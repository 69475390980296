import { REST_ENDPOINTS } from 'methone/shared/constants'
import { api } from 'methone/utils/api'

const ENDPOINT = REST_ENDPOINTS.CONFIGS

export async function getConfigs(): Promise<Record<string, string>> {
  const { data: response } = await api.get(`/${ENDPOINT}`)

  return response
}

export async function updateConfigs(configs: Record<string, string>): Promise<Record<string, string>> {
  const { data: response } = await api.post(`/${ENDPOINT}`, configs)

  return response
}
