import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { parseQueryParams } from 'methone/shared/parseQueryParams'
import { parseURLSearchParams } from 'methone/utils/parseURLSearchParams'
import { toQueryString } from 'methone/utils/toQueryString'

type QueryParamsAux = (string | number | boolean) | (string | number | boolean)[]
type QueryParamsProps<T extends Record<string, QueryParamsAux>> = [params: T, setParams: (params: T) => void]

export function useQueryParams<T extends Record<string, QueryParamsAux>>(defaultParams?: T): QueryParamsProps<T> {
  const [parsedParams, setParsedParams] = useState<T>(defaultParams as T)
  const [params, setParams] = useSearchParams()

  function updateQueryString(newParams: T): void {
    setParams(toQueryString(newParams))
  }

  useEffect(() => {
    setParsedParams(parseURLSearchParams(parseQueryParams(params), defaultParams))
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return [parsedParams, updateQueryString]
}
