import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const SettingsStyledContainer = styled(Container)`
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  > * {
    width: 100%;
  }
`
