import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const UserEditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card {
      > .card-body {
        > fieldset {
          display: grid;
          gap: var(--spacing-2);
          grid-template-areas: 'NM NM UN' 'EM PM PM' 'PW PW PW';
          grid-template-columns: 300px 1fr 200px;

          > .form-input-container--name {
            grid-area: NM;
          }

          > .form-input-container--username {
            grid-area: UN;
          }

          > .form-input-container--email {
            grid-area: EM;
          }

          > .form-select-container--permissions {
            grid-area: PM;
          }

          > .form-input-container--enabled {
            grid-area: EN;
          }

          > .form-input-container--password {
            grid-area: PW;
          }
        }
      }

      &.form-footer {
        margin-top: var(--spacing-2);

        > .card-body {
          display: flex;
          justify-content: space-between;

          > .actions-area {
            display: flex;
            justify-content: center;
            align-items: center;

            > .btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }

            > span {
              margin-left: 10px;
            }
          }

          > .required-warn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--font-semibold);
            color: var(--red-500);
          }
        }
      }
    }
  }
`
