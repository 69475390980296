import React from 'react'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { configRendererService } from 'methone/services/configRendererService'
import { getConfigs, updateConfigs } from 'methone/services/rest/configs'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { SettingsStyledContainer } from './styled'

interface ConfigRendererProps {
  item: ConfigRendererItem
}

export const SettingsPage: React.FC = () => {
  const { loading: configsLoading, mutate, data: configs, error: configsError } = useRequest(getConfigs)
  const [saving, setSaving] = React.useState(false)

  function onSubmit(scope: string) {
    return async (data: Record<string, any>): Promise<'saved' | 'error'> => {
      try {
        setSaving(true)
        const configsToSave = {}

        for (const [key, value] of Object.entries(data)) {
          if (!key.startsWith(`${scope}.`)) {
            configsToSave[`${scope}.${key}`] = value
          } else {
            configsToSave[key] = value
          }
        }

        await updateConfigs(configsToSave)
        mutate((old) => ({ ...old, ...configsToSave }))

        return 'saved'
      } catch (error) {
        handleHTTPRequestError(error)

        return 'error'
      } finally {
        setSaving(false)
      }
    }
  }

  const ConfigRenderer: React.FC<ConfigRendererProps> = React.useCallback(
    (props) => {
      const { component: Renderer, scope } = props.item
      const configsToRenderer = {}

      for (const [key, value] of Object.entries(configs)) {
        if (key.startsWith(`${scope}.`)) {
          // Remove scope prefix
          configsToRenderer[key.replace(new RegExp(`^${scope}.`), '')] = value
        }
      }

      return <Renderer configs={configsToRenderer} onSave={onSubmit(scope)} />
    },
    [configs] // eslint-disable-line react-hooks/exhaustive-deps
  )

  React.useEffect(() => {
    if (configsError) {
      handleHTTPRequestError(configsError)
    }
  }, [configsError])

  return (
    <SettingsStyledContainer>
      <FixedLoading enabled={configsLoading || saving} />
      {!configsLoading &&
        configRendererService
          .getRenderers()
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((item, i) => <ConfigRenderer key={i} item={item} />)}
    </SettingsStyledContainer>
  )
}
