import React from 'react'
import { createRoot } from 'react-dom/client'

import { GlobalConfirmationModal, Props as GCMProps } from 'methone/components/GlobalConfirmationModal'
import { DEFAULT_PORTAL_ID } from 'methone/utils/constants'
import { getOrCreatePortalRoot } from 'methone/utils/getOrCreatePortalRoot'

import { i18n } from './i18n'

const PORTAL_ROOT_ID = `${DEFAULT_PORTAL_ID}-global-confirmation`

type Props = Omit<GCMProps, 'onCancel' | 'onConfirm' | 'children'>
type FinalProps = Omit<GCMProps, 'onCancel' | 'onConfirm' | 'children' | 'level'>

class GlobalConfirmation {
  public async confirm(content: string | JSX.Element, props: Props): Promise<boolean> {
    const container = getOrCreatePortalRoot(PORTAL_ROOT_ID)
    const portalRoot = createRoot(container)

    return new Promise((resolve, reject) => {
      function onCancel(): void {
        portalRoot.unmount()
        resolve(false)
      }

      function onConfirm(): void {
        portalRoot.unmount()
        resolve(true)
      }

      try {
        portalRoot.render(
          <GlobalConfirmationModal onCancel={onCancel} onConfirm={onConfirm} {...props}>
            {content}
          </GlobalConfirmationModal>
        )
      } catch (e) {
        reject(e)
      }
    })
  }

  public async success(content: string | JSX.Element, props?: FinalProps): Promise<boolean> {
    return this.confirm(content, { ...props, level: 'success' })
  }

  public async warn(content: string | JSX.Element, props?: FinalProps): Promise<boolean> {
    return this.confirm(content, { ...props, level: 'warning' })
  }

  public async danger(content: string | JSX.Element, props?: FinalProps): Promise<boolean> {
    const finalContent = (
      <>
        <p
          style={{
            color: 'var(--red-500)',
            fontFamily: 'var(--font-bold)',
            fontSize: 'var(--font-size-3)',
            marginBottom: 'var(--spacing-2)'
          }}
        >
          {i18n('This action can not be undone.')}
        </p>
        {content}
      </>
    )

    return this.confirm(finalContent, { ...props, level: 'danger' })
  }
}

export const globalConfirmation = new GlobalConfirmation()
