import styled, { css, keyframes } from 'styled-components'

import { SIDEBAR } from 'methone/utils/constants'

const sharedDropdownItemStyle = css`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--spacing-4);
  font-family: var(--font-semibold);
  height: ${SIDEBAR.ITEM_HEIGHT}px;
  border-radius: var(--spacing-1);
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  background: none;
  border: none;

  &:hover {
    background: rgba(var(--gray-800-rgb), 0.5);
  }

  &.active {
    background: rgba(var(--blue-500-rgb), 0.1);
  }
`

const openDropdownAnimation = keyframes`
  from {
    display: none;
    height: 0;
    opacity: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  to {
    display: flex;
    height: calc((${SIDEBAR.ITEM_HEIGHT}px * var(--items-count)) + (var(--spacing-1) * (var(--items-count) - 1)) + (var(--spacing-2) * 2));
    opacity: 1;
    margin-top: var(--spacing-1);
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }
`

const closeDropdownAnimation = keyframes`
  from {
    display: flex;
    height: calc((${SIDEBAR.ITEM_HEIGHT}px * var(--items-count)) + (var(--spacing-1) * (var(--items-count) - 1)) + (var(--spacing-2) * 2));
    opacity: 1;
    margin-top: var(--spacing-1);
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }
  to {
    display: none;
    height: 0;
    opacity: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const SidebarItemContainer = styled.div`
  position: relative;

  > button {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-2);
    flex-wrap: nowrap;
    background: none;
    border: none;
    width: 100%;
    border-radius: var(--spacing-2);

    &:hover,
    &.focused {
      background: rgba(var(--gray-900-rgb), 0.5);
    }

    &.active {
      background: rgba(var(--blue-500-rgb), 0.1);
    }

    > * {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: ${SIDEBAR.ITEM_HEIGHT}px;
    }

    > .icon-wrapper {
      justify-content: center;
      width: ${SIDEBAR.ITEM_HEIGHT}px;
      border-radius: 100%;
    }

    > .sidebar-item-title {
      justify-content: flex-start;
      font-family: var(--font-semibold);
      width: calc(${SIDEBAR.EXPANDED_WIDTH}px - (${SIDEBAR.ITEM_HEIGHT}px + (var(--spacing-2) * 3)));
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: var(--spacing-6);
    }
  }
`

export const DropdownItemsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  margin-top: var(--spacing-1);
  background: var(--gray-600);
  padding: var(--spacing-2);
  border-radius: var(--spacing-2);
  overflow: hidden;

  &.focused {
    animation: ${openDropdownAnimation} ${SIDEBAR.ITEM_DROPDOWN_ANIMATION_DURATION}ms linear forwards;
  }

  &.not-focused {
    animation: ${closeDropdownAnimation} ${SIDEBAR.ITEM_DROPDOWN_ANIMATION_DURATION}ms linear forwards;
  }

  &.idle,
  &:not(.show) {
    display: none;
  }

  > button.dropdown-item {
    ${sharedDropdownItemStyle}
  }
`

export const SidebarSubItemsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  background: var(--gray-700);
  border-radius: var(--spacing-2);
  padding: var(--spacing-2);
  box-shadow: var(--shadow);
  margin-left: calc(var(--spacing-2) * 2);
  border: 1px solid var(--gray-800);

  &::before {
    content: '';
    position: absolute;
    background: var(--gray-700);
    top: 50%;
    left: -1px;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-left: 1px solid var(--gray-800);
    border-bottom: 1px solid var(--gray-800);
    border-bottom-left-radius: var(--spacing-1);
  }

  &::after {
    content: '';
    position: absolute;
    background: transparent;
    top: 0;
    left: calc(var(--spacing-2) * -1);
    width: var(--spacing-2);
    height: 100%;
  }

  > .sidebar-sub-item-header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-2);
    flex-wrap: nowrap;
    background: rgba(var(--gray-900-rgb), 0.5);
    border: none;
    width: 100%;
    border-radius: var(--spacing-1);

    &:last-child {
      margin: calc(var(--spacing-2) * -1);
      background: unset;
    }

    > * {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: ${SIDEBAR.ITEM_HEIGHT}px;
    }

    > .sub-item-icon-wrapper {
      justify-content: center;
      width: ${SIDEBAR.ITEM_HEIGHT}px;
      border-radius: 100%;
    }

    > .sidebar-sub-item-title {
      justify-content: flex-start;
      font-family: var(--font-semibold);
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: var(--spacing-6);
    }
  }

  > .sidebar-sub-item-container {
    ${sharedDropdownItemStyle}
  }
`
