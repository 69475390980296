import { theme } from 'methone/styles/theme'

export const DEFAULT_PORTAL_ID = 'methone-portal-root'

/* ================================================================================================================== */

export const SPACING_1 = Number(theme['spacing-1'].replace('px', ''))
export const SPACING_2 = Number(theme['spacing-2'].replace('px', ''))
export const SPACING_3 = Number(theme['spacing-3'].replace('px', ''))
export const SPACING_4 = Number(theme['spacing-4'].replace('px', ''))
export const SPACING_5 = Number(theme['spacing-5'].replace('px', ''))
export const SPACING_6 = Number(theme['spacing-6'].replace('px', ''))

/* ================================================================================================================== */

export const SIDEBAR_EXPANDED_WIDTH = 225
export const SIDEBAR_COLLAPSED_WIDTH = 56

export const SIDEBAR_HEADER_HEIGHT = 56
export const SIDEBAR_HEADER_BUTTON_SIZE = 40
export const SIDEBAR_HEADER_LOGO_HEIGHT = 40
export const SIDEBAR_HEADER_LOGO_WIDTH = SIDEBAR_EXPANDED_WIDTH - SPACING_1 * 3 + SIDEBAR_HEADER_BUTTON_SIZE

export const SIDEBAR_ITEM_HEIGHT = 40
export const SIDEBAR_ITEM_ICON_SIZE = 40
export const SIDEBAR_ITEM_DROPDOWN_ANIMATION_DURATION = 150

export const SIDEBAR_FOOTER_HEIGHT = 56
export const SIDEBAR_FOOTER_USER_ICON = 32

export const SIDEBAR = {
  EXPANDED_WIDTH: SIDEBAR_EXPANDED_WIDTH,
  COLLAPSED_WIDTH: SIDEBAR_COLLAPSED_WIDTH,

  HEADER_HEIGHT: SIDEBAR_HEADER_HEIGHT,
  HEADER_BUTTON_SIZE: SIDEBAR_HEADER_BUTTON_SIZE,
  HEADER_LOGO_HEIGHT: SIDEBAR_HEADER_LOGO_HEIGHT,
  HEADER_LOGO_WIDTH: SIDEBAR_HEADER_LOGO_WIDTH,

  ITEM_HEIGHT: SIDEBAR_ITEM_HEIGHT,
  ITEM_ICON_SIZE: SIDEBAR_ITEM_ICON_SIZE,
  ITEM_DROPDOWN_ANIMATION_DURATION: SIDEBAR_ITEM_DROPDOWN_ANIMATION_DURATION,

  FOOTER_HEIGHT: SIDEBAR_FOOTER_HEIGHT,
  FOOTER_USER_ICON: SIDEBAR_FOOTER_USER_ICON
}

export const NO_SIDEBAR_ROUTES = ['login', 'reset-password', 'forgot-password']

export enum AUTH_ACTIONS {
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_USER = 'SET_USER',
  SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED',
  RESET = 'RESET'
}

export enum UI_ACTIONS {
  SET_SIDEBAR_EXPANDED = 'SET_SIDEBAR_EXPANDED',
  RESET = 'RESET'
}
