import React from 'react'
import { Card, Accordion, Button } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormPhoneInput, HookFormTaxIdInput } from 'methone/components/ui/Forms'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { createClient, findClient, updateClient } from 'methone/services/rest/clients'
import { ClientAddressResponseDTO } from 'methone/shared/dto/ClientAddressResponseDTO'
import { ClientCreateDTO } from 'methone/shared/dto/ClientCreateDTO'
import { validTaxID } from 'methone/shared/validators'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { EditorContainer } from './styled'

interface FormProps extends ClientCreateDTO {
  addresses: Omit<ClientAddressResponseDTO, 'id' | 'clientId'>[]
}

const defaultValues: FormProps = {
  name: '',
  taxId: '',
  email: '',
  group: '',
  addresses: []
}

export function ClientsEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset } = useForm<FormProps>({ defaultValues, mode: 'all' })
  const { fields, append, remove } = useFieldArray({ control, keyName: 'uid', name: 'addresses' })

  const { loading, run, data: client, error } = useRequest(findClient, { manual: true })
  const [savingStatus, setSavingStatus] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')

  const navigate = useNavigate()

  async function handleRemoveAddress(index: number): Promise<void> {
    if (await globalConfirmation.danger(i18n('Are you sure you want to remove this address?'))) {
      remove(index)
    }
  }

  function handleAddAddress(e: React.MouseEvent): void {
    e.preventDefault()

    append({
      description: i18n('New address'),
      phone: '',
      street: '',
      number: undefined,
      details: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      createdAt: undefined,
      updatedAt: undefined,
      createdBy: undefined,
      updatedBy: undefined
    })
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setSavingStatus('saving')

      const bodyData: ClientCreateDTO = {
        ...data,
        taxId: data.taxId.replace(/\D/g, ''),
        addresses: (data.addresses ?? []).map((address) => ({
          ...address,
          phone: address.phone ? address.phone.replace(/\D/g, '') : undefined
        }))
      }

      if (id === 'new') {
        const createdUser = await createClient(bodyData)
        navigate(`/clients/${createdUser.id}`)
      } else {
        await updateClient(id, bodyData)
        run(id)
      }
      setSavingStatus('saved')
    } catch (e) {
      handleHTTPRequestError(e)
      setSavingStatus('error')
    }
  }

  React.useEffect(() => {
    if (client) {
      let taxIdFormatted = ''

      if (client.taxId.length === 11) {
        taxIdFormatted = client.taxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
      } else {
        taxIdFormatted = client.taxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
      }

      reset({
        name: client.name,
        taxId: taxIdFormatted,
        email: client.email,
        group: client.group,
        addresses: client.addresses.map((address) => {
          if (address.phone != null && address.phone.length > 0) {
            let phoneFormatted = ''

            if (address.phone.length === 11) {
              phoneFormatted = address.phone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
            } else {
              phoneFormatted = address.phone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
            }

            address.phone = phoneFormatted
          }

          return address
        })
      })
    }
  }, [client]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    reset(defaultValues)
    if (id !== 'new') {
      run(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  return (
    <EditorContainer>
      <FixedLoading enabled={loading || savingStatus === 'saving'} />
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Card>
          <Card.Body>
            <fieldset>
              <HookFormInput label={i18n('Name')} name="name" control={control} rules={{ required: true }} />
              <HookFormTaxIdInput
                label={i18n('Tax id')}
                name="taxId"
                control={control}
                rules={{ required: true, validate: (value) => validTaxID(value) ?? i18n('Invalid tax id') }}
              />
              <HookFormInput label={i18n('Email')} name="email" control={control} inputProps={{ type: 'email' }} />
              <HookFormInput label={i18n('Group')} name="group" control={control} />
            </fieldset>
          </Card.Body>
        </Card>

        <div className="addresses-list">
          <Accordion>
            {fields.map((field, idx) => (
              <Accordion.Item key={field.uid} eventKey={field.uid}>
                <div className="accordion-header">
                  <Accordion.Button type="button" onClick={(e) => e.preventDefault()}>
                    {field.description}
                  </Accordion.Button>
                  <Button type="button" className="trash-button" onClick={() => handleRemoveAddress(idx)}>
                    <i className="far fa-trash-alt" />
                  </Button>
                </div>
                <Accordion.Body>
                  <fieldset>
                    <HookFormInput
                      label={i18n('Description')}
                      name={`addresses.${idx}.description`}
                      control={control}
                      rules={{ required: true }}
                    />
                    <HookFormPhoneInput label={i18n('Phone')} name={`addresses.${idx}.phone`} control={control} />
                    <HookFormInput label={i18n('Street')} name={`addresses.${idx}.street`} control={control} />
                    <HookFormInput label={i18n('Number')} name={`addresses.${idx}.number`} control={control} />
                    <HookFormInput label={i18n('Details')} name={`addresses.${idx}.details`} control={control} />
                    <HookFormInput
                      label={i18n('Neighborhood')}
                      name={`addresses.${idx}.neighborhood`}
                      control={control}
                    />
                    <HookFormInput label={i18n('City')} name={`addresses.${idx}.city`} control={control} />
                    <HookFormInput label={i18n('State')} name={`addresses.${idx}.state`} control={control} />
                    <HookFormInput label={i18n('Country')} name={`addresses.${idx}.country`} control={control} />
                  </fieldset>
                </Accordion.Body>
              </Accordion.Item>
            ))}
            <Accordion.Item eventKey="add" className="add-button">
              <div className="accordion-header">
                <button className="accordion-button collapsed" onClick={handleAddAddress}>
                  <i className="fas fa-plus" /> {i18n('New address')}
                </button>
              </div>
            </Accordion.Item>
          </Accordion>
        </div>

        <Card className="form-footer">
          <Card.Body>
            <div className="actions-area">
              <SaveButton type="submit" status={savingStatus}>
                {i18n(id === 'new' ? 'Create' : 'Save')}
              </SaveButton>
              <span>{i18n('or')}</span>
              <Button type="button" variant="link" onClick={() => navigate(`/clients`)}>
                {i18n('cancel')}
              </Button>
            </div>

            <div className="required-warn">* {i18n('Required fields')}</div>
          </Card.Body>
        </Card>
      </form>
    </EditorContainer>
  )
}
