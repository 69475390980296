import React from 'react'
import { FormText } from 'react-bootstrap'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import cx from 'classnames'

import { getHookFormErrorMessage } from '../errorMessages'
import { FormGroup } from '../FormGroup'
import { PureInput } from '../Input/Pure'
import { Label } from '../Label'

export interface PasswordInputProps extends HTMLInputProps {
  label?: string
  error?: string
  infoText?: string
}

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(function Component(props, ref) {
  const { label, error, infoText, ...rest } = props
  const id = rest?.name ?? Math.random().toString(36).substring(7)
  const fullId = rest?.id ?? `input-field--${id}`
  const cnBase = 'form-input'
  const inputClassName = `${cnBase}-field`

  const [visible, setVisible] = React.useState(false)

  return (
    <FormGroup className={cx(`${cnBase}-container ${cnBase}-container--${id}`, { hasError: !!error })}>
      {label && (
        <Label className={cx(`${cnBase}-label`, { required: rest?.required })} htmlFor={fullId}>
          {label}
        </Label>
      )}

      <PureInput {...rest} ref={ref} id={fullId} type={visible ? 'text' : 'password'} className={inputClassName} />

      <button
        type="button"
        className={cx('visible-button', { hasLabel: label })}
        onClick={() => setVisible((old) => !old)}
      >
        <i className={`far fa-${visible ? 'eye' : 'eye-slash'}`} />
      </button>

      {(error || infoText) && <FormText>{error || infoText}</FormText>}
    </FormGroup>
  )
})

export interface HFPasswordProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  inputProps?: Omit<PasswordInputProps, 'label' | 'ref' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error'>
}

export function HookFormPassword<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  inputProps
}: HFPasswordProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <PasswordInput
          {...inputProps}
          {...field}
          label={label}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
