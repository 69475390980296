import { FieldError, MultipleFieldErrors } from 'react-hook-form'

import { i18n } from 'methone/services/i18n'

const messages: Record<keyof MultipleFieldErrors, string> = {
  required: i18n('errors.forms.required')
}

export function getHookFormErrorMessage(error: FieldError): string {
  if (error) {
    const msg = error.message !== '' ? error.message : messages[error.type]

    return msg ?? i18n('errors.forms.unknown')
  }

  return null
}
