import { styled } from 'styled-components'

export const FormGroup = styled.div.attrs({ className: 'form-group' })`
  position: relative;
  display: flex;
  flex-direction: column;

  &.hasError {
    > label.form-label {
      color: var(--red-500);
    }

    > div.react-select > div.react-select__control,
    > div.react-datepicker-wrapper > div.react-datepicker__input-container > input.form-control,
    > input.form-control,
    > textarea.form-control {
      border-color: var(--red-500);
    }

    > small.form-text {
      color: var(--red-500);
    }
  }

  > div.form-checkbox-field {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0;

    > input {
      margin: 0;
      height: 18px;
      width: 34px;

      box-shadow: none;
      border-color: var(--gray-500);
      background-color: var(--gray-500);

      &:checked {
        border-color: var(--blue-500);
        background-color: var(--blue-500);
      }

      &:disabled {
        border-color: var(--gray-800);
        background-color: var(--gray-800);
      }
    }
  }

  > button.visible-button,
  > button.clipboard-button {
    position: absolute;
    right: calc(var(--spacing-1) - 1px);
    border-radius: var(--spacing-1);
    background: var(--gray-800);
    cursor: pointer;
    border: none;
    width: 28px;
    height: 28px;

    &.hasLabel {
      transform: translateY(22px);
    }

    &:hover {
      background: var(--gray-900);
    }

    i {
      color: var(--gray-0);
    }
  }

  > small.form-text {
    margin: var(--spacing-1) 0 0 var(--spacing-1);
  }
`
