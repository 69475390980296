import React from 'react'
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'
import { filesize as fileSize } from 'filesize'

import { SortedTable } from 'methone/components/ui/SortedTable'
import { dateService } from 'methone/services/dateService'
import { i18n } from 'methone/services/i18n'
import { getPlugins, getMetrics } from 'methone/services/rest/utilities'

import { StatusContainer, StatusBadge } from './styled'

export function StatusPage(): JSX.Element {
  const { data: plugins } = useRequest(getPlugins)
  const { data: metrics } = useRequest(getMetrics, { pollingInterval: 60000 })

  const navigate = useNavigate()

  function handleVersion(rawVersion: string): string {
    const [version, build] = rawVersion.split('-')

    if (!build) {
      return version
    }

    return `${version}-${build.slice(0, 8)}`
  }

  function handlePluginBadge(status: string): string {
    switch (status) {
      case 'active':
        return 'success'
      case 'inactive':
        return 'secondary'
      case 'loaded':
      case 'registered':
        return 'warning'
      default:
        return 'danger'
    }
  }

  function formatUptime(seconds: number): string {
    const minuteCounter = 60
    const hourCounter = minuteCounter * 60
    const dayCounter = hourCounter * 24
    const weekCounter = dayCounter * 7
    const monthCounter = dayCounter * 30

    const months = Math.floor(seconds / monthCounter)
    seconds %= monthCounter

    const weeks = Math.floor(seconds / weekCounter)
    seconds %= weekCounter

    const days = Math.floor(seconds / dayCounter)
    seconds %= dayCounter

    const hours = Math.floor(seconds / hourCounter)
    seconds %= hourCounter

    const minutes = Math.floor(seconds / minuteCounter)
    seconds %= minuteCounter

    const uptime = []

    if (months > 0) {
      uptime.push(`${months}m`)
    }

    if (weeks > 0) {
      uptime.push(`${weeks}w`)
    }

    if (days > 0) {
      uptime.push(`${days}d`)
    }

    if (hours > 0) {
      uptime.push(`${hours}h`)
    }

    if (minutes > 0) {
      uptime.push(`${minutes}min`)
    }

    if (seconds > 0) {
      uptime.push(`${seconds.toFixed(0)}s`)
    }

    return uptime.join(' ')
  }

  return (
    <StatusContainer>
      <div className="metrics-wrapper">
        <div className="metrics-group">
          <div className="metrics-card">
            <div className="metrics-card-avatar">
              <i className="fas fa-info-circle fa-2x" />
            </div>
            <div className="metrics-card-content">
              <div className="metrics-card-info-wrapper">
                <div className="metrics-card--info-title">{i18n('Version')}</div>
                {metrics &&
                  (handleVersion(metrics.version) !== metrics.version ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          <div style={{ whiteSpace: 'nowrap' }}>{metrics.version}</div>
                        </Tooltip>
                      }
                    >
                      <div className="metrics-card--info-value">{handleVersion(metrics.version)}</div>
                    </OverlayTrigger>
                  ) : (
                    <div className="metrics-card--info-value">{handleVersion(metrics.version)}</div>
                  ))}
              </div>
              <div className="metrics-card-info-wrapper">
                <div className="metrics-card--info-title">{i18n('Last update')}</div>
                <div className="metrics-card--info-value">
                  {metrics && dateService.formatDateTime(metrics.lastUpdate)}
                </div>
              </div>
            </div>
          </div>

          <div className="metrics-card">
            <div className="metrics-card-avatar">
              <i className="far fa-circle-up fa-2x" />
            </div>
            <div className="metrics-card-content">
              <div className="metrics-card-info-wrapper">
                <div className="metrics-card--info-title">{i18n('System uptime')}</div>
                <div className="metrics-card--info-value">{metrics && formatUptime(metrics.uptime.host)}</div>
              </div>
              <div className="metrics-card-info-wrapper">
                <div className="metrics-card--info-title">{i18n('Methone uptime')}</div>
                <div className="metrics-card--info-value">{metrics && formatUptime(metrics.uptime.process)}</div>
              </div>
            </div>
          </div>

          <div className="metrics-card">
            <div className="metrics-card-avatar">
              <i className="fas fa-microchip fa-2x" />
            </div>
            <div className="metrics-card-content">
              <div className="metrics-card-header">
                <div className="metrics-card--title">{i18n('CPU')}</div>
                <div className="metrics-card--usage-label">
                  <span>{metrics && `${metrics.cpuUsage.toFixed(2)}%`}</span>
                </div>
              </div>
              <div className="metrics-card--usage-bar">
                {metrics ? (
                  <ProgressBar now={metrics.cpuUsage} />
                ) : (
                  <ProgressBar now={100} variant="secondary" animated />
                )}
              </div>
            </div>
          </div>

          <div className="metrics-card">
            <div className="metrics-card-avatar">
              <i className="fas fa-memory fa-2x" />
            </div>
            <div className="metrics-card-content">
              <div className="metrics-card-header">
                <div className="metrics-card--title">{i18n('Memory')}</div>
                <div className="metrics-card--usage-label">
                  {metrics && (
                    <>
                      <span>{String(fileSize(metrics.memory.used + metrics.memory.cache))}</span>/
                      <span>{String(fileSize(metrics.memory.total))}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="metrics-card--usage-bar">
                {metrics ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <div>
                          {i18n('Used')}: {String(fileSize(metrics.memory.used))}
                        </div>
                        <div>
                          {i18n('Cache')}: {String(fileSize(metrics.memory.cache))}
                        </div>
                      </Tooltip>
                    }
                  >
                    <ProgressBar>
                      <ProgressBar now={(metrics.memory.used / metrics.memory.total) * 100} />
                      <ProgressBar now={(metrics.memory.cache / metrics.memory.total) * 100} variant="warning" />
                    </ProgressBar>
                  </OverlayTrigger>
                ) : (
                  <ProgressBar now={100} variant="secondary" animated />
                )}
              </div>
            </div>
          </div>

          {(metrics?.storages ?? []).map((storageMetric) => {
            const unknownSize = storageMetric.total === null || storageMetric.used === null
            const percentage = unknownSize ? 100 : (storageMetric.used / storageMetric.total) * 100
            const url = `/admin/extensions/${storageMetric.type}/${storageMetric.id}`

            return (
              <div
                key={storageMetric.id}
                className="metrics-card metrics-card--storage-provider"
                onClick={() => navigate(url)}
              >
                <div className="metrics-card-avatar">
                  <i className="far fa-hard-drive fa-2x" />
                </div>
                <div className="metrics-card-content">
                  <div className="metrics-card-header">
                    <div className="metrics-card--title">{storageMetric.name}</div>
                    <div className="metrics-card--usage-label">
                      <span>{String(fileSize(storageMetric.used))}</span>
                      {storageMetric.total && (
                        <>
                          /<span>{String(fileSize(storageMetric.total))}</span>
                        </>
                      )}
                      <i className="fas fa-arrow-up-right-from-square" />
                    </div>
                  </div>

                  <div className="metrics-card--usage-bar">
                    <ProgressBar striped={unknownSize} now={unknownSize ? 100 : percentage} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="plugins-list">
        <SortedTable
          items={(plugins ?? []).map((plugin) => ({
            name: plugin.name,
            version: plugin.version,
            status: <StatusBadge bg={handlePluginBadge(plugin.status)}>{plugin.status}</StatusBadge>
          }))}
          columns={[
            { columnRef: 'name', title: i18n('Name'), width: '100%' },
            { columnRef: 'version', title: i18n('Version'), width: 100, bodyCellStyle: { whiteSpace: 'nowrap' } },
            { columnRef: 'status', title: i18n('Status') }
          ]}
        />
      </div>
    </StatusContainer>
  )
}
