import React from 'react'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import { Checkbox, CheckboxProps } from '../Checkbox'
import { getHookFormErrorMessage } from '../errorMessages'

export type SwitchProps = CheckboxProps

/** @deprecated use <Checkbox type="switch" {...rest} /> instead */
export const Switch = React.forwardRef<HTMLInputElement, CheckboxProps>(function SwitchComponent(props, ref) {
  return <Checkbox {...props} type="switch" ref={ref} />
})

type OmitProps = 'label' | 'ref' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error'
export interface HFSwitchProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  switchProps?: Omit<SwitchProps, OmitProps>
}

export function HookFormSwitch<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  switchProps
}: HFSwitchProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Switch
          {...switchProps}
          label={label}
          ref={field.ref}
          name={field.name}
          value={field.value}
          checked={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
