function arrayGuard(value: any): value is Array<any> {
  return Array.isArray(value)
}

function objectGuard(value: any): value is Record<string, any> {
  return typeof value === 'object' && value !== null && !arrayGuard(value)
}

export function toQueryString(params: Record<string, any> | URLSearchParams): string {
  if (params == null) {
    return null
  }

  return Object.entries(params)
    .map(([key, value]) => {
      if (value == null) {
        return ''
      }

      if (arrayGuard(value)) {
        if (value.length === 0) {
          return ''
        }

        return value.map((av, idx) => toQueryString({ [`${key}[${idx}]`]: av })).join('&')
      }

      if (objectGuard(value)) {
        if (Object.keys(value).length === 0) {
          return ''
        }

        return Object.entries(value)
          .map(([sk, sv]) => toQueryString({ [`${key}[${sk}]`]: sv }))
          .join('&')
      }

      if (typeof value === 'function' || typeof value === 'symbol') {
        throw new Error(`Cannot convert value of type ${typeof value} to query string`)
      }

      return `${key}=${value}`
    })
    .join('&')
}
