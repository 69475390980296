import { validatePermsByStore } from './validatePermsByStore'

export function menuItemFilter(menuItem: MenuItem | MenuDropdownSubItem): boolean {
  if (menuItem == null) {
    return false
  }

  if (!validatePermsByStore(menuItem.permissions) && (menuItem.permissions ?? []).length > 0) {
    return false
  }

  return true
}
