import { Permissions } from './permissions'

const bypass: string[] = [Permissions.ADMIN, Permissions.SUPER_ADMIN]

export function validatePermissions(userPerms: string[], requiredPerms?: string[]): boolean {
  if (requiredPerms == null || requiredPerms.length === 0) {
    requiredPerms = []
  }

  if (userPerms.some((p) => bypass.includes(p))) {
    return true
  }

  return requiredPerms.some((p) => userPerms.includes(p))
}
