export function getSizeInBytes(size: number | string): number {
  if (typeof size === 'number') {
    if (Number.isNaN(size) || size < 0) {
      throw new Error('Invalid size')
    }

    return size
  }

  const number = Number(size.replace(/[a-zA-Z]/g, ''))
  const unit = (/([a-zA-Z]+)/.exec(size)?.[0] ?? '').toLowerCase()

  if (Number.isNaN(number) || number < 0 || !['b', 'kb', 'mb', 'gb', 'tb'].includes(unit)) {
    throw new Error('Invalid size')
  }

  const multiplier = {
    b: 0,
    kb: 1,
    mb: 2,
    gb: 3,
    tb: 4
  }

  return number * 1024 ** multiplier[unit]
}
