import React from 'react'
import { FormText } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import cx from 'classnames'

import { storageService } from 'methone/services/storageService'

import { getHookFormErrorMessage } from '../errorMessages'
import { FormGroup } from '../FormGroup'
import { MaskInput } from '../Input/Mask'
import { Label } from '../Label'

export interface DatePickerProps extends Omit<React.ComponentProps<typeof ReactDatePicker>, 'dateFormat'> {
  clipboard?: boolean
  label?: string
  error?: string
  infoText?: string
  dateFormat?: string
}

export const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(function DatePickerComponent(props, ref) {
  const { clipboard, label, error, infoText, ...rest } = props
  const dateFormat = storageService.getItem('language') === 'pt-BR' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
  const id = rest?.name ?? Math.random().toString(36).substring(7)
  const fullId = rest?.id ?? `input-field--${id}`
  const cnBase = 'form-input'
  const inputClassName = `${cnBase}-field`

  async function copyToClipboard(value: string): Promise<void> {
    await navigator.clipboard.writeText(value)
  }

  return (
    <FormGroup className={cx(`${cnBase}-container ${cnBase}-container--${id}`, { hasError: !!error })}>
      {label && (
        <Label className={cx(`${cnBase}-label`, { required: rest?.required })} htmlFor={fullId}>
          {label}
        </Label>
      )}

      <ReactDatePicker
        {...rest}
        ref={ref}
        id={fullId}
        dateFormat={rest?.dateFormat ?? dateFormat}
        placeholderText={rest?.dateFormat ?? dateFormat}
        customInput={
          <MaskInput
            type="text"
            className={inputClassName}
            mask={(rest?.dateFormat ?? dateFormat).replace(/[dMy]/g, '_')}
            replacement={{ _: /\d/ }}
          />
        }
      />

      {clipboard && (
        <button
          type="button"
          className={cx('clipboard-button', { hasLabel: label })}
          onClick={(e) => copyToClipboard((e.target as HTMLInputElement).value)}
        >
          <i className="far fa-clipboard" />
        </button>
      )}

      {(error || infoText) && <FormText>{error || infoText}</FormText>}
    </FormGroup>
  )
})

export interface HFDatePickerProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  inputProps?: Omit<DatePickerProps, 'label' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error'>
}

export function HookFormDatePicker<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  inputProps
}: HFDatePickerProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <DatePicker
          {...inputProps}
          label={label}
          ref={field.ref}
          name={field.name}
          value={typeof field.value === 'string' ? field.value : undefined}
          selected={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
