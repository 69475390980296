import React from 'react'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import { ModifiedData } from '@react-input/mask'

import { getHookFormErrorMessage } from '../errorMessages'
import { Input, InputProps } from '../Input'

const CPF_INPUT_MASK = '___.___.___-___'
const CNPJ_INPUT_MASK = '__.___.___/____-__'

export const TaxIdInput = React.forwardRef<HTMLInputElement, InputProps>(function InputComponent(props, ref) {
  function modify(input: string): ModifiedData {
    const innerMask = input.length <= 11 ? CPF_INPUT_MASK : CNPJ_INPUT_MASK

    return {
      mask: innerMask,
      replacement: { _: /\d/ },
      showMask: false,
      separate: false
    }
  }

  return (
    <Input
      {...{ ...(props ?? {}), type: 'text' }}
      ref={ref}
      mask={((props?.value ?? '') as string).replace(/[^\d]/g, '').length > 11 ? CNPJ_INPUT_MASK : CPF_INPUT_MASK}
      replacement={{ _: /\d/ }}
      modify={modify}
    />
  )
})

export interface HFTaxIdInputProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  inputProps?: Omit<InputProps, 'label' | 'ref' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error' | 'type'> & {
    type: 'text'
  }
}

export function HookFormTaxIdInput<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  inputProps
}: HFTaxIdInputProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TaxIdInput
          {...inputProps}
          {...field}
          label={label}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
