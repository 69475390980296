export class PluginResponseDTO {
  name: string
  version: string
  description: string
  status: string
  messages: string[]

  constructor(data?: PluginResponseDTO) {
    this.name = data.name
    this.version = data.version
    this.description = data.description
    this.status = data.status
    this.messages = data.messages
  }

  public static fromArray(data: PluginResponseDTO[]): PluginResponseDTO[] {
    return data.map((item) => new PluginResponseDTO(item))
  }
}
