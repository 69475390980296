/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef } from 'react'

type Handler = (e: MouseEvent | TouchEvent) => void

export function useOnClickOutside<E extends HTMLElement>(handler: Handler): React.MutableRefObject<E> {
  const ref = useRef<E>()

  useEffect(() => {
    function listener(event: MouseEvent | TouchEvent): void {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])

  return ref
}
