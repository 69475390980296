import { useEffect, useState } from 'react'

export interface Dimensions {
  width: number
  height: number
}

function getDimensions(deductions: Partial<Dimensions>): Dimensions {
  if (deductions == null) {
    deductions = { height: 0, width: 0 }
  } else {
    if (deductions.height == null || deductions.height < 0) {
      deductions.height = 0
    }

    if (deductions.width == null || deductions.width < 0) {
      deductions.width = 0
    }
  }

  return {
    width: window.innerWidth - deductions.width ?? 0,
    height: window.innerHeight - deductions.height ?? 0
  }
}

export function useDimensions(deductions?: Partial<Dimensions>): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions(deductions))

  function handleResize(): void {
    setDimensions(getDimensions(deductions))
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return dimensions
}
