import { EXTENSIONS_PATHS, REST_ENDPOINTS } from 'methone/shared/constants'
import { ExtensionBuilderResponseDTO } from 'methone/shared/dto/ExtensionBuilderResponseDTO'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'
import { api } from 'methone/utils/api'

const ENDPOINT = REST_ENDPOINTS.EXTENSIONS

export async function getExtensionBuilders(): Promise<ExtensionBuilderResponseDTO[]> {
  const { data: response } = await api.get(`/${ENDPOINT}/${EXTENSIONS_PATHS.BUILDERS}`)

  return response
}

export async function getEmailProviders(): Promise<ExtensionResponseDTO[]> {
  const { data: response } = await api.get(`/${ENDPOINT}/${EXTENSIONS_PATHS.EMAIL_PROVIDERS}`)

  return response
}

export async function getStorageProviders(): Promise<ExtensionResponseDTO[]> {
  const { data: response } = await api.get(`/${ENDPOINT}/${EXTENSIONS_PATHS.STORAGE_PROVIDERS}`)

  return response
}

export async function getExtensions(): Promise<ExtensionResponseDTO[]> {
  const { data: response } = await api.get(`/${ENDPOINT}`)

  return response
}

export async function getExtension(id: string): Promise<ExtensionResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function createExtension(extension: ExtensionResponseDTO): Promise<ExtensionResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, extension)

  return response
}

export async function updateExtension(id: string, extension: ExtensionResponseDTO): Promise<ExtensionResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, extension)

  return response
}
