import React from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ExtensionFormBase } from 'methone/components/ExtensionFormBase'
import { HookFormInput } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'

const defaultValues: ExtensionResponseDTO<DiskStorageConfig> = {
  id: '',
  name: '',
  type: '',
  enabled: true,
  status: EXTENSION_STATUS.STOPPED,
  jsonConfig: {
    path: '%DATA_PATH%'
  },
  createdAt: undefined,
  createdBy: '',
  updatedAt: undefined,
  updatedBy: '',
  deletedAt: undefined,
  deletedBy: ''
}

export const DiskStorageProvider: React.FC = () => {
  const { control, ...rest } = useForm<ExtensionResponseDTO<DiskStorageConfig>>({ defaultValues, mode: 'all' })

  return (
    <ExtensionFormBase control={control} {...rest}>
      <Card>
        <Card.Body>
          <div className="g1">
            <HookFormInput control={control} name="jsonConfig.path" label={i18n('Path')} />
          </div>
        </Card.Body>
      </Card>
    </ExtensionFormBase>
  )
}
