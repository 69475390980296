export class PermissionResponseDTO {
  public name: string
  public langs: Record<string, string>

  constructor(data: Partial<PermissionResponseDTO>) {
    this.name = data.name
    this.langs = data.langs
  }

  public static fromArray(data: PermissionResponseDTO[]): PermissionResponseDTO[] {
    return data.map((item) => new PermissionResponseDTO(item))
  }

  public static fromIterator(data: IterableIterator<PermissionResponseDTO>): PermissionResponseDTO[] {
    return Array.from(data).map((item) => new PermissionResponseDTO(item))
  }
}
