import styled from 'styled-components'

export const LogsContainer = styled.div`
  padding: var(--spacing-4);

  > div.table-wrapper {
    border: 1px solid var(--gray-800);
    border-radius: var(--spacing-1);
    overflow: hidden;

    > table {
      border-collapse: collapse;
      background: var(--gray-0);
      width: 100%;

      * {
        font-family: var(--font-code);
        font-size: 12px;
      }

      tr {
        th,
        td {
          padding: var(--spacing-2);
        }
      }

      > thead {
        tr {
          background: var(--gray-900);
        }
      }

      > tbody {
        tr {
          background: var(--gray-800);

          &:nth-child(even) {
            background: var(--gray-700);
          }

          > td.error {
            background: var(--red-500);
            color: var(--gray-0);
          }
        }
      }
    }
  }
`
