import { DefaultTheme, createGlobalStyle } from 'styled-components'

import { hexToRGB } from 'methone/utils/hexToRGB'

import { bootstrapOverride } from './bootstrap'
import { reactDatepickerOverride } from './react-datepicker'
import { reactSelectOverride } from './react-select'

function parseCSSVariables(theme: DefaultTheme): string {
  return (
    Object.entries(theme)
      .map(([key, value]) => {
        if (value.startsWith('#')) {
          return `--${key}: ${value};\n--${key}-rgb: ${hexToRGB(value)}`
        } else {
          return `--${key}: ${value}`
        }
      })
      .join(';\n') + ';\n'
  )
}

export const GlobalStyle = createGlobalStyle`
  :root {
    ${({ theme }) => parseCSSVariables(theme)}
  }

  /* CONFIGURE FONTS */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Roboto-Regular.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Roboto-Italic.ttf') format("truetype");
  }

  @font-face {
    font-family: 'Roboto Semibold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/Roboto-Medium.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Semibold';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/Roboto-MediumItalic.ttf') format("truetype");
  }

  @font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Roboto-Bold.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Bold';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Roboto-BoldItalic.ttf') format("truetype");
  }

  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/RobotoMono-Regular.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/RobotoMono-Italic.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/RobotoMono-Medium.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/RobotoMono-MediumItalic.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/RobotoMono-Bold.ttf') format("truetype");
  }
  @font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/RobotoMono-BoldItalic.ttf') format("truetype");
  }
  /* END CONFIGURE FONTS */

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  label {
    text-size-adjust: 100%;
  }

  html {
    --rem: var(--font-size-1);
    font-size: var(--font-size-1);
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: var(--bs-body-bg);
    color: var(--bs-body-color);
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  #root {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    > .methone-content {
      flex: 1;
    }
  }

  ${bootstrapOverride}
  ${reactSelectOverride}
  ${reactDatepickerOverride}
`
