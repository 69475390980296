import EventSourcePolyfill from 'eventsource'

import { REST_API_PREFIX, REST_ENDPOINTS, UTILS_PATHS } from 'methone/shared/constants'
import { PluginResponseDTO } from 'methone/shared/dto/PluginResponseDTO'
import { api } from 'methone/utils/api'

export async function getPlugins(): Promise<PluginResponseDTO[]> {
  const { data } = await api.get<PluginResponseDTO[]>(`/${REST_ENDPOINTS.UTILS}/${UTILS_PATHS.PLUGINS}`)

  return data
}

export function openLogStream(): EventSourcePolyfill {
  const baseURL = window.location.origin

  return new EventSourcePolyfill(`${baseURL}/${REST_API_PREFIX}/${REST_ENDPOINTS.UTILS}/${UTILS_PATHS.LOGS}`, {
    headers: { Authorization: String(api.defaults.headers.common.Authorization) }
  })
}

export async function getMetrics(): Promise<Metrics> {
  const { data } = await api.get<Metrics>(`/${REST_ENDPOINTS.UTILS}/${UTILS_PATHS.METRICS}`)

  return data
}
