import { css } from 'styled-components'

export const bootstrapOverride = css`
  :root {
    --bs-blue: var(--blue-500);
    --bs-indigo: var(--indigo);
    --bs-purple: var(--purple);
    --bs-pink: var(--pink);
    --bs-red: var(--red-500);
    --bs-orange: var(--yellow-800);
    --bs-yellow: var(--yellow-500);
    --bs-green: var(--green-500);
    --bs-teal: var(--teal);
    --bs-cyan: var(--cyan);
    --bs-black: var(--gray-1000);
    --bs-white: var(--gray-0);
    --bs-gray: var(--gray-600);
    --bs-gray-dark: var(--gray-800);
    --bs-gray-100: var(--gray-100);
    --bs-gray-200: var(--gray-200);
    --bs-gray-300: var(--gray-300);
    --bs-gray-400: var(--gray-400);
    --bs-gray-500: var(--gray-500);
    --bs-gray-600: var(--gray-600);
    --bs-gray-700: var(--gray-700);
    --bs-gray-800: var(--gray-800);
    --bs-gray-900: var(--gray-900);
    --bs-primary: var(--blue-500);
    --bs-primary-rgb: var(--blue-500-rgb);
    --bs-secondary: var(--gray-600);
    --bs-secondary-rgb: var(--gray-600-rgb);
    --bs-success: var(--green-500);
    --bs-success-rgb: var(--green-500-rgb);
    --bs-info: var(--cyan);
    --bs-info-rgb: var(--cyan-rgb);
    --bs-warning: var(--yellow-500);
    --bs-warning-rgb: var(--yellow-500-rgb);
    --bs-danger: var(--red-500);
    --bs-danger-rgb: var(--red-500-rgb);
    --bs-light: var(--gray-100);
    --bs-light-rgb: var(--gray-100-rgb);
    --bs-dark: var(--gray-900);
    --bs-dark-rgb: var(--gray-900-rgb);
    /* --bs-primary-text-emphasis: #6ea8fe; */
    /* --bs-secondary-text-emphasis: #a7acb1; */
    /* --bs-success-text-emphasis: #75b798; */
    /* --bs-info-text-emphasis: #6edff6; */
    /* --bs-warning-text-emphasis: #ffda6a; */
    /* --bs-danger-text-emphasis: #ea868f; */
    --bs-light-text-emphasis: var(--gray-100);
    --bs-dark-text-emphasis: var(--gray-300);
    /* --bs-primary-bg-subtle: #031633; */
    /* --bs-secondary-bg-subtle: #161719; */
    /* --bs-success-bg-subtle: #051b11; */
    /* --bs-info-bg-subtle: #032830; */
    /* --bs-warning-bg-subtle: #332701; */
    /* --bs-danger-bg-subtle: #2c0b0e; */
    --bs-light-bg-subtle: var(--gray-800);
    /* --bs-dark-bg-subtle: #1a1d20; */
    /* --bs-primary-border-subtle: #084298; */
    /* --bs-secondary-border-subtle: #41464b; */
    /* --bs-success-border-subtle: #0f5132; */
    /* --bs-info-border-subtle: #087990; */
    /* --bs-warning-border-subtle: #997404; */
    /* --bs-danger-border-subtle: #842029; */
    --bs-light-border-subtle: var(--gray-700);
    --bs-dark-border-subtle: var(--gray-800);
    --bs-white-rgb: var(--gray-0-rgb);
    --bs-black-rgb: var(--gray-1000-rgb);
    --bs-font-sans-serif: var(--font-regular);
    --bs-font-monospace: var(--font-code);
    --bs-gradient: linear-gradient(180deg, rgba(var(--gray-0-rgb), 0.15), rgba(var(--gray-0-rgb), 0));
    --bs-body-font-family: var(--font-regular);
    --bs-body-font-size: var(--font-size-1);
    /* --bs-body-font-weight: 400; */
    --bs-body-line-height: 1;
    --bs-body-color: var(--gray-0);
    --bs-body-color-rgb: var(--gray-0-rgb);
    --bs-body-bg: var(--gray-800);
    --bs-body-bg-rgb: var(--gray-800-rgb);
    --bs-emphasis-color: var(--gray-0);
    --bs-emphasis-color-rgb: var(--gray-0-rgb);
    --bs-secondary-color: rgba(var(--gray-300-rgb), 0.75);
    --bs-secondary-color-rgb: var(--gray-300-rgb);
    --bs-secondary-bg: var(--gray-800);
    --bs-secondary-bg-rgb: var(--gray-800-rgb);
    --bs-tertiary-color: rgba(var(--gray-300-rgb), 0.5);
    --bs-tertiary-color-rgb: var(--gray-300-rgb);
    /* --bs-tertiary-bg: #2b3035; */
    /* --bs-tertiary-bg-rgb: 43, 48, 53; */
    /* --bs-heading-color: inherit; */
    /* --bs-link-color: #6ea8fe; */
    /* --bs-link-color-rgb: 110, 168, 254; */
    /* --bs-link-decoration: underline; */
    /* --bs-link-hover-color: #8bb9fe; */
    /* --bs-link-hover-color-rgb: 139, 185, 254; */
    /* --bs-code-color: #e685b5; */
    --bs-highlight-color: var(--gray-300);
    /* --bs-highlight-bg: #664d03; */
    /* --bs-border-width: 1px; */
    /* --bs-border-style: solid; */
    --bs-border-color: var(--gray-700);
    --bs-border-color-translucent: rgba(var(--gray-0-rgb), 0.15);
    /* --bs-border-radius: 0.375rem; */
    /* --bs-border-radius-sm: 0.25rem; */
    /* --bs-border-radius-lg: 0.5rem; */
    /* --bs-border-radius-xl: 1rem; */
    /* --bs-border-radius-xxl: 2rem; */
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    /* --bs-border-radius-pill: 50rem; */
    --bs-box-shadow: 0 0.5rem 1rem rgba(var(--gray-1000-rgb), 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--gray-1000-rgb), 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--gray-1000-rgb), 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--gray-1000-rgb), 0.075);
    /* --bs-focus-ring-width: 0.25rem; */
    /* --bs-focus-ring-opacity: 0.25; */
    --bs-focus-ring-color: rgba(var(--blue-500-rgb), 0.25);
    /* --bs-form-valid-color: #75b798; */
    /* --bs-form-valid-border-color: #75b798; */
    /* --bs-form-invalid-color: #ea868f; */
    /* --bs-form-invalid-border-color: #ea868f; */
  }

  .card {
    --bs-card-spacer-y: var(--spacing-4);
    --bs-card-spacer-x: var(--spacing-4);
    --bs-card-title-spacer-y: var(--spacing-2);
    /* --bs-card-title-color: ; */
    /* --bs-card-subtitle-color: ; */
    /* --bs-card-border-width: var(--bs-border-width); */
    --bs-card-border-color: rgba(var(--gray-600-rgb), 0.25);
    /* --bs-card-border-radius: var(--bs-border-radius); */
    --bs-card-box-shadow: var(--shadow);
    /* --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width))); */
    --bs-card-cap-padding-y: var(--spacing-4);
    --bs-card-cap-padding-x: var(--spacing-4);
    /* --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03); */
    /* --bs-card-cap-color: ; */
    /* --bs-card-height: ; */
    --bs-card-color: var(--gray-0);
    --bs-card-bg: var(--gray-700);
    --bs-card-img-overlay-padding: var(--spacing-2);
    --bs-card-group-margin: var(--spacing-2);
  }

  .btn:focus,
  .form-control:focus {
    box-shadow: none;
  }

  .form-control {
    /* display: block; */
    height: 36px;
    /* width: 100%; */
    padding: var(--spacing-1) var(--spacing-2);
    font-size: var(--font-size-1);
    font-weight: unset;
    font-family: var(--font-regular);
    line-height: 1;
    color: var(--gray-0);
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    background-color: var(--gray-500);
    /* background-clip: padding-box; */
    border: var(--bs-border-width) solid var(--gray-500);
    /* border-radius: var(--bs-border-radius); */
    /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */

    &:disabled {
      border-color: var(--bs-secondary-bg);
    }

    &:focus {
      background-color: var(--gray-500);
    }
  }

  .dropdown {
    > .dropdown-menu {
      /* --bs-dropdown-zindex: 1000; */
      /* --bs-dropdown-min-width: 10rem; */
      --bs-dropdown-padding-x: var(--spacing-2);
      --bs-dropdown-padding-y: var(--spacing-2);
      /* --bs-dropdown-spacer: 0.125rem; */
      /* --bs-dropdown-font-size: 1rem; */
      /* --bs-dropdown-color: var(--bs-body-color); */
      --bs-dropdown-bg: var(--gray-500);
      --bs-dropdown-border-color: var(--gray-800);
      --bs-dropdown-border-radius: var(--spacing-2);
      /* --bs-dropdown-border-width: var(--bs-border-width); */
      /* --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width)); */
      /* --bs-dropdown-divider-bg: var(--bs-border-color-translucent); */
      /* --bs-dropdown-divider-margin-y: 0.5rem; */
      --bs-dropdown-box-shadow: var(--shadow);
      /* --bs-dropdown-link-color: var(--bs-body-color); */
      /* --bs-dropdown-link-hover-color: var(--bs-body-color); */
      /* --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg); */
      /* --bs-dropdown-link-active-color: #fff; */
      /* --bs-dropdown-link-active-bg: #0d6efd; */
      /* --bs-dropdown-link-disabled-color: var(--bs-tertiary-color); */
      --bs-dropdown-item-padding-x: var(--spacing-4);
      --bs-dropdown-item-padding-y: var(--spacing-2);
      /* --bs-dropdown-header-color: #6c757d; */
      /* --bs-dropdown-header-padding-x: 1rem; */
      /* --bs-dropdown-header-padding-y: 0.5rem; */
      --bs-dropdown-item-border-radius: var(--spacing-1);

      > .dropdown-item {
        display: flex;
        align-items: center;
        gap: var(--spacing-2);

        &:hover {
          background: rgba(var(--gray-700-rgb), 0.5);
        }
      }
    }
  }

  .modal {
    /* --bs-modal-zindex: 1055; */
    /* --bs-modal-width: 500px; */
    /* --bs-modal-padding: 1rem; */
    /* --bs-modal-margin: 0.5rem; */
    --bs-modal-color: var(--gray-0);
    --bs-modal-bg: var(--gray-700);
    --bs-modal-border-color: rgba(var(--gray-700-rgb), 0.25);
    /* --bs-modal-border-width: var(--bs-border-width); */
    /* --bs-modal-border-radius: var(--bs-border-radius-lg); */
    /* --bs-modal-box-shadow: var(--bs-box-shadow-sm); */
    /* --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width))); */
    /* --bs-modal-header-padding-x: 1rem; */
    /* --bs-modal-header-padding-y: 1rem; */
    /* --bs-modal-header-padding: 1rem 1rem; */
    /* --bs-modal-header-border-color: var(--bs-border-color); */
    /* --bs-modal-header-border-width: var(--bs-border-width); */
    /* --bs-modal-title-line-height: 1.5; */
    /* --bs-modal-footer-gap: 0.5rem; */
    /* --bs-modal-footer-bg: ; */
    /* --bs-modal-footer-border-color: var(--bs-border-color); */
    /* --bs-modal-footer-border-width: var(--bs-border-width); */

    > .modal-dialog {
      > .modal-content {
        > .modal-header {
          background: rgba(var(--bs-body-color-rgb), 0.03);
        }
      }
    }
  }

  .accordion {
    /* --bs-accordion-color: var(--bs-body-color); */
    --bs-accordion-bg: var(--gray-700);
    /* --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease; */
    --bs-accordion-border-color: rgba(var(--gray-600-rgb), 0.5);
    /* --bs-accordion-border-width: var(--bs-border-width); */
    /* --bs-accordion-border-radius: var(--bs-border-radius); */
    /* --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width))); */
    --bs-accordion-btn-padding-x: var(--spacing-4);
    --bs-accordion-btn-padding-y: var(--spacing-4);
    /* --bs-accordion-btn-color: var(--bs-body-color); */
    /* --bs-accordion-btn-bg: var(--bs-accordion-bg); */
    /* --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e"); */
    /* --bs-accordion-btn-icon-width: 1.25rem; */
    /* --bs-accordion-btn-icon-transform: rotate(-180deg); */
    /* --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out; */
    /* --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23052c65%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e"); */
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: var(--spacing-4);
    --bs-accordion-body-padding-y: var(--spacing-4);
    --bs-accordion-active-color: var(--bs-body-color);
    --bs-accordion-active-bg: rgba(var(--bs-body-color-rgb), 0.03);
  }
`

// /* ---------------------------------------------------------------------------------------------------------------- */

// .mt-1,
// .my-1 {
//   margin-top: var(--spacing-1) !important;
// }

// .mt-2,
// .my-2 {
//   margin-top: var(--spacing-2) !important;
// }

// .mt-3,
// .my-3 {
//   margin-top: var(--spacing-3) !important;
// }

// .mt-4,
// .my-4 {
//   margin-top: var(--spacing-4) !important;
// }

// .mt-5,
// .my-5 {
//   margin-top: var(--spacing-5) !important;
// }

// .mb-1,
// .my-1 {
//   margin-bottom: var(--spacing-1) !important;
// }

// .mb-2,
// .my-2 {
//   margin-bottom: var(--spacing-2) !important;
// }

// .mb-3,
// .my-3 {
//   margin-bottom: var(--spacing-3) !important;
// }

// .mb-4,
// .my-4 {
//   margin-bottom: var(--spacing-4) !important;
// }

// .mb-5,
// .my-5 {
//   margin-bottom: var(--spacing-5) !important;
// }

// /* ---------------------------------------------------------------------------------------------------------------- */

// .ml-1,
// .mx-1 {
//   margin-left: var(--spacing-1) !important;
// }

// .ml-2,
// .mx-2 {
//   margin-left: var(--spacing-2) !important;
// }

// .ml-3,
// .mx-3 {
//   margin-left: var(--spacing-3) !important;
// }

// .ml-4,
// .mx-4 {
//   margin-left: var(--spacing-4) !important;
// }

// .ml-5,
// .mx-5 {
//   margin-left: var(--spacing-5) !important;
// }

// .mr-1,
// .mx-1 {
//   margin-right: var(--spacing-1) !important;
// }

// .mr-2,
// .mx-2 {
//   margin-right: var(--spacing-2) !important;
// }

// .mr-3,
// .mx-3 {
//   margin-right: var(--spacing-3) !important;
// }

// .mr-4,
// .mx-4 {
//   margin-right: var(--spacing-4) !important;
// }

// .mr-5,
// .mx-5 {
//   margin-right: var(--spacing-5) !important;
// }

// /* ---------------------------------------------------------------------------------------------------------------- */

// .row {
//   > [class*='col-'] {
//     padding-left: var(--spacing-2);
//     padding-right: var(--spacing-2);
//   }
// }
