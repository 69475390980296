export const colors = {
  black: '#000000',
  white: '#FFFFFF',

  'blue-100': '#d7e7ff',
  'blue-200': '#a5c9fe',
  'blue-300': '#72abfe',
  'blue-400': '#408cfd',
  'blue-500': '#0d6efd',
  'blue-600': '#0b63e3',
  'blue-700': '#0a58ca',
  'blue-800': '#094db1',
  'blue-900': '#074297',
  cyan: '#0dcaf0',
  'gray-0': '#FFFFFF',
  'gray-100': '#CDD0D4',
  'gray-200': '#9498A2',
  'gray-300': '#6C727D',
  'gray-400': '#535760',
  'gray-500': '#40444B',
  'gray-600': '#33363C',
  'gray-700': '#2A2C31',
  'gray-800': '#242529',
  'gray-900': '#1F2024',
  'gray-1000': '#131416',
  'green-100': '#84e8ba',
  'green-200': '#59e0a1',
  'green-300': '#2ed889',
  'green-400': '#21b26f',
  'green-500': '#198754',
  'green-600': '#16794b',
  'green-700': '#146c43',
  'green-800': '#115e3a',
  'green-900': '#0f5132',
  indigo: '#6610f2',
  pink: '#d63384',
  purple: '#6f42c1',
  'red-100': '#fae3e5',
  'red-200': '#f3b7bd',
  'red-300': '#eb8c95',
  'red-400': '#e4606d',
  'red-500': '#dc3545',
  'red-600': '#c62f3e',
  'red-700': '#b02a37',
  'red-800': '#9a2530',
  'red-900': '#841f29',
  teal: '#20c997',
  'yellow-100': '#fff4d3',
  'yellow-200': '#ffe7a0',
  'yellow-300': '#ffdb6d',
  'yellow-400': '#ffce3a',
  'yellow-500': '#ffc107',
  'yellow-600': '#e5ad06',
  'yellow-700': '#fd9a47',
  'yellow-800': '#fd7e14',
  'yellow-900': '#e37112'
}

export const measurements = {
  'spacing-1': '4px',
  'spacing-2': '8px',
  'spacing-3': '12px',
  'spacing-4': '16px',
  'spacing-5': '20px',
  'spacing-6': '24px',

  'font-size-1': '14px',
  'font-size-2': '16px',
  'font-size-3': '18px',
  'font-size-4': '22px',
  'font-size-5': '26px',
  'font-size-6': '30px',
  'font-size-7': '34px'
}

export const theme = {
  themeName: 'dark',
  ...colors,
  ...measurements,
  shadow: `0 0 16px 0 rgba(var(--gray-1000), 0.25)`,
  'font-code': '"Roboto Mono", monospace',
  'font-regular': '"Roboto", sans-serif',
  'font-semibold': '"Roboto Semibold", sans-serif',
  'font-bold': '"Roboto Bold", sans-serif'
}
