import React from 'react'

import cx from 'classnames'

import { i18n } from 'methone/services/i18n'

import { SortedTableContainer, SortedTableWrapper } from './styled'

export type SortedTableSort = 'ASC' | 'DESC'
type CommonKnownColumnItem = string | number | JSX.Element | JSX.Element[]
type KnownColumnItem = CommonKnownColumnItem | { __html: string }
type ItemType = Record<string, KnownColumnItem>

export interface SortedTableColumnProps<Item extends ItemType, K extends keyof Item = keyof Item> {
  title: string
  columnRef: K
  sortable?: boolean
  width?: number | string
  /** Used to define if will show this option based on a boolean value */
  hide?: boolean
  headerCellStyle?: React.CSSProperties
  bodyCellStyle?: React.CSSProperties
}

export interface SortedTableActionsProps<Item extends ItemType> {
  icon: FAIconType
  onClick: (item: Item) => void
  iconProps?: React.HTMLAttributes<HTMLElement>
  hide?: boolean
}

export interface SortedTableProps<Item extends ItemType> {
  items: Item[]
  columns: SortedTableColumnProps<Item>[]
  tableProps?: React.TableHTMLAttributes<HTMLTableElement>
  actions?: SortedTableActionsProps<Item>[]
  noDataMessage?: string | JSX.Element
  noDataMessageShowAlways?: boolean
  className?: string
  sort?: {
    column: string
    direction: SortedTableSort
    onSort: (column: keyof Item, direction: SortedTableSort) => void
  }
}

function dangerousHTMLGuard(item: KnownColumnItem): item is { __html: string } {
  return typeof item === 'object' && '__html' in item
}

export function SortedTable<Item extends ItemType>(props: SortedTableProps<Item>): JSX.Element {
  const { columns, items, actions, className, noDataMessage, noDataMessageShowAlways, sort, tableProps } = props

  function printTableItemIsValid(item: CommonKnownColumnItem): CommonKnownColumnItem {
    const isString = typeof item === 'string'
    const isNumber = typeof item === 'number'
    const isElement = React.isValidElement(item)
    const isElementArray = Array.isArray(item) && item.every(React.isValidElement)

    if (isString || isNumber || isElement || isElementArray) {
      return item
    } else {
      return ' '
    }
  }

  function onSort(columnRef: keyof Item): void {
    sort?.onSort?.(columnRef, sort.direction === 'ASC' ? 'DESC' : 'ASC')
  }

  function filterColumns(e: SortedTableColumnProps<Item, keyof Item> | SortedTableActionsProps<Item>): boolean {
    if (e == null) {
      return false
    }

    return !(e.hide ?? false)
  }

  return (
    <SortedTableWrapper className={cx('sorted-table__wrapper', className)}>
      <SortedTableContainer className="sorted-table__table" {...tableProps}>
        <thead>
          <tr className="table-row-0 table-row-head">
            {columns.filter(filterColumns).map(({ title, columnRef: ref, sortable, width, headerCellStyle }) => (
              <th
                key={`table-column-${String(ref)}`}
                className={`table-column-${String(ref)} table-column-head-${sortable ? 'sortable' : 'not-sortable'}`}
                onClick={() => onSort(ref)}
                style={width ? { width } : {}}
              >
                <div style={headerCellStyle}>
                  {title}
                  {sortable && sort?.column === ref && (
                    <i className={`fas fa-arrow-${sort.direction === 'ASC' ? 'down' : 'up'}`} />
                  )}
                </div>
              </th>
            ))}
            {actions && <th className="table-column-actions" />}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={`table-row-${index}`} className={`table-row-${index}`}>
              {columns.filter(filterColumns).map(({ columnRef: ref, bodyCellStyle }) => {
                const itemToRender = item[ref]
                if (dangerousHTMLGuard(itemToRender)) {
                  return (
                    <td
                      key={`table-column-${String(ref)}`}
                      className={`table-column-${String(ref)} table-row-${index}-column-${String(ref)}`}
                    >
                      <div style={bodyCellStyle} dangerouslySetInnerHTML={itemToRender} />
                    </td>
                  )
                }

                return (
                  <td
                    key={`table-column-${String(ref)}`}
                    className={`table-column-${String(ref)} table-row-${index}-column-${String(ref)}`}
                  >
                    <div style={bodyCellStyle}>{printTableItemIsValid(itemToRender)}</div>
                  </td>
                )
              })}
              {actions != null && actions.length > 0 && (
                <td className={`table-column-actions table-row-${index}-column-actions`}>
                  <div>
                    {actions.filter(filterColumns).map(({ icon, iconProps, onClick }, idx) => (
                      <button type="button" key={`table-action-${idx}`} onClick={() => onClick(item)}>
                        <i className={icon} {...iconProps} />
                      </button>
                    ))}
                  </div>
                </td>
              )}
            </tr>
          ))}
          {(items.length === 0 || noDataMessageShowAlways === true) && (
            <tr>
              <td className="table-column-no-data" colSpan={columns.length + (actions ? 1 : 0)}>
                <div>{noDataMessage ?? i18n('No data')}</div>
              </td>
            </tr>
          )}
        </tbody>
      </SortedTableContainer>
    </SortedTableWrapper>
  )
}
