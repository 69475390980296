import { exposeContext, exposeItem } from '../lib/expose'

// Webapp packages
exposeItem('@react-input/mask', require('@react-input/mask'))
exposeItem('@react-input/number-format', require('@react-input/number-format'))
exposeItem('@reduxjs/toolkit', require('@reduxjs/toolkit'))
exposeItem('ahooks', require('ahooks'))
exposeItem('bootstrap', require('bootstrap'))
exposeItem('classnames', require('classnames'))
exposeItem('eventsource', require('eventsource'))
exposeItem('filesize', require('filesize'))
exposeItem('polished', require('polished'))
exposeItem('react', require('react'))
exposeItem('react-bootstrap', require('react-bootstrap'))
exposeItem('react-custom-scrollbars-2', require('react-custom-scrollbars-2'))
exposeItem('react-datepicker', require('react-datepicker'))
exposeItem('react-dom', require('react-dom'))
exposeItem('react-dropzone', require('react-dropzone'))
exposeItem('react-hook-form', require('react-hook-form'))
exposeItem('react-redux', require('react-redux'))
exposeItem('react-router-dom', require('react-router-dom'))
exposeItem('react-select', require('react-select'))
exposeItem('react-select/animated', require('react-select/animated'))
exposeItem('react-select/async', require('react-select/async'))
exposeItem('react-select/creatable', require('react-select/creatable'))
exposeItem('react-select/async-creatable', require('react-select/async-creatable'))
exposeItem('react-toastify', require('react-toastify'))
exposeItem('styled-components', require('styled-components'))

// Fallback
exposeItem('assert', require('assert'))
exposeItem('buffer', require('buffer'))
exposeItem('console', require('console'))
exposeItem('constants', require('constants')) // eslint-disable-line n/no-deprecated-api
exposeItem('crypto', require('crypto'))
exposeItem('domain', require('domain')) // eslint-disable-line n/no-deprecated-api
exposeItem('events', require('events'))
exposeItem('http', require('http'))
exposeItem('https', require('https'))
exposeItem('os', require('os'))
exposeItem('path', require('path'))
exposeItem('punycode', require('punycode')) // eslint-disable-line n/no-deprecated-api
exposeItem('process', require('process'))
exposeItem('querystring', require('querystring'))
exposeItem('stream', require('stream'))
exposeItem('string_decoder', require('string_decoder'))
exposeItem('sys', require('sys')) // eslint-disable-line n/no-deprecated-api
exposeItem('timers', require('timers'))
exposeItem('tty', require('tty'))
exposeItem('url', require('url'))
exposeItem('util', require('util'))
exposeItem('vm', require('vm'))
exposeItem('zlib', require('zlib'))

// Workspace packages
exposeItem('axios', require('axios'))
exposeItem('date-fns', require('date-fns'))
exposeItem('joi', require('joi'))
exposeItem('lodash', require('lodash'))

exposeContext(require.context('../components', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/components')
exposeContext(require.context('../hooks', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/hooks')
exposeContext(require.context('../i18n', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/i18n')
exposeContext(require.context('../lib', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/lib')
exposeContext(require.context('../../../shared/src', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/shared')
exposeContext(require.context('../services', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/services')
exposeContext(require.context('../store', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/store')
exposeContext(require.context('../styles', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/styles')
exposeContext(require.context('../utils', true, /^((?!(.*([.d]\.ts?)))(.*([j|t]sx?)))?$/), 'methone/utils')
