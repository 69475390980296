import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`

export const FormRowHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span:first-child {
    font-family: var(--font-semibold);
  }
`

export const FormRowHeaderContent = styled.div`
  display: grid;
  grid-template-areas: 'NM ST';
  grid-template-columns: 1fr 75px;
  gap: var(--spacing-2);
`

export const FormRowFooter = styled.div`
  display: flex;
  gap: var(--spacing-2);
  align-items: center;

  span {
    margin: 0 var(--spacing-1);
  }

  .btn.btn-link {
    padding: 0;
    text-decoration: none;
    color: var(--gray-0);
  }
`
