import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const EditorContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    > .card {
      > .card-body {
        > button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--spacing-2);
          height: 34px;
        }

        > fieldset {
          display: grid;
          gap: var(--spacing-2);
          grid-template-areas: 'NM TX' 'EM GP';
          grid-template-columns: 1fr 250px;

          > .form-input-container--name {
            grid-area: NM;
          }

          > .form-input-container--taxId {
            grid-area: TX;
          }

          > .form-input-container--email {
            grid-area: EM;
          }

          > .form-input-container--group {
            grid-area: GP;
          }
        }
      }
    }

    > .addresses-list {
      margin-top: var(--spacing-2);

      > .accordion {
        > .accordion-item {
          > .accordion-header {
            position: relative;

            > .trash-button {
              position: absolute;
              right: 0;
              top: 0;
              z-index: 3;
              height: 49px;
              width: 49px;
              background: none;
              border: none;

              i,
              svg > path {
                color: var(--red-500);
              }
            }

            > .accordion-button {
              &::after {
                display: none;
              }
            }
          }

          > .accordion-collapse {
            > .accordion-body {
              > fieldset {
                display: grid;
                gap: var(--spacing-2);
                grid-template-areas: 'DSC DSC DSC PHO' 'STS NMB DTS NBR' 'CYT STA STA CTR';
                grid-template-columns: 2fr 100px 200px 1fr;

                > div[class*='form-input-container--addresses.'] {
                  &[class*='.description'] {
                    grid-area: DSC;
                  }

                  &[class*='.phone'] {
                    grid-area: PHO;
                  }

                  &[class*='.street'] {
                    grid-area: STS;
                  }

                  &[class*='.number'] {
                    grid-area: NMB;
                  }

                  &[class*='.details'] {
                    grid-area: DTS;
                  }

                  &[class*='.neighborhood'] {
                    grid-area: NBR;
                  }

                  &[class*='.city'] {
                    grid-area: CYT;
                  }

                  &[class*='.state'] {
                    grid-area: STA;
                  }

                  &[class*='.country'] {
                    grid-area: CTR;
                  }
                }
              }
            }
          }

          &.add-button {
            > .accordion-header {
              > button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--spacing-2);
              }
            }
          }
        }
      }
    }

    > .form-footer {
      margin-top: var(--spacing-2);

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .actions-area {
          display: flex;
          justify-content: center;
          align-items: center;

          > button {
            padding-top: 5px;
            padding-bottom: 5px;

            &.btn.btn-link {
              color: var(--gray-0);
              text-decoration: none;
            }
          }

          > span {
            margin-left: 10px;
          }
        }

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`
