import { useState, useEffect, SetStateAction, Dispatch } from 'react'

import { storageService } from 'methone/services/storageService'

type ReturnType<T> = [T, Dispatch<SetStateAction<T>>]

export function usePersistedState<T extends keyof Storage>(key: T, initialState: Storage[T]): ReturnType<Storage[T]> {
  const [value, setValue] = useState<Storage[T]>(() => {
    const storageValue = storageService.getItem(key)

    return storageValue ?? initialState
  })

  useEffect(() => {
    storageService.setItem(key, value)
  }, [key, value])

  return [value, setValue]
}
