import { CustomValidator } from 'joi'

export const plateRegex = /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/
// eslint-disable-next-line prettier/prettier
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
export const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function validPlate(plate: string): boolean {
  return plateRegex.test(plate)
}

export function validEmail(email: string): boolean {
  return emailRegex.test(email)
}

export function validUUID(id: string): boolean {
  return uuidRegex.test(id)
}

export function validBetween(value: number, min?: number, max?: number): boolean {
  return value >= (min ?? Number.MIN_SAFE_INTEGER) && value <= (max ?? Number.MAX_SAFE_INTEGER)
}

export function validOrderBy(acceptedKeys: string[]): CustomValidator<[string, 'ASC' | 'DESC'][]> {
  return (items, helpers) => {
    for (const [col, dir] of items) {
      const match = col.match(/\D+\((\D+)(,.*)?\)/)
      const colName = (match?.[1] ?? col).trim()

      if (!(acceptedKeys ?? [colName]).includes(colName)) {
        return helpers.error('methone.orderByKeys', { value: colName, values: acceptedKeys })
      }

      if (!['ASC', 'DESC'].includes(dir)) {
        return helpers.error('methone.orderByDirections', { value: dir, values: ['ASC', 'DESC'] })
      }
    }

    return items
  }
}

function validCPF(strCPF: string): boolean {
  let sum = 0
  let rest = 0

  for (let i = 1; i <= 9; i++) {
    sum += Number(strCPF.substring(i - 1, i)) * (11 - i)
  }

  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  } else if (rest !== Number(strCPF.substring(9, 10))) {
    return false
  }

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += Number(strCPF.substring(i - 1, i)) * (12 - i)
  }

  rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) {
    rest = 0
  } else if (rest !== Number(strCPF.substring(10, 11))) {
    return false
  }

  return true
}

function validCNPJ(strCNPJ: string): boolean {
  let sum = 0
  let rest = 0

  for (let i = 0; i < 12; i++) {
    if (i < 4) {
      sum += Number(strCNPJ[i]) * (5 - i)
    } else if (i >= 4) {
      sum += Number(strCNPJ[i]) * (13 - i)
    }
  }

  rest = sum % 11
  if ((rest < 2 ? 0 : 11 - rest).toString() !== strCNPJ[12]) {
    return false
  }

  sum = 0
  for (let i = 0; i < 13; i++) {
    if (i < 5) {
      sum += Number(strCNPJ[i]) * (6 - i)
    } else if (i >= 5) {
      sum += Number(strCNPJ[i]) * (14 - i)
    }
  }

  rest = sum % 11
  if ((rest < 2 ? 0 : 11 - rest).toString() !== strCNPJ[13]) {
    return false
  }

  return true
}

export function validTaxID(taxId: string): boolean {
  taxId = (taxId ?? '').replace(/[^\d]/g, '')

  if (!taxId || taxId.trim() === '' || taxId.length < 11 || taxId.length > 14) {
    return false
  }

  if (taxId.length === 11) {
    return validCPF(taxId)
  }

  if (taxId.length === 14) {
    return validCNPJ(taxId)
  }

  return false
}
