import React from 'react'
import type { FieldValues as FV, Path } from 'react-hook-form'

import { HFSelectProps, SelectRef } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { listClients } from 'methone/services/rest/clients'

import { AutoComplete, AutoCompleteHookForm, ACProps } from '../AutoComplete'

type Props<TFV extends FV, TN extends Path<TFV>> = Omit<HFSelectProps<TFV, TN>, 'options'>
type CSProps = Omit<ACProps, 'searchFunction'>

export const ClientSelector = React.forwardRef<SelectRef, CSProps>(function ClientSelector(props, ref) {
  async function searchFunction(newValue: string): Promise<Options> {
    const data = await listClients({
      name: Number.isNaN(Number(newValue)) ? newValue : undefined,
      taxId: !Number.isNaN(Number(newValue)) ? newValue : undefined
    })

    return data.entries.map((c) => ({ value: c.id, label: `${c.name} (${c.taxId})` }))
  }

  return (
    <AutoComplete
      {...props}
      ref={ref}
      searchFunction={searchFunction}
      placeholder=""
      noOptionsMessage={() => i18n('No clients founded')}
    />
  )
})

export function ClientSelectorHookForm<TFV extends FV, TN extends Path<TFV>>(props: Props<TFV, TN>): JSX.Element {
  async function searchFunction(newValue: string): Promise<Options> {
    const data = await listClients({
      name: Number.isNaN(Number(newValue)) ? newValue : undefined,
      taxId: !Number.isNaN(Number(newValue)) ? newValue : undefined
    })

    return data.entries.map((c) => ({ value: c.id, label: `${c.name} (${c.taxId})` }))
  }

  return (
    <AutoCompleteHookForm
      {...props}
      searchFunction={searchFunction}
      selectProps={{
        placeholder: '',
        noOptionsMessage: () => i18n('No clients founded'),
        ...(props.selectProps ?? {})
      }}
    />
  )
}
