import { transparentize } from 'polished'
import { css } from 'styled-components'

const reactSelectOption = css`
  &.react-select__option--is-selected {
    background: var(--gray-900);
    font-family: var(--font-bold);
  }

  &.react-select__option--is-focused {
    background: ${({ theme }) => transparentize(0.8, theme['blue-500'])};

    &.react-select__option--is-selected {
      background: ${({ theme }) => transparentize(0.5, theme['blue-500'])};
    }
  }
`

export const reactSelectOverride = css`
  .react-select {
    > div.react-select__control {
      border: 1px solid var(--gray-500);
      background: var(--gray-500);
      box-shadow: none;
      height: 34px;
      min-height: 36px;

      &.react-select__control--is-focused {
        border-color: #86b7fe;
      }

      > div.react-select__value-container {
        height: 34px;
        padding: 2px 4px;

        &.react-select__value-container--is-multi {
          overflow-y: auto;
        }

        > div.react-select__placeholder {
          color: var(--gray-0);
          opacity: 0.8;
        }

        > div.react-select__single-value {
          color: var(--gray-0);
        }

        > div.react-select__multi-value {
          background: var(--gray-700);
          height: 24px;

          > div.react-select__multi-value__label {
            color: var(--gray-0);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        > div.react-select__input-container {
          cursor: text;

          > input.react-select__input {
            color: var(--gray-0) !important;
          }
        }
      }

      > div.react-select__indicators {
        height: 34px;

        > span.react-select__indicator-separator {
          background: var(--gray-200);
        }
      }
    }

    > div.react-select__menu {
      margin-top: var(--spacing-1);
      background: var(--gray-700);
      z-index: 9980;

      > div.react-select__menu-list {
        > div.react-select__option {
          ${reactSelectOption}
        }

        > div.react-select__menu-notice--no-options {
          color: var(--gray-0);
          opacity: 0.8;
        }

        > div.react-select__group {
          > div.react-select__group-heading {
            color: var(--gray-100);
          }

          > div {
            > div.react-select__option {
              ${reactSelectOption}
            }
          }
        }
      }
    }

    &.react-select--is-disabled {
      > div.react-select__control {
        background: var(--gray-800);
        border-color: var(--gray-800);
      }
    }
  }
`
