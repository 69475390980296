import React from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'

interface GlobalNotificationOptions {
  autoClose?: number
  closeButton?: boolean
  icon?: FAIconType | false
}

const defaultOptions: GlobalNotificationOptions = {
  autoClose: 5000,
  closeButton: false,
  icon: false
}

function handleConfiguration(options: GlobalNotificationOptions, icon?: FAIconType): ToastOptions {
  return {
    autoClose: options.autoClose ?? defaultOptions.autoClose,
    closeButton: options.closeButton ?? defaultOptions.closeButton,
    icon:
      typeof options.icon === 'string' ? (
        <i className={options?.icon ?? icon} style={{ fontSize: 'var(--font-size-3)' }} />
      ) : (
        false
      )
  }
}

export const globalNotification = {
  success: (text: ToastContent, options = defaultOptions): void => {
    toast.success(text, handleConfiguration(options, 'fas fa-check-circle'))
  },
  warning: (text: ToastContent, options = defaultOptions): void => {
    toast.warning(text, handleConfiguration(options, 'fas fa-exclamation-triangle'))
  },
  error: (text: ToastContent, options = defaultOptions): void => {
    toast.error(text, handleConfiguration(options, 'fas fa-times-circle'))
  },
  info: (text: ToastContent, options = defaultOptions): void => {
    toast.info(text, handleConfiguration(options, 'fas fa-info-circle'))
  }
}
