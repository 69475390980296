import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const ClientsContainer = styled(Container)`
  padding: var(--spacing-4);

  > .card.filters-header {
    > .card-body {
      > form {
        display: grid;
        grid-template-areas: 'NME NME NME' 'EML TID CGP' 'ACT ACT ACT';
        grid-template-columns: 1fr 200px 300px;
        gap: var(--spacing-3);

        > .form-input-container--name {
          grid-area: NME;
        }

        > .form-input-container--email {
          grid-area: EML;
        }

        > .form-input-container--taxId {
          grid-area: TID;
        }

        > .form-select-container--groups {
          grid-area: CGP;
        }

        > .actions {
          grid-area: ACT;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: var(--spacing-2);

          > button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--spacing-2);
          }

          > div.dropdown {
            margin-right: auto;

            > button.export-button {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: var(--spacing-2);

              &:hover,
              &.show {
                i {
                  color: var(--gray-0);
                }
              }
            }
          }
        }
      }
    }
  }

  > div.sorted-table__wrapper {
    > table {
      .table-column-name {
        > div {
          display: flex;
          gap: var(--spacing-2);

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;

            &.badge {
              height: 21px;
            }
          }
        }
      }
    }
  }

  > div.pagination__wrapper {
    margin-top: var(--spacing-2);
  }
`
