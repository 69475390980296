export enum MethoneErrors {
  BAD_REQUEST /* ==================== */ = 4000,

  DUPLICATION_CLIENT /* ============= */ = 4001,
  DUPLICATION_PERMISSION /* ========= */ = 4002,
  DUPLICATION_TOKEN /* ============== */ = 4003,
  DUPLICATION_USER /* =============== */ = 4004,
  DUPLICATION_CONFIG /* ============= */ = 4005,
  /* ADD_ME                              = 4006, */
  /* ADD_ME                              = 4007, */
  /* ADD_ME                              = 4008, */
  /* ADD_ME                              = 4009, */
  /* ADD_ME                              = 40010, */
  /* ADD_ME                              = 40011, */
  /* ADD_ME                              = 40012, */
  /* ADD_ME                              = 40013, */
  /* ADD_ME                              = 40014, */
  /* ADD_ME                              = 40015, */
  /* ADD_ME                              = 40016, */
  /* ADD_ME                              = 40017, */
  /* ADD_ME                              = 40018, */
  /* ADD_ME                              = 40019, */
  /* ADD_ME                              = 40020, */

  INVALID_AUTH_TOKEN /* ============= */ = 40021,
  REFRESH_TOKEN_NOT_FOUND /* ======== */ = 40022,
  INVALID_TOKEN_TYPE /* ============= */ = 40023,
  MISSING_AUTH_TOKEN /* ============= */ = 40024,
  MALFORMED_AUTH_TOKEN /* =========== */ = 40025,
  INVALID_RESET_PASSWORD_TOKEN /* === */ = 40026,
  /* ADD_ME                              = 40027, */
  /* ADD_ME                              = 40028, */
  /* ADD_ME                              = 40029, */
  /* ADD_ME                              = 40030, */

  VALIDATION_ERROR /* =============== */ = 40031,
  CANT_DELETE_REQUIRED_EXTENSION /* = */ = 40132,

  /* ================================================================================== */

  UNAUTHORIZED /* ===================== */ = 4010,

  UNAUTHORIZED_ROUTE /* =============== */ = 4011,
  ONLY_ONE_SUPER_ADMIN /* ============= */ = 4012,
  CANT_MODIFY_OTHERS_PERMISSIONS /* === */ = 4013,
  CANT_MODIFY_SELF_PERMISSIONS /* ===== */ = 4014,
  CANT_DISABLE_SELF /* ================ */ = 4015,
  CANT_CREATE_OTHER_ADMINS /* ========= */ = 4016,
  CANT_MODIFY_USER /* ================= */ = 4017,
  CANT_DELETE_SELF /* ================= */ = 4018,
  CANT_DELETE_USER /* ================= */ = 4019,

  INACTIVE_ACCOUNT /* ================= */ = 40110,
  INVALID_CREDENTIALS /* ============== */ = 40111,
  REVOKED_REFRESH_TOKEN /* ============ */ = 40112,
  REVOKED_ACCESS_TOKEN /* ============= */ = 40113,
  USER_DISABLED /* ==================== */ = 40114,
  INVALID_REFRESH_TOKEN /* ============ */ = 40115,
  /* ADD_ME                                = 40116, */
  /* ADD_ME                                = 40117, */
  /* ADD_ME                                = 40118, */
  /* ADD_ME                                = 40119, */
  /* ADD_ME                                = 40120, */

  /* ================================================================================== */

  NOT_FOUND /* =============== */ = 4040,

  CLIENT_ADDRESS_NOT_FOUND /* = */ = 4041,
  CLIENT_NOT_FOUND /* ========= */ = 4042,
  USER_NOT_FOUND /* =========== */ = 4043,
  PERMISSION_NOT_FOUND /* ===== */ = 4044,
  TOKEN_NOT_FOUND /* ========== */ = 4045,
  CONFIG_NOT_FOUND /* ========= */ = 4046,
  /* ADD_ME                        = 4047, */
  /* ADD_ME                        = 4048, */
  /* ADD_ME                        = 4049, */
  /* ADD_ME                        = 40410, */

  FILE_NOT_FOUND /* =========== */ = 40411,
  /* ADD_ME                        = 40412, */
  /* ADD_ME                        = 40413, */
  /* ADD_ME                        = 40414, */
  /* ADD_ME                        = 40415, */
  /* ADD_ME                        = 40416, */
  /* ADD_ME                        = 40417, */
  /* ADD_ME                        = 40418, */
  /* ADD_ME                        = 40419, */
  /* ADD_ME                        = 40420, */

  ROUTE_NOT_FOUND /* ========== */ = 40421,

  /* ================================================================================== */

  METHOD_NOT_ALLOWED /* */ = 4050,

  /* ================================================================================== */

  INTERNAL_SERVER_ERROR /* =================== */ = 5000,

  MISSING_ENVIRONMENT_VARIABLES /* =========== */ = 5001,
  PERSISTANCE_ERROR /* ======================= */ = 5002,
  MISSING_REPOSITORY_MODEL /* ================ */ = 5003,
  INVALID_WHERE_VALUE_TYPE /* ================ */ = 5004,
  UNSUPPORTED_WHERE_VALUE_TYPE /* ============ */ = 5005,
  UNSUPPORTED_WHERE_OPERATOR /* ============== */ = 5006,
  METHOD_CAN_ONLY_BE_CALLED_FROM_CORE /* ===== */ = 5007,
  APP_URL_NOT_SET /* ========================= */ = 5008,
  /* ADD_ME                                       = 5009, */
  /* ADD_ME                                       = 50010, */

  UNSUPPORTED_FILE_TYPE /* =================== */ = 50011,
  FILE_TOO_LARGE /* ========================== */ = 50012,
  FILE_TOO_MANY /* =========================== */ = 50013,
  FILE_UPLOAD_ERROR /* ======================= */ = 50014,
  /* ADD_ME                                       = 50015, */
  /* ADD_ME                                       = 50016, */
  /* ADD_ME                                       = 50017, */
  /* ADD_ME                                       = 50018, */
  /* ADD_ME                                       = 50019, */
  /* ADD_ME                                       = 50020, */

  EMAIL_PROVIDER_DOES_NOT_EXIST /* =========== */ = 50021,
  STORAGE_PROVIDER_DOES_NOT_EXIST /* ========= */ = 50022,
  EXTENSION_MUST_BE_STARTED /* =============== */ = 50023,
  EXTENSION_CONFIG_INVALID /* ================ */ = 50024,
  EXTENSION_ALREADY_EXISTS /* ================ */ = 50025,
  EXTENSION_DOES_NOT_EXISTS /* =============== */ = 50026,
  EXTENSION_IS_UNIQUE /* ===================== */ = 50027,
  AUTH_PROVIDER_DOES_NOT_EXIST /* ============ */ = 50028
  /* ADD_ME                                       = 50029 */
  /* ADD_ME                                       = 50030 */
}
