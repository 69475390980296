export class ExtensionRendererService {
  private readonly _repository: Map<string, ExtensionUI>

  constructor() {
    this._repository = new Map()
  }

  public hasRenderer(type: string): boolean {
    return this._repository.has(type)
  }

  public getRenderer(type: string): ExtensionUI {
    return this._repository.get(type)
  }

  public registerRenderer(extension: ExtensionUI): void {
    this._repository.set(extension.type, extension)
  }
}

export const extensionRendererService = new ExtensionRendererService()
