import { css } from 'styled-components'

export const reactDatepickerOverride = css`
  .react-datepicker__tab-loop {
    > .react-datepicker-popper {
      inset: unset !important;
      transform: unset !important;

      > div {
        > .react-datepicker {
          font-family: var(--font-regular) !important;
          background-color: var(--gray-700) !important;
          border-color: var(--gray-1000) !important;
          color: var(--gray-0) !important;

          * {
            color: var(--gray-0) !important;
          }

          > .react-datepicker__navigation {
            top: 4px !important;
          }

          > .react-datepicker__triangle {
            top: -8px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            margin-top: 0 !important;
            margin-left: 0 !important;

            &::before,
            &::after {
              top: unset !important;
              left: 0 !important;
              transform: translateX(-50%) !important;
            }

            &::before {
              border-width: 9px !important;
              top: -1px !important;
              border-bottom-color: var(--gray-1000) !important;
            }

            &::after {
              border-bottom-color: var(--gray-800) !important;
            }
          }

          > .react-datepicker__month-container {
            > .react-datepicker__header {
              background-color: var(--gray-800) !important;
              border-bottom-color: var(--gray-1000) !important;
            }

            > .react-datepicker__month {
              > .react-datepicker__week {
                > .react-datepicker__day:hover {
                  background-color: var(--gray-900) !important;
                }

                > .react-datepicker__day--keyboard-selected {
                  background-color: var(--blue-500) !important;

                  &:hover {
                    background-color: var(--blue-800) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
