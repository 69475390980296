export class Numbers {
  private static _locale: 'pt-BR' | 'en-US' = 'en-US'
  private static _formatter: Intl.NumberFormat

  public static get locale(): 'pt-BR' | 'en-US' {
    return this._locale
  }

  public static setLocale(value: 'pt-BR' | 'en-US'): void {
    // We verify if value is equal to 'pt-BR' to define instead just pass value
    // in _locale and NumberFormat to prevent another locales, Methone just support
    // pt-BR and en-US.
    if (value === 'pt-BR') {
      this._locale = 'pt-BR'
      this._formatter = new Intl.NumberFormat('pt-BR')
    } else {
      this._locale = 'en-US'
      this._formatter = new Intl.NumberFormat('en-US')
    }
  }

  private static _parseNumber(s: number | string): number {
    if (typeof s === 'number') {
      return s
    } else if (this._locale === 'pt-BR') {
      return Number(s.replace(/\./g, '').replace(/,/g, '.'))
    } else {
      return Number(s.replace(/,/g, ''))
    }
  }

  public static parseInt(s: number | string): number {
    const num = this._parseNumber(s)

    return Math.floor(num)
  }

  public static parseFloat(s: number | string): number {
    const num = this._parseNumber(s)

    return num
  }

  public static formatString(s: number | string): string {
    const num = this._parseNumber(s)

    return this._formatter.format(num)
  }
}
