import { Card } from 'react-bootstrap'

import styled from 'styled-components'

import { SIDEBAR } from 'methone/utils/constants'

export const CardHeaderStyled = styled(Card.Header)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .card-title {
    margin: 0;
  }

  > button,
  > .card-editing-actions {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const FormHeaderActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
`

export const Form = styled.form`
  display: grid;
  grid-template-areas: 'AI AI' 'AU AU' 'EP SP';
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-2);

  > * {
    &:nth-child(1) {
      grid-area: AI;
      display: grid;
      grid-template-areas: 'F L';
      grid-template-columns: 1fr 1fr;
      gap: var(--spacing-2);

      > * {
        &:nth-child(1) {
          grid-area: F;

          > img {
            height: ${SIDEBAR.ITEM_ICON_SIZE}px;
            width: ${SIDEBAR.ITEM_ICON_SIZE}px;
          }
        }

        &:nth-child(2) {
          grid-area: L;

          > img {
            height: ${SIDEBAR.ITEM_ICON_SIZE}px;
            width: calc(${SIDEBAR.EXPANDED_WIDTH}px - (var(--spacing-2) * 3) - ${SIDEBAR.ITEM_ICON_SIZE}px);
          }
        }
      }
    }

    &:nth-child(2) {
      grid-area: AU;
    }

    &:nth-child(3) {
      grid-area: EP;
    }

    &:nth-child(4) {
      grid-area: SP;
    }
  }
`
