import { UserResponseDTO } from './UserResponseDTO'

export class UserWithTokensDTO {
  user: UserResponseDTO
  accessToken: string
  refreshToken: string

  constructor(user: UserResponseDTO, accessToken: string, refreshToken: string) {
    this.user = user
    this.accessToken = accessToken
    this.refreshToken = refreshToken
  }
}
