import { globalNotification } from 'methone/services/globalNotification'
import { i18n } from 'methone/services/i18n'
import { logger } from 'methone/services/logger'
import { MethoneErrors } from 'methone/shared/errors'

const methoneErrorsMessages = {
  [MethoneErrors.BAD_REQUEST]: i18n('An error occurred while processing your request'),

  [MethoneErrors.DUPLICATION_CLIENT]: i18n('Client already exists'),
  [MethoneErrors.DUPLICATION_PERMISSION]: i18n('Permission already exists'),
  [MethoneErrors.DUPLICATION_TOKEN]: i18n('Token already exists'),
  [MethoneErrors.DUPLICATION_USER]: i18n('User already exists'),

  [MethoneErrors.INVALID_AUTH_TOKEN]: i18n('Invalid auth token'),
  [MethoneErrors.REFRESH_TOKEN_NOT_FOUND]: i18n('Refresh token not found'),
  [MethoneErrors.INVALID_TOKEN_TYPE]: i18n('Invalid token type'),
  [MethoneErrors.MISSING_AUTH_TOKEN]: i18n('Missing auth token'),
  [MethoneErrors.MALFORMED_AUTH_TOKEN]: i18n('Malformed auth token'),
  [MethoneErrors.INVALID_RESET_PASSWORD_TOKEN]: i18n('Invalid reset password token'),

  [MethoneErrors.VALIDATION_ERROR]: i18n('Validation error'),
  [MethoneErrors.CANT_DELETE_REQUIRED_EXTENSION]: i18n("You can't delete a required extension"),

  /* <============================================================================================================> */

  [MethoneErrors.UNAUTHORIZED]: i18n('Unauthorized'),

  [MethoneErrors.UNAUTHORIZED_ROUTE]: i18n('Unauthorized route'),
  [MethoneErrors.ONLY_ONE_SUPER_ADMIN]: i18n('Only one super admin is allowed'),
  [MethoneErrors.CANT_MODIFY_OTHERS_PERMISSIONS]: i18n("You can't modify others permissions"),
  [MethoneErrors.CANT_MODIFY_SELF_PERMISSIONS]: i18n("You can't modify your own permissions"),
  [MethoneErrors.CANT_DISABLE_SELF]: i18n("You can't disable yourself"),
  [MethoneErrors.CANT_CREATE_OTHER_ADMINS]: i18n("You can't create other admins"),
  [MethoneErrors.CANT_MODIFY_USER]: i18n("You can't modify this user"),
  [MethoneErrors.CANT_DELETE_SELF]: i18n("You can't delete yourself"),
  [MethoneErrors.CANT_DELETE_USER]: i18n("You can't delete this user"),

  [MethoneErrors.INACTIVE_ACCOUNT]: i18n('Inactive account'),
  [MethoneErrors.INVALID_CREDENTIALS]: i18n('Invalid credentials'),
  [MethoneErrors.REVOKED_REFRESH_TOKEN]: i18n('Revoked refresh token'),
  [MethoneErrors.REVOKED_ACCESS_TOKEN]: i18n('Revoked access token'),
  [MethoneErrors.USER_DISABLED]: i18n('User disabled'),
  [MethoneErrors.INVALID_REFRESH_TOKEN]: i18n('Invalid refresh token'),

  /* <============================================================================================================> */

  [MethoneErrors.NOT_FOUND]: i18n('Not found'),

  [MethoneErrors.CLIENT_ADDRESS_NOT_FOUND]: i18n('Client address not found'),
  [MethoneErrors.CLIENT_NOT_FOUND]: i18n('Client not found'),
  [MethoneErrors.USER_NOT_FOUND]: i18n('User not found'),
  [MethoneErrors.PERMISSION_NOT_FOUND]: i18n('Permission not found'),
  [MethoneErrors.TOKEN_NOT_FOUND]: i18n('Token not found'),
  [MethoneErrors.CONFIG_NOT_FOUND]: i18n('Config not found'),

  [MethoneErrors.FILE_NOT_FOUND]: i18n('File not found'),

  [MethoneErrors.ROUTE_NOT_FOUND]: i18n('Route not found'),

  /* <============================================================================================================> */

  [MethoneErrors.METHOD_NOT_ALLOWED]: i18n('Method not allowed'),

  /* ============================================================================================================== */

  [MethoneErrors.INTERNAL_SERVER_ERROR]: i18n('Internal server error'),

  [MethoneErrors.PERSISTANCE_ERROR]: i18n('Persistance error'),
  [MethoneErrors.MISSING_REPOSITORY_MODEL]: i18n('Missing repository model'),
  [MethoneErrors.INVALID_WHERE_VALUE_TYPE]: i18n('Invalid where value type'),
  [MethoneErrors.UNSUPPORTED_WHERE_VALUE_TYPE]: i18n('Unsupported where value type'),
  [MethoneErrors.UNSUPPORTED_WHERE_OPERATOR]: i18n('Unsupported where operator'),
  [MethoneErrors.APP_URL_NOT_SET]: i18n('App url not set'),

  [MethoneErrors.UNSUPPORTED_FILE_TYPE]: i18n('Unsupported file type'),
  [MethoneErrors.FILE_TOO_LARGE]: i18n('File too large'),
  [MethoneErrors.FILE_TOO_MANY]: i18n('File too many'),
  [MethoneErrors.FILE_UPLOAD_ERROR]: i18n('File upload error'),

  [MethoneErrors.EMAIL_PROVIDER_DOES_NOT_EXIST]: i18n('Email provider not found'),
  [MethoneErrors.STORAGE_PROVIDER_DOES_NOT_EXIST]: i18n('Storage provider not found'),
  [MethoneErrors.EXTENSION_MUST_BE_STARTED]: i18n('Extension must be started'),
  [MethoneErrors.EXTENSION_CONFIG_INVALID]: i18n('Extension config is invalid'),
  [MethoneErrors.EXTENSION_ALREADY_EXISTS]: i18n('Extension already exists'),
  [MethoneErrors.EXTENSION_DOES_NOT_EXISTS]: i18n('Extension does not exists'),
  [MethoneErrors.EXTENSION_IS_UNIQUE]: i18n('Extension is unique')
}

export function getApiErrorCode(error: any): MethoneErrors {
  return error?.response?.data?.errorCode ?? null
}

export function handleHTTPRequestError(err: any, defaultMessage = i18n('An unexpected error has occurred.')): void {
  if (err?.message === 'Network Error') {
    return globalNotification.error(i18n('Check your internet connection'))
  } else if (err.response && err.response.data && err.response.data) {
    const { message, errorCode } = err.response.data as MethoneErrorResponse

    const finalMessage: string = methoneErrorsMessages[errorCode] ?? message

    if (finalMessage != null && finalMessage.trim()) {
      return globalNotification.error(finalMessage)
    }
  }

  logger.error(err)
  globalNotification.error(defaultMessage)
}
