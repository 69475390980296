import React from 'react'
import { Badge, Card, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { getUserInformation, updateUser } from 'methone/services/rest/users'
import { UserResponseDTO } from 'methone/shared/dto/UserResponseDTO'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { FormCard, ProfileCard, ProfileContainer } from './styled'

type FormData = Pick<UserResponseDTO, 'name' | 'username' | 'email'>

export const ProfilePage: React.FC = () => {
  const { control, handleSubmit, reset } = useForm<FormData>({ mode: 'all' })

  const [isLoading, setIsLoading] = React.useState(true)
  const [status, setStatus] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')
  const [user, setUser] = React.useState<UserResponseDTO>()

  async function handleGetUserData(): Promise<void> {
    try {
      setIsLoading(true)

      const data = await getUserInformation()

      setUser(data)

      reset(data)
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSubmitForm(data: FormData): Promise<void> {
    try {
      setIsLoading(true)
      setStatus('saving')

      const body = { ...user, name: data.name, username: data.username, email: data.email }
      delete body.enabled
      delete body.permissions

      await updateUser(user.id, body)

      setStatus('saved')
      await handleGetUserData()
    } catch (e) {
      handleHTTPRequestError(e)
      setStatus('error')
    } finally {
      setIsLoading(false)
    }
  }

  function normalizeName(name: string): string {
    if (name == null) {
      return ''
    }

    const [first, second] = name.trim().split(' ')

    return `${first} ${second ?? ''}`.trim()
  }

  function permissionsMap(permissions: string[]): JSX.Element[] {
    if (permissions == null) {
      return []
    }

    const permissionsCount = []
    const badges = []

    for (const [idx, permission] of permissions.entries()) {
      if (idx === 0) {
        badges.push(
          <Badge key={0} bg="secondary">
            {permission}
          </Badge>
        )
      } else {
        permissionsCount.push(permission)
      }
    }

    if (permissionsCount.length > 0) {
      const renderTooltip = (props: React.ComponentProps<typeof Tooltip>): JSX.Element => (
        <Tooltip id="permissions-tooltip" {...props}>
          <ListGroup>
            {permissionsCount.map((permission, idx) => (
              <ListGroup.Item key={idx} style={{ textAlign: 'left' }}>
                {permission}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Tooltip>
      )

      badges.push(
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
          <Badge key={1} bg="secondary">
            +{permissionsCount.length}
          </Badge>
        </OverlayTrigger>
      )
    }

    return badges
  }

  React.useEffect(() => {
    handleGetUserData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProfileContainer>
      <FixedLoading enabled={isLoading} />
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <ProfileCard className="profile-card">
          <ProfileCard.Body>
            <div className="avatar-wrapper">
              <img src={`https://ui-avatars.com/api/?name=${user?.name}`} />
            </div>
            <div className="user-name">{normalizeName(user?.name)}</div>
            <div className="user-permissions">{permissionsMap(user?.permissions)}</div>
          </ProfileCard.Body>
        </ProfileCard>

        <FormCard className="form-card">
          <Card.Body>
            <fieldset>
              <HookFormInput label={i18n('Name')} name="name" control={control} rules={{ required: true }} />
              <HookFormInput label={i18n('Username')} name="username" control={control} rules={{ required: true }} />
              <HookFormInput label={i18n('Email')} name="email" control={control} rules={{ required: true }} />
            </fieldset>
          </Card.Body>
        </FormCard>

        <Card className="form-footer">
          <Card.Body>
            <div className="actions-area">
              <SaveButton type="submit" status={status}>
                {i18n('Save')}
              </SaveButton>
            </div>

            <div className="required-warn">* {i18n('Required fields')}</div>
          </Card.Body>
        </Card>
      </form>
    </ProfileContainer>
  )
}
