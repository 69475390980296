import React from 'react'
import { Badge, Button, Card, Dropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect, HookFormTaxIdInput } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { deleteClient, exportClientsList, listClientGroups, listClients } from 'methone/services/rest/clients'
import { Permissions } from 'methone/shared/permissions'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'
import { validatePermsByStore } from 'methone/utils/validatePermsByStore'

import { ClientsContainer } from './styled'

interface FormData {
  name: string
  email: string
  taxId: string
  groups: Options<string>
}
const defaultValues: FormData = {
  name: '',
  email: '',
  taxId: '',
  groups: []
}

const defaultParams: ListClientsFilters = { page: 1, perPage: 10, direction: 'ASC', orderBy: 'name' }

export function ClientsPage(): JSX.Element {
  const isMounted = React.useRef(true)
  const [params, setParams] = useQueryParams<ListClientsFilters>(defaultParams)
  const { control, handleSubmit, reset } = useForm({ defaultValues, mode: 'all' })

  const { loading: loadingGroups, data: groups, error: groupsError } = useRequest(listClientGroups)
  const { loading: loadingClients, mutate, run, data, error: clientsError } = useRequest(listClients, { manual: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const navigate = useNavigate()

  function handleDownloadFile(fileType: 'csv' | 'excel' | 'pdf') {
    return async () => {
      let url: string = null
      try {
        setIsLoading(true)
        const file = await exportClientsList(fileType, params)
        url = URL.createObjectURL(file)

        const link = document.createElement('a')
        link.href = url
        link.download = file.name
        link.click()
      } catch (error) {
        handleHTTPRequestError(error)
      } finally {
        setIsLoading(false)

        if (url != null) {
          URL.revokeObjectURL(url)
        }
      }
    }
  }

  async function handleDeleteClient({ id, name }: { id: string; name: string }): Promise<void> {
    try {
      setIsLoading(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the client {name}?', { name }))) {
        await deleteClient(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsLoading(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      name: fields.name,
      taxId: (fields.taxId ?? '').replace(/\D/g, ''),
      email: fields.email,
      groups: fields.groups?.map((group) => group.value)
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      let taxIdFormatted = ''

      const taxId = String(params.taxId ?? '').trim()
      if (taxId.length > 0) {
        if (taxId.length === 11) {
          taxIdFormatted = taxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
        } else {
          taxIdFormatted = taxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
        }
      }

      reset({
        name: params.name ?? '',
        taxId: taxIdFormatted,
        email: params.email ?? '',
        groups: (params.groups ?? [])?.map((group) => ({ value: group, label: group }))
      })
      run(params)
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (clientsError) {
      handleHTTPRequestError(clientsError)
    }

    if (groupsError) {
      handleHTTPRequestError(groupsError)
    }
  }, [clientsError, groupsError])

  React.useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true
    } else {
      if (Number(params.page) < 1 || Number(params.perPage) < 1) {
        setParams({ ...params, page: Math.max(1, Number(params.page)), perPage: Math.max(10, Number(params.perPage)) })
      } else {
        updateFormData()
      }
    }
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClientsContainer>
      <Card className="filters-header" style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <HookFormInput control={control} name="name" label={i18n('Name')} />
            <HookFormInput control={control} name="email" label={i18n('Email')} />
            <HookFormTaxIdInput control={control} name="taxId" label={i18n('Tax id')} />
            <HookFormSelect control={control} name="groups" label={i18n('Groups')} isMulti options={groups ?? []} />
            <div className="actions">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary" className="export-button">
                  <i className="fas fa-download" />
                  {i18n('Export')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleDownloadFile('csv')}>
                    <i className="fas fa-file-csv" />
                    {i18n('CSV')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('excel')}>
                    <i className="fas fa-file-excel" />
                    {i18n('Excel')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('pdf')}>
                    <i className="fas fa-file-pdf" />
                    {i18n('PDF')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button type="button" onClick={() => navigate('/clients/new')}>
                <i className="fas fa-plus" />
                {i18n('New client')}
              </Button>
            </div>
          </form>
        </Card.Body>
      </Card>
      <SortedTable
        items={(data?.entries ?? []).map((client) => ({
          id: client.id,
          pureName: client.name,
          name: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              <span>{client.name}</span>
            </div>
          ),
          taxId: client.taxId
        }))}
        columns={[
          { title: i18n('Name'), columnRef: 'name', sortable: true },
          { title: i18n('Tax id'), columnRef: 'taxId', sortable: false, width: 200 }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/clients/${id}`),
            hide: !validatePermsByStore([Permissions.CLIENTS_MANAGER, Permissions.CLIENTS_ADMIN])
          },
          {
            icon: 'far fa-trash-alt',
            onClick: ({ id, pureName }) => handleDeleteClient({ id, name: pureName }),
            hide: !validatePermsByStore([Permissions.CLIENTS_ADMIN])
          }
        ]}
      />
      <div className="pagination__wrapper">
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={loadingClients || loadingGroups || isLoading} />
    </ClientsContainer>
  )
}
