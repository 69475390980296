import { normalizePath } from 'methone/shared/normalizePath'

import { routerService } from './routerService'

type MenuItemWithElement = (MenuNormalItem & { element?: React.FC }) | Omit<MenuDropdownItem, 'items'>
type MenuDropdownSubItemWithElement = MenuDropdownSubItem & { element?: React.FC }

class MenuService {
  private readonly repository: Map<string, MenuItem>

  constructor() {
    this.repository = new Map()
  }

  public getMenuItems(): MenuItem[] {
    return Array.from(this.repository.values())
  }

  public addItem(menuItem: MenuItemWithElement): void {
    const key = 'path' in menuItem ? menuItem.path : menuItem.basePath

    if (this.repository.has(key)) {
      throw new Error(`Menu item with key ${key} already exists`)
    }

    if (!('basePath' in menuItem)) {
      if (menuItem.element) {
        routerService.addRoute({
          path: key,
          title: menuItem.title,
          element: menuItem.element,
          permissions: menuItem.permissions ?? []
        })
      }
    } else {
      ;(menuItem as MenuDropdownItem).items = []
    }

    if ('element' in menuItem) {
      delete menuItem.element
    }

    this.repository.set(key, menuItem as MenuItem)
  }

  public addSubItem(basePath: string, subItem: MenuDropdownSubItemWithElement): void {
    const menuItem = this.repository.get(basePath)

    if (!menuItem) {
      throw new Error(`Menu item with key ${basePath} does not exists`)
    }

    if ('path' in menuItem) {
      throw new Error(`Menu item with key ${basePath} is not a dropdown`)
    }

    if (menuItem.items.some((item) => item.path === subItem.path)) {
      throw new Error(`Menu item with key ${subItem.path} already exists`)
    }

    if (subItem.element && 'path' in subItem) {
      routerService.addRoute({
        path: normalizePath(`${menuItem.basePath}/${subItem.path}`),
        title: subItem.title,
        element: subItem.element,
        permissions: Array.from(new Set([...(menuItem.permissions ?? []), ...(subItem.permissions ?? [])]))
      })
    }

    delete subItem.element
    menuItem.items.push(subItem)
  }

  public getRoute(path: string): MenuItem {
    return this.repository.get(path)
  }
}

export const menuService = new MenuService()
