import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { useRequest } from 'ahooks'

import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect, FileData, HookFormFileItem } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { getEmailProviders, getStorageProviders } from 'methone/services/rest/extensions'
import { CONFIG_KEYS, EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { CardHeaderStyled, Form, FormHeaderActions } from './styled'

const APP_URL = CONFIG_KEYS.APP_URL.replace('core.', '')
const APP_FAVICON = CONFIG_KEYS.APP_FAVICON.replace('core.', '')
const APP_LOGO = CONFIG_KEYS.APP_LOGO.replace('core.', '')
const DEFAULT_EMAIL_PROVIDER = CONFIG_KEYS.DEFAULT_EMAIL_PROVIDER.replace('core.', '')
const DEFAULT_STORAGE_PROVIDER = CONFIG_KEYS.DEFAULT_STORAGE_PROVIDER.replace('core.', '')

const defaultValues: Record<string, string | FileData[] | Option> = {
  [APP_URL]: '',
  [APP_FAVICON]: null,
  [APP_LOGO]: null,
  [DEFAULT_EMAIL_PROVIDER]: null,
  [DEFAULT_STORAGE_PROVIDER]: null
}

function toOption(value: ExtensionResponseDTO): Option<string> {
  if (!value) {
    return null
  }

  if (value.status !== EXTENSION_STATUS.RUNNING) {
    return { value: value.id, label: `${value.name} (${i18n('Not running')})` }
  }

  return { value: value.id, label: value.name }
}

async function fromOption(value: Option | FileData[] | string): Promise<string> {
  if (typeof value === 'object' && 'value' in value) {
    return value.value
  }

  return value as string
}

export const ApplicationGeneralConfigCard: React.FC<ConfigRendererProps> = ({ configs, onSave }) => {
  const [savingState, setSavingState] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')
  const [isEditing, setIsEditing] = React.useState(false)
  const { control, formState, handleSubmit, reset } = useForm({ mode: 'all', defaultValues })

  const { loading: epLoading, data: emailProviders, error: epError } = useRequest(getEmailProviders)
  const { loading: spLoading, data: storageProviders, error: spError } = useRequest(getStorageProviders)

  async function onSubmit(data: Record<string, string | FileData[] | Option>): Promise<void> {
    setSavingState('saving')

    const mappedData = {
      [APP_URL]: data[APP_URL],
      [APP_FAVICON]: data[APP_FAVICON]?.[0]?.preview ?? null,
      [APP_LOGO]: data[APP_LOGO]?.[0]?.preview ?? null,
      [DEFAULT_EMAIL_PROVIDER]: await fromOption(data[DEFAULT_EMAIL_PROVIDER]),
      [DEFAULT_STORAGE_PROVIDER]: await fromOption(data[DEFAULT_STORAGE_PROVIDER])
    }

    const result = await onSave(mappedData)
    setSavingState(result)
  }

  React.useEffect(() => {
    if (configs && emailProviders && storageProviders) {
      const appFavIcon = [{ name: i18n('Favicon'), preview: configs[APP_FAVICON], size: '---' }]
      const appLogo = [{ name: i18n('Logo'), preview: configs[APP_LOGO], size: '---' }]
      const emailProvider = emailProviders.find((ep) => ep.id === configs[DEFAULT_EMAIL_PROVIDER])
      const storageProvider = storageProviders.find((sp) => sp.id === configs[DEFAULT_STORAGE_PROVIDER])

      reset({
        ...configs,
        [APP_FAVICON]: configs[APP_FAVICON] ? appFavIcon : null,
        [APP_LOGO]: configs[APP_LOGO] ? appLogo : null,
        [DEFAULT_EMAIL_PROVIDER]: toOption(emailProvider),
        [DEFAULT_STORAGE_PROVIDER]: toOption(storageProvider)
      })
    }
  }, [configs, emailProviders, storageProviders]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (epError) {
      handleHTTPRequestError(epError)
    }

    if (spError) {
      handleHTTPRequestError(spError)
    }
  }, [epError, spError])

  return (
    <Card>
      <CardHeaderStyled>
        <Card.Title>{i18n('Application General Configs')}</Card.Title>
        {isEditing ? (
          <FormHeaderActions className="card-editing-actions">
            <SaveButton
              type="submit"
              form="default-providers-from"
              disabled={!formState.isValid}
              status={savingState}
            />
            <Button variant="outline-secondary" onClick={() => setIsEditing(false)}>
              {i18n('Cancel')}
            </Button>
          </FormHeaderActions>
        ) : (
          <Button variant="outline-primary" onClick={() => setIsEditing(true)}>
            {i18n('Edit')}
          </Button>
        )}
      </CardHeaderStyled>
      <Card.Body>
        <Form id="default-providers-from" onSubmit={handleSubmit(onSubmit)}>
          <div className="application-icons">
            <div className="application-favicon">
              <HookFormFileItem
                label={i18n('Custom application favicon')}
                name={APP_FAVICON}
                control={control}
                fileItemProps={{ maxFiles: 1, accept: { 'image/*': ['.png', '.jpg', 'jpeg'] }, disabled: !isEditing }}
              />
            </div>
            <div className="application-logo">
              <HookFormFileItem
                label={i18n('Custom application logo')}
                name={APP_LOGO}
                control={control}
                fileItemProps={{ maxFiles: 1, accept: { 'image/*': ['.png', '.jpg', 'jpeg'] }, disabled: !isEditing }}
              />
            </div>
          </div>
          <HookFormInput
            label={i18n('Application URL')}
            name={APP_URL}
            control={control}
            rules={{ required: true }}
            inputProps={{ disabled: !isEditing }}
          />
          <HookFormSelect
            label={i18n('Default email provider')}
            name={DEFAULT_EMAIL_PROVIDER}
            control={control}
            rules={{ required: true }}
            options={(emailProviders ?? []).map(toOption)}
            selectProps={{ disabled: !isEditing }}
          />
          <HookFormSelect
            label={i18n('Default storage provider')}
            name={DEFAULT_STORAGE_PROVIDER}
            control={control}
            rules={{ required: true }}
            options={(storageProviders ?? []).map(toOption)}
            selectProps={{ disabled: !isEditing }}
          />
        </Form>
        <FixedLoading enabled={epLoading || spLoading} />
      </Card.Body>
    </Card>
  )
}
