export function formatTaxId(document: string): string {
  if (!document) {
    return ''
  }

  let doc = document.replace(/\D/g, '')

  if (document.length === 14) {
    doc = document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else if (document.length === 11) {
    doc = document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  return doc
}

export function formatMoney(value?: string | number): string {
  if (!value) {
    return ''
  }

  const newValue = parseFloat(value.toString()).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

  return newValue.replace('R$', '').trim()
}

export function formatPhone(phone?: string): string {
  if (!phone) {
    return ''
  }

  const phoneFormat = phone.replace(/\D/g, '')

  if (phoneFormat.length === 10) {
    return phoneFormat.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  }

  if (phoneFormat.length === 11) {
    return phoneFormat.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  }

  return phoneFormat
}
