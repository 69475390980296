import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { SortedTable } from 'methone/components/ui/SortedTable'
import { useQueryParams, useSelector } from 'methone/hooks'
import { dateService } from 'methone/services/dateService'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { listPermissions } from 'methone/services/rest/permissions'
import { deleteUser, listUsers } from 'methone/services/rest/users'
import { UserResumeResponseDTO } from 'methone/shared/dto/UserResumeResponseDTO'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { Form, UsersListContainer } from './styled'

interface FormData extends Omit<ListUsersFilters, 'permissions'> {
  permissions: Options
}

const defaultValues: FormData = {
  page: 1,
  perPage: 10,
  orderBy: 'name',
  direction: 'ASC',
  name: '',
  username: '',
  email: '',
  permissions: []
}

export const UsersPage: React.FC = () => {
  const [params, setParams] = useQueryParams<ListUsersFilters>({ page: 1, perPage: 10 })
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })

  const { loading: permissionsLoading, data: permissions, error: permissionsError } = useRequest(listPermissions)
  const { loading, mutate, run, data, error } = useRequest(listUsers, { manual: true })
  const [isDeleting, setIsDeleting] = React.useState(false)
  const navigate = useNavigate()

  const user = useSelector((state) => state.authentication.user)

  async function handleDeleteUser({ id, name }: Pick<UserResumeResponseDTO, 'id' | 'name'>): Promise<void> {
    try {
      setIsDeleting(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the user {name}?', { name }))) {
        await deleteUser(id)
        mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setIsDeleting(false)
    }
  }

  function handleEditUser(id: string): void {
    if (id === user.id) {
      navigate(`/profile`)
    } else {
      navigate(`/admin/users/${id}`)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      name: fields.name,
      username: fields.username,
      email: fields.email,
      permissions: fields.permissions.map((p) => p.value)
    })
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }

    if (permissionsError) {
      handleHTTPRequestError(permissionsError)
    }
  }, [error, permissionsError])

  React.useEffect(() => {
    run(params)
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    reset({
      ...params,
      permissions: (params.permissions ?? []).map((p) => ({
        value: p,
        label: permissions?.find((perm) => perm.name === p)?.langs[i18n.getLang()] ?? p
      }))
    })
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UsersListContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <HookFormInput control={control} label={i18n('Name')} name="name" />
            <HookFormInput control={control} label={i18n('Username')} name="username" />
            <HookFormInput control={control} label={i18n('Email')} name="email" />
            <HookFormSelect
              control={control}
              label={i18n('Permissions')}
              name="permissions"
              isMulti
              options={(permissions ?? []).map((p) => ({
                value: p.name,
                label: p.langs[i18n.getLang()] ?? p.name
              }))}
            />
            <div className="actions">
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button type="button" variant="primary" onClick={() => navigate('/admin/users/new')}>
                <i className="fas fa-plus" />
                {i18n('New user')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <SortedTable
        items={(data?.entries ?? []).map((item) => ({
          id: item.id,
          name: item.name,
          username: item.username,
          email: item.email,
          enabled: (
            <Badge bg={item.enabled ? 'success' : 'danger'}>{i18n(item.enabled ? 'Enabled' : 'Disabled')}</Badge>
          ),
          createdAt: dateService.formatDateTime(item.createdAt),
          updatedAt: dateService.formatDateTime(item.updatedAt)
        }))}
        columns={[
          { columnRef: 'name', title: i18n('Name') },
          { columnRef: 'username', title: i18n('Username'), width: 150 },
          { columnRef: 'email', title: i18n('Email') },
          { columnRef: 'enabled', title: i18n('Status'), width: 75 },
          { columnRef: 'createdAt', title: i18n('Created at'), width: 190 },
          { columnRef: 'updatedAt', title: i18n('Updated at'), width: 190 }
        ]}
        actions={[
          { icon: 'far fa-edit', onClick: ({ id }) => handleEditUser(id) },
          { icon: 'far fa-trash-alt', onClick: ({ id, name }) => handleDeleteUser({ id, name }) }
        ]}
      />
      <FixedLoading enabled={loading || permissionsLoading || isDeleting} />
    </UsersListContainer>
  )
}
