import React from 'react'
import { Card } from 'react-bootstrap'

import { HomeContainer } from './styled'

export function HomePage(): JSX.Element {
  return (
    <HomeContainer>
      <Card>
        <Card.Body style={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-2)' }} />
      </Card>
    </HomeContainer>
  )
}
