import React from 'react'
import { FormControl } from 'react-bootstrap'

import { InputNumberFormat, NumberFormatProps } from '@react-input/number-format'

import { storageService } from 'methone/services/storageService'

export interface NumberFormatInputProps
  extends Omit<NumberFormatProps, 'component' | 'locales'>,
    Omit<HTMLInputProps, 'type' | 'ref'> {
  type: 'number'
}

// eslint-disable-next-line react/display-name
export const NumberFormatInput = React.forwardRef<HTMLInputElement, NumberFormatInputProps>(function (props, ref) {
  return <InputNumberFormat {...props} component={FormControl} locales={storageService.getItem('language')} ref={ref} />
})
