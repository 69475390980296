import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useInterval } from 'ahooks'

import { MethoneErrors } from 'methone/shared/errors'

import { Navbar } from './components/Navbar'
import { FixedLoading } from './components/ui/FixedLoading'
import { useDispatch, useSelector, useDimensions } from './hooks'
import { ForgotPasswordPage } from './pages/ForgotPassword'
import { LoginPage } from './pages/Login'
import { NotFoundErrorPage } from './pages/NotFound'
import { ResetPasswordPage } from './pages/ResetPassword'
import { i18n } from './services/i18n'
import { logger } from './services/logger'
import { getUserAccessToken } from './services/rest/users'
import { routerService } from './services/routerService'
import { storageService } from './services/storageService'
import { api } from './utils/api'
import { AUTH_ACTIONS, NO_SIDEBAR_ROUTES, SIDEBAR } from './utils/constants'
import { getApiErrorCode, handleHTTPRequestError } from './utils/handleHTTPRequestError'
import { securePage, page } from './utils/page'

export function App(): JSX.Element {
  const [showNavbar, setShowNavbar] = React.useState(false)
  const [validating, setValidating] = React.useState(true)
  const [validState, setValidState] = React.useState<true | Error>()

  const location = useLocation()
  const navigate = useNavigate()
  const dimensions = useDimensions()

  const isAuthenticated = useSelector((store) => store.authentication.isAuthenticated)
  const sidebarExpanded = useSelector((store) => store.ui.sidebarExpanded)
  const dispatch = useDispatch()

  const contentDimensions = React.useMemo(() => {
    if (showNavbar) {
      return {
        width: dimensions.width - (sidebarExpanded ? SIDEBAR.EXPANDED_WIDTH : SIDEBAR.COLLAPSED_WIDTH),
        height: dimensions.height
      }
    }

    return {
      width: dimensions.width,
      height: dimensions.height
    }
  }, [dimensions.height, dimensions.width, showNavbar, sidebarExpanded])

  /* <==========================================[ VALIDATE REFRESH TOKEN ]==========================================> */
  async function refreshToken(): Promise<void> {
    if (NO_SIDEBAR_ROUTES.includes(location.pathname.replace('/', ''))) {
      return
    }

    try {
      logger.debug('Refreshing token...')
      const token = storageService.getItem('refreshToken')

      if (token == null) {
        return navigate('/login')
      }

      const { user, accessToken } = await getUserAccessToken({ token })
      dispatch({ type: AUTH_ACTIONS.SET_AUTHENTICATED, payload: { newAccessToken: accessToken, newUser: user } })
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      setValidState(true)
      logger.debug('Token refreshed.')
    } catch (err: any) {
      const code = getApiErrorCode(err)
      if (code != null) {
        switch (code) {
          case MethoneErrors.REFRESH_TOKEN_NOT_FOUND:
          case MethoneErrors.REVOKED_REFRESH_TOKEN:
          case MethoneErrors.INACTIVE_ACCOUNT:
          case MethoneErrors.INVALID_REFRESH_TOKEN:
            storageService.removeItem('refreshToken')
            navigate('/login')

            return
        }
      }

      setValidState(err)
      handleHTTPRequestError(err)
    } finally {
      setValidating(false)
    }
  }

  useInterval(refreshToken, 1000 * 60 * 10, { immediate: true })
  /* <========================================[ END VALIDATE REFRESH TOKEN ]========================================> */

  /* <===============================================[ SIDEBAR SHOW ]===============================================> */
  React.useEffect(() => {
    setShowNavbar(!NO_SIDEBAR_ROUTES.includes(location.pathname.replace('/', '')))

    if (NO_SIDEBAR_ROUTES.includes(location.pathname.replace('/', ''))) {
      setValidating(false)
    }
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps
  /* <=============================================[ END SIDEBAR SHOW ]=============================================> */

  if (validState instanceof Error) {
    throw validState
  }

  if (validating || (!isAuthenticated && showNavbar)) {
    return <FixedLoading />
  }

  return (
    <>
      {showNavbar && <Navbar />}

      <div className="methone-content" style={contentDimensions}>
        <Scrollbars>
          <Routes>
            <Route path="/login" element={page(LoginPage, { dimensions: contentDimensions, title: 'Login' })} />
            <Route
              path="/forgot-password"
              element={page(ForgotPasswordPage, { dimensions: contentDimensions, title: i18n('Forgot password') })}
            />
            <Route
              path="/reset-password"
              element={page(ResetPasswordPage, { dimensions: contentDimensions, title: i18n('Reset password') })}
            />

            {routerService.getRoutes().map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={securePage(route.element, {
                  dimensions: contentDimensions,
                  title: route.title,
                  permissions: route.permissions
                })}
              />
            ))}

            <Route path="*" element={page(NotFoundErrorPage, { dimensions: contentDimensions, title: '404' })} />
          </Routes>
        </Scrollbars>
      </div>
    </>
  )
}
