import styled from 'styled-components'

export const SMTPEmailProviderStyledContainer = styled.div`
  display: grid;
  gap: var(--spacing-2);

  > div {
    display: grid;
    gap: var(--spacing-2);
  }

  > .g1 {
    grid-template-areas: 'NM EM';
    grid-template-columns: 1fr 1fr;

    .form-input-container[class*='form-input-container--jsonConfig.emailName'] {
      grid-area: NM;
    }

    .form-input-container[class*='form-input-container--jsonConfig.emailAddress'] {
      grid-area: EM;
    }
  }

  > .g2 {
    grid-template-areas: 'HT PT SC';
    grid-template-columns: 1fr 100px 80px;

    .form-input-container[class*='form-input-container--jsonConfig.host'] {
      grid-area: HT;
    }

    .form-input-container[class*='form-input-container--jsonConfig.port'] {
      grid-area: PT;
    }

    .form-input-container[class*='form-input-container--jsonConfig.secure'] {
      grid-area: SC;
    }
  }

  > .g3 {
    grid-template-areas: 'US PW';
    grid-template-columns: 1fr 1fr;

    .form-input-container[class*='form-input-container--jsonConfig.user'] {
      grid-area: US;
    }

    .form-input-container[class*='form-input-container--jsonConfig.password'] {
      grid-area: PW;
    }
  }
`
