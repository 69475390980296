export class ConfigRendererService {
  private readonly _repository: Map<string, ConfigRendererItem>

  constructor() {
    this._repository = new Map()
  }

  public getRenderers(): ConfigRendererItem[] {
    return Array.from(this._repository.values())
  }

  public registerRenderer(renderer: ConfigRendererItem): void {
    if (this._repository.has(renderer.scope)) {
      throw new Error(`ConfigRenderer with scope "${renderer.scope}" already exists`)
    }

    this._repository.set(renderer.scope, renderer)
  }
}

export const configRendererService = new ConfigRendererService()
