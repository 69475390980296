export function getOrCreatePortalRoot(id: string): HTMLElement {
  let portalRoot = document.getElementById(id)

  if (!portalRoot) {
    portalRoot = document.createElement('div')
    portalRoot.id = id

    document.body.appendChild(portalRoot)
  }

  return portalRoot
}
