import React from 'react'

import type EventSourcePolyfill from 'eventsource'

import { dateService } from 'methone/services/dateService'
import { openLogStream } from 'methone/services/rest/utilities'

import { LogsContainer } from './styled'

class LogErrorParsing implements BackendError {
  name: string
  message: string
  stack: string
  cause?: string

  constructor(error: BackendError) {
    this.name = error.name
    this.message = error.message
    this.stack = error.stack
    this.cause = error.cause
  }
}

export const LogsPage: React.FC = () => {
  const eventSource = React.useRef<EventSourcePolyfill>()

  const [logsBuffer, setLogsBuffer] = React.useState<Set<LogMessage>>(new Set())

  React.useEffect(() => {
    if (!eventSource.current) {
      eventSource.current = openLogStream()

      eventSource.current.onmessage = ({ data }) => {
        const parsedData = JSON.parse(data) as LogMessage

        if (!Array.isArray(parsedData.data)) {
          parsedData.data = new LogErrorParsing(parsedData.data)
        }

        setLogsBuffer((prev) => new Set([...prev, parsedData]))
      }
    }
  }, [])

  React.useEffect(() => {
    return () => {
      eventSource.current?.close()
    }
  }, [])

  return (
    <LogsContainer>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th style={{ width: 50 }}>Level</th>
              <th style={{ width: 175 }}>Date</th>
              <th style={{ width: 400 }}>Trace</th>
              <th style={{ width: 100 }}>User</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {[...new Set(logsBuffer)].map((logRow, idx) => {
              if (!Array.isArray(logRow.data)) {
                return (
                  <tr key={idx}>
                    <td colSpan={5} className="error">
                      <pre>
                        {logRow.data.stack.split('\n').map((line, idx) => {
                          return (
                            <span key={idx}>
                              {line}
                              <br />
                            </span>
                          )
                        })}
                      </pre>
                    </td>
                  </tr>
                )
              } else {
                return (
                  <tr key={idx}>
                    <td>{logRow.level}</td>
                    <td>{dateService.formatDateTime(logRow.date)}</td>
                    <td>{logRow.trace}</td>
                    <td>{logRow.user}</td>
                    <td>{logRow.data.join(' ')}</td>
                  </tr>
                )
              }
            }, [])}
          </tbody>
        </table>
      </div>
    </LogsContainer>
  )
}
