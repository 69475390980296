import React from 'react'
import { FormText } from 'react-bootstrap'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import cx from 'classnames'

import { getHookFormErrorMessage } from '../errorMessages'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'
import { MaskInput, MaskInputProps } from './Mask'
import { NumberFormatInput, NumberFormatInputProps } from './NumberFormat'
import { PureInput } from './Pure'

interface BaseInputProps {
  clipboard?: boolean
  label?: React.ReactNode
  error?: string
  infoText?: string
}

interface PureInputProps extends Omit<HTMLInputProps, 'type' | 'ref'> {
  type?: 'email' | 'password' | 'tel' | 'text' | 'url'
}

export type InputProps = (PureInputProps | MaskInputProps | NumberFormatInputProps) & BaseInputProps

function maskedInputGuard(type: string, props: any): props is MaskInputProps {
  return 'mask' in props
}

function numberInputGuard(type: string, props: any): props is NumberFormatInputProps {
  return type === 'number'
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function InputComponent(props, ref) {
  const { clipboard, error, label, type = 'text', infoText, ...rest } = props
  const id = rest?.name ?? Math.random().toString(36).substring(7)
  const fullId = rest?.id ?? `input-field--${id}`
  const cnBase = 'form-input'
  const inputClassName = `${cnBase}-field`

  async function copyToClipboard(value: string): Promise<void> {
    await navigator.clipboard.writeText(value)
  }

  return (
    <FormGroup className={cx(`${cnBase}-container ${cnBase}-container--${id}`, { hasError: !!error })}>
      {label && (
        <Label className={cx(`${cnBase}-label`, { required: rest?.required })} htmlFor={fullId}>
          {label}
        </Label>
      )}

      {maskedInputGuard(type, rest) ? (
        <MaskInput {...rest} ref={ref} id={fullId} className={inputClassName} />
      ) : numberInputGuard(type, rest) ? (
        <NumberFormatInput {...rest} ref={ref} id={fullId} className={inputClassName} />
      ) : (
        <PureInput {...rest} ref={ref} id={fullId} type={type} className={inputClassName} />
      )}

      {clipboard && (
        <button
          type="button"
          className={cx('clipboard-button', { hasLabel: label })}
          onClick={(e) => copyToClipboard((e.target as HTMLInputElement).value)}
        >
          <i className="far fa-clipboard" />
        </button>
      )}

      {(error || infoText) && <FormText>{error || infoText}</FormText>}
    </FormGroup>
  )
})

export interface HFInputProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  inputProps?: PureInputProps | NumberFormatInputProps | MaskInputProps
}

export function HookFormInput<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  inputProps
}: HFInputProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Input
          {...inputProps}
          label={label}
          ref={field.ref}
          name={field.name}
          value={field.value ?? ''}
          checked={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
