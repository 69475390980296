import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'
import Joi from 'joi'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormPassword } from 'methone/components/ui/Forms'
import { useDispatch, useQueryParams } from 'methone/hooks'
import { i18n } from 'methone/services/i18n'
import { listAllAuthProviderButtons } from 'methone/services/rest/authProviders'
import { userLogin } from 'methone/services/rest/users'
import { storageService } from 'methone/services/storageService'
import { UserLoginDTO } from 'methone/shared/dto/UserLoginDTO'
import { JOIErrorMessages } from 'methone/shared/JOIErrorMessages'
import { api } from 'methone/utils/api'
import { AUTH_ACTIONS } from 'methone/utils/constants'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { LoginContainer } from './styled'

const defaultValues: UserLoginDTO = {
  username: '',
  password: ''
}

export function LoginPage(): JSX.Element {
  const [params] = useQueryParams({ token: '' })
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const { loading: authProviderButtonsLoading, data: authProviderButtons } = useRequest(listAllAuthProviderButtons)

  const { control, handleSubmit } = useForm<UserLoginDTO>({ mode: 'all', defaultValues })
  const dispatch = useDispatch()

  async function handleLogin(data: UserLoginDTO): Promise<void> {
    try {
      setLoading(true)

      const { error } = Joi.object<UserLoginDTO>({
        username: Joi.string().required(),
        password: Joi.string().required()
      })
        .messages(JOIErrorMessages)
        .validate(data)

      if (error) {
        throw error
      }

      const response = await userLogin({ username: data.username, password: data.password })

      storageService.setItem('refreshToken', response.refreshToken)
      api.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`
      dispatch({
        type: AUTH_ACTIONS.SET_AUTHENTICATED,
        payload: { newUser: response.user, newAccessToken: response.accessToken }
      })

      navigate('/')
    } catch (err: any) {
      handleHTTPRequestError(err)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const token = params?.token ?? storageService.getItem('refreshToken')
    if (token != null && token !== '') {
      storageService.setItem('refreshToken', token)

      navigate('/')
    }
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (storageService.hasItem('refreshToken')) {
      navigate('/')
    }
  }, []) // eslint-disable-line

  return (
    <LoginContainer>
      <Card style={{ minWidth: 310.283 }}>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Card.Header>
            <Card.Img src="/images/application-logo.png" alt="methone-logo" height="70px" />
          </Card.Header>
          <Card.Body>
            <HookFormInput
              control={control}
              name="username"
              rules={{ required: true }}
              label={i18n('Username')}
              inputProps={{ autoCorrect: 'off', autoCapitalize: 'none' }}
            />
            <HookFormPassword control={control} name="password" rules={{ required: true }} label={i18n('Password')} />
          </Card.Body>
          <Card.Footer style={{ display: 'flex', gap: 'var(--spacing-2)', flexDirection: 'column' }}>
            <Button type="submit" variant="primary">
              {i18n('Submit')}
            </Button>
            <Button type="button" variant="outline-secondary" onClick={() => navigate('/forgot-password')}>
              {i18n('Forgot password?')}
            </Button>
          </Card.Footer>
        </form>
      </Card>
      {!authProviderButtonsLoading && authProviderButtons.length > 0 && (
        <div className="auth-provider-buttons-wrapper">
          <div className="auth-providers-title">{i18n('Or login with')}</div>
          {authProviderButtons.map((authProviderButton, idx) => (
            <Button key={idx} variant="secondary" onClick={() => window.open(authProviderButton.url, '_self')}>
              {authProviderButton.icon.startsWith('fa') ? (
                <i className={authProviderButton.icon} />
              ) : (
                <img src={authProviderButton.icon} />
              )}
              {authProviderButton.name}
            </Button>
          ))}
        </div>
      )}
      <FixedLoading enabled={loading} />
    </LoginContainer>
  )
}
