import { normalizePath } from 'methone/shared/normalizePath'

class RouterService {
  private readonly repository: Map<string, Route>

  constructor() {
    this.repository = new Map()
  }

  /** Get all secure routes */
  public getRoutes(): Route[] {
    return Array.from(this.repository.values())
  }

  /** Get a secure route */
  public getRoute(path: string): Route {
    path = normalizePath(path)

    return this.repository.get(path)
  }

  /** Add a new secure route */
  public addRoute(route: Route): void {
    route.path = normalizePath(route.path)

    if (this.repository.has(route.path)) {
      throw new Error(`Route with path '${route.path}' already exists`)
    }

    this.repository.set(route.path, route)
  }
}

export const routerService = new RouterService()
