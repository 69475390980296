import React from 'react'
import { FormText, FormCheck, FormCheckProps } from 'react-bootstrap'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import cx from 'classnames'

import { getHookFormErrorMessage } from '../errorMessages'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'

export interface CheckboxProps extends FormCheckProps {
  label?: string
  error?: string
  infoText?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function CheckboxComponent(props, ref) {
  const { label, error, infoText, ...rest } = props
  const id = rest?.name ?? Math.random().toString(36).substring(7)
  const fullId = rest?.id ?? `checkbox-field--${id}`
  const cnBase = 'form-checkbox'
  const checkboxClassName = `${cnBase}-field`

  return (
    <FormGroup className={cx(`${cnBase}-container ${cnBase}-container--${id}`, { hasError: !!error })}>
      {label && (
        <Label className={cx(`${cnBase}-label`, { required: rest?.required })} htmlFor={fullId}>
          {label}
        </Label>
      )}

      <FormCheck {...rest} ref={ref} id={fullId} className={checkboxClassName} />

      {(error || infoText) && <FormText>{error || infoText}</FormText>}
    </FormGroup>
  )
})

type OmitProps = 'label' | 'ref' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error' | 'type'
export interface HFCheckboxProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  type?: 'checkbox' | 'switch' | 'radio'
  checkboxProps?: Omit<CheckboxProps, OmitProps>
}

export function HookFormCheckbox<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  type,
  checkboxProps
}: HFCheckboxProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Checkbox
          {...checkboxProps}
          type={type ?? 'checkbox'}
          label={label}
          ref={field.ref}
          name={field.name}
          value={field.value}
          checked={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
