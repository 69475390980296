import { ApplicationGeneralConfigCard } from '../_configCards_/ApplicationGeneralConfigCard'
import { DiskStorageProvider } from '../_extensions_/DiskStorageProvider'
import { SMTPEmailProviderForm } from '../_extensions_/SMTPEmailProviderForm'
import { configRendererService } from '../services/configRendererService'
import { extensionRendererService } from '../services/extensionRendererService'

extensionRendererService.registerRenderer({ type: 'SMTPEmailProvider', component: SMTPEmailProviderForm })
extensionRendererService.registerRenderer({ type: 'DiskStorageProvider', component: DiskStorageProvider })

configRendererService.registerRenderer({
  scope: 'core',
  component: ApplicationGeneralConfigCard,
  order: Number.MIN_SAFE_INTEGER
})
