export const REST_API_PREFIX = 'api'
export const REST_OPEN_PREFIX = 'open'
export const REST_CONTENT_PREFIX = 'content'

export enum OPEN_REST_ENDPOINTS {
  AUTH_PROVIDERS = 'auth-providers'
}

export enum AUTH_PROVIDERS_OPEN_PATHS {
  BUTTONS = 'buttons'
}

export enum REST_ENDPOINTS {
  CONFIGS = 'configs',
  CLIENTS = 'clients',
  EXTENSIONS = 'extensions',
  USERS = 'users',
  UTILS = 'utils'
}

export enum EXTENSIONS_PATHS {
  TEST = 'test',
  BUILDERS = 'builders',
  STORAGE_PROVIDERS = 'storage-providers',
  EMAIL_PROVIDERS = 'email-providers'
}

export enum UTILS_PATHS {
  PLUGINS = 'plugins',
  LOGS = 'logs',
  METRICS = 'metrics',
  PERMISSIONS = 'permissions'
}

export enum USERS_PATHS {
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  LOGIN = 'auth',
  REFRESH_TOKEN = 'refresh-token',
  ME = 'me'
}

export enum CLIENTS_PATHS {
  GROUPS = 'groups',
  RESUME = 'resume',
  ADDRESSES = 'addresses',
  EXPORT = 'export'
}

export enum PLUGIN_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ERROR = 'error',
  LOADED = 'loaded',
  REGISTERED = 'registered'
}

export enum EXTENSION_STATUS {
  STARTING = 'starting',
  RUNNING = 'running',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
  ERROR = 'error'
}

export enum EXTENSION_TEST_STATUS {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum CONFIG_KEYS {
  DEFAULT_EMAIL_PROVIDER = 'core.defaultEmailProvider',
  DEFAULT_STORAGE_PROVIDER = 'core.defaultStorageProvider',
  APP_URL = 'core.appUrl',
  APP_LOGO = 'core.appLogo',
  APP_FAVICON = 'core.appFavicon'
}
