import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import './init/exposes'
import './init/extensions'
import './init/routes'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { ThemeProvider } from 'styled-components'

import { Numbers } from 'methone/shared/Numbers'

import { App } from './App'
import { ErrorBoundary } from './components/ErrorBoundary'
import { dateService } from './services/dateService'
import { logger } from './services/logger'
import { storageService } from './services/storageService'
import { store } from './store'
import { GlobalStyle } from './styles/global'
import { theme } from './styles/theme'

const ProviderWrapper: React.FC = () => {
  const isMounted = React.useRef(false)

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    }
  }, [])

  React.useEffect(() => {
    if (isMounted.current) {
      let language = storageService.getItem('language')
      if (language == null || (language !== 'pt-BR' && language !== 'en-US')) {
        language = 'pt-BR'
        storageService.setItem('language', language)
        window.location.reload()
      }

      window.Methone = {
        ...(window.Methone ?? {}),
        lang: language,
        modules: {
          ...(window.Methone?.modules ?? {})
        }
      }

      Numbers.setLocale(language)
      dateService.setLocale(language.replace('-', ''))
      window.logger = logger

      if (process.env.NODE_ENV === 'development') {
        logger.setLogLevel('debug')
      } else {
        logger.setLogLevel(storageService.getItem('logLevel') ?? 'warn')
      }
    }
  }, [isMounted.current]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ErrorBoundary>
            <HashRouter>
              <App />
            </HashRouter>
          </ErrorBoundary>

          <ToastContainer
            position="top-right"
            pauseOnHover={true}
            pauseOnFocusLoss={false}
            toastStyle={{ borderRadius: 'var(--spacing-1)' }}
            theme="colored"
          />
          <GlobalStyle />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(<ProviderWrapper />)
