import { Permissions } from 'methone/shared/permissions'

import { ExtensionsPage } from '../pages/Admin/Extensions'
import { ExtensionEditorPage } from '../pages/Admin/Extensions/Editor'
import { LogsPage } from '../pages/Admin/Logs'
import { SettingsPage } from '../pages/Admin/Settings'
import { StatusPage } from '../pages/Admin/Status'
import { UserEditorPage, UsersPage } from '../pages/Admin/Users'
import { ClientsPage, ClientsEditorPage } from '../pages/Clients'
import { HomePage } from '../pages/Home'
import { ProfilePage } from '../pages/Profile'
import { i18n } from '../services/i18n'
import { menuService } from '../services/menuService'
import { routerService } from '../services/routerService'

menuService.addItem({ path: '/', title: i18n('Home'), order: Number.MIN_SAFE_INTEGER, icon: 'fas fa-home', element: HomePage }) // eslint-disable-line prettier/prettier

/* =================================================[ CLIENT ROUTE ]================================================= */
menuService.addItem({ path: '/clients', title: i18n('Clients'), order: Number.MAX_SAFE_INTEGER - 1, icon: 'fas fa-users', element: ClientsPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: '/clients/:id', title: i18n('Clients'), permissions: [Permissions.CLIENTS_MANAGER], element: ClientsEditorPage }) // eslint-disable-line prettier/prettier
/* ===============================================[ END CLIENT ROUTE ]=============================================== */

/* =============================================[ ADMINISTRATION ROUTE ]============================================= */
menuService.addItem({ basePath: '/admin', title: i18n('Administration'), order: Number.MAX_SAFE_INTEGER, icon: 'fas fa-cog', permissions: [Permissions.ADMIN] }) // eslint-disable-line prettier/prettier

/* ==========================================[ ADMINISTRATION USERS ROUTE ]========================================== */
menuService.addSubItem('/admin', { path: '/users', title: i18n('Users'), element: UsersPage })
routerService.addRoute({ path: '/admin/users/:id', title: i18n('Users'), element: UserEditorPage, permissions: [Permissions.ADMIN] }) // eslint-disable-line prettier/prettier
/* ========================================[ END ADMINISTRATION USERS ROUTE ]======================================== */

/* ========================================[ ADMINISTRATION EXTENSION ROUTE ]======================================== */
menuService.addSubItem('/admin', { path: '/extensions', title: i18n('Extensions'), element: ExtensionsPage })
routerService.addRoute({ path: '/admin/extensions/:type/:id', title: i18n('Extensions'), element: ExtensionEditorPage, permissions: [Permissions.ADMIN] }) // eslint-disable-line prettier/prettier
/* ======================================[ END ADMINISTRATION EXTENSION ROUTE ]====================================== */

menuService.addSubItem('/admin', { path: '/status', title: i18n('Status'), element: StatusPage })
menuService.addSubItem('/admin', { path: '/logs', title: i18n('Logs'), element: LogsPage })
menuService.addSubItem('/admin', { path: '/settings', title: i18n('Settings'), element: SettingsPage })
/* ===========================================[ END ADMINISTRATION ROUTE ]=========================================== */

routerService.addRoute({ path: '/profile', title: i18n('Profile'), element: ProfilePage })
