import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import type { OverlayChildren } from 'react-bootstrap/esm/Overlay'
import { useLocation, useNavigate } from 'react-router-dom'

import cx from 'classnames'

import { useSelector } from 'methone/hooks'
import { normalizePath } from 'methone/shared/normalizePath'
import { SIDEBAR_ITEM_DROPDOWN_ANIMATION_DURATION } from 'methone/utils/constants'
import { menuItemFilter } from 'methone/utils/menuItemFilter'
import { menuItemValidateActive } from 'methone/utils/menuItemValidateActive'
import { sortByOrder } from 'methone/utils/sortByOrder'

import { SidebarItemContainer, DropdownItemsWrapper, SidebarSubItemsWrapper } from './styled'

interface Props {
  focusedMenuItem: string
  menuItem: MenuItem
  setFocusedMenuItem: React.Dispatch<React.SetStateAction<string>>
}

function dropdownGuard(menuItem: MenuItem): menuItem is MenuDropdownItem {
  return 'items' in menuItem
}

export const NavbarItem: React.FC<Props> = ({ focusedMenuItem, menuItem, setFocusedMenuItem }) => {
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const [focusStatus, setFocusStatus] = React.useState<'idle' | 'focused' | 'not-focused'>('idle')

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const sidebarExpanded = useSelector((store) => store.ui.sidebarExpanded)

  function handleNavigate(path: string): void {
    navigate(path)
  }

  function handleSetFocusedMenuItem(mi: MenuItem): void {
    if (dropdownGuard(mi)) {
      setFocusedMenuItem((old) => (old === mi.basePath ? null : mi.basePath))
    } else {
      setFocusedMenuItem((old) => (old === mi.path ? null : mi.path))
    }
  }

  function handleButtonClick(): void {
    if (dropdownGuard(menuItem)) {
      if (sidebarExpanded) {
        handleSetFocusedMenuItem(menuItem)
      }
    } else {
      handleNavigate(menuItem.path)
    }
  }

  function handleIsActive(): boolean {
    if (dropdownGuard(menuItem)) {
      return menuItemValidateActive(pathname, `${menuItem.basePath}%`)
    }

    return menuItemValidateActive(pathname, menuItem.path)
  }

  /* ================================================================================================================ */

  const collapsedOverlay: OverlayChildren = (props) => (
    <SidebarSubItemsWrapper {...props} onMouseLeave={() => setFocusedMenuItem(null)}>
      <div className="sidebar-sub-item-header">
        <div className="sub-item-icon-wrapper">
          <i className={menuItem.icon} />
        </div>
        <div className="sidebar-sub-item-title">{menuItem.title}</div>
      </div>

      {dropdownGuard(menuItem) &&
        menuItem.items
          .filter(menuItemFilter)
          .sort(sortByOrder)
          .map((item) => {
            const fullURL = normalizePath(`${menuItem.basePath}/${item.path}`)

            return (
              <button
                key={fullURL}
                className={cx('sidebar-sub-item-container', { active: menuItemValidateActive(pathname, fullURL) })}
                onClick={() => handleNavigate(fullURL)}
              >
                {item.title}
              </button>
            )
          })}
    </SidebarSubItemsWrapper>
  )

  /* ================================================================================================================ */

  React.useEffect(() => {
    let isFocused = false

    if (dropdownGuard(menuItem)) {
      isFocused = focusedMenuItem === menuItem.basePath
    } else {
      isFocused = focusedMenuItem === menuItem.path
    }

    if (focusStatus !== 'idle') {
      setFocusStatus(isFocused ? 'focused' : 'not-focused')
    } else if (isFocused) {
      setFocusStatus('focused')
    }
  }, [focusStatus, focusedMenuItem, menuItem, sidebarExpanded])

  React.useEffect(() => {
    if (focusStatus === 'not-focused') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        setFocusStatus('idle')
      }, SIDEBAR_ITEM_DROPDOWN_ANIMATION_DURATION)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [focusStatus])

  return (
    <OverlayTrigger
      placement="right"
      overlay={collapsedOverlay}
      show={!sidebarExpanded && (dropdownGuard(menuItem) ? focusStatus === 'focused' : undefined)}
    >
      <SidebarItemContainer className="sidebar-item sidebar-item-dropdown">
        <button
          onMouseEnter={() => !sidebarExpanded && handleSetFocusedMenuItem(menuItem)}
          onClick={handleButtonClick}
          className={cx(focusStatus, { active: handleIsActive() })}
        >
          <div className="icon-wrapper">
            <i className={menuItem.icon} />
          </div>
          <div className="sidebar-item-title">{menuItem.title}</div>
        </button>

        {dropdownGuard(menuItem) && (
          <DropdownItemsWrapper
            style={
              {
                '--items-count': menuItem.items.filter(menuItemFilter).sort(sortByOrder).length
              } as React.CSSProperties
            }
            className={cx(`dropdown-items-wrapper ${focusStatus}`, { show: sidebarExpanded })}
          >
            {menuItem.items
              .filter(menuItemFilter)
              .sort(sortByOrder)
              .map((item) => {
                const fullURL = normalizePath(`${menuItem.basePath}/${item.path}`)

                return (
                  <button
                    key={fullURL}
                    className={cx('dropdown-item', { active: menuItemValidateActive(pathname, fullURL) })}
                    onClick={() => handleNavigate(fullURL)}
                  >
                    {item.title}
                  </button>
                )
              })}
          </DropdownItemsWrapper>
        )}
      </SidebarItemContainer>
    </OverlayTrigger>
  )
}
