import { ptBR } from './ptBR'

export const enUS: I18NProps = {
  name: 'English (United States)',
  code: 'en-US',
  values: {
    ...Object.keys(ptBR.values).reduce((acc, key) => ({ ...acc, [key]: key }), {}),
    'errors.forms.unknown': 'This field is invalid',
    'errors.forms.required': 'This field is required',
    'errors.forms.maxLength': 'This field must have less than {max} characters',
    'errors.forms.minLength': 'This field must have more than {min} characters'
  }
}
