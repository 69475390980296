const arrayKeyRegex = /^(.*)\[(\d)?\]$/

function parseParams(params: Record<string, any> | URLSearchParams): Iterable<[value: string, key: string]> {
  if (params instanceof URLSearchParams) {
    return params
  }

  return Object.entries(params)
}

export function parseQueryParams<T>(params: Record<string, any> | URLSearchParams): T {
  const returnObject = {} as T

  for (const [key, value] of parseParams(params)) {
    if (arrayKeyRegex.test(key)) {
      const [, name, index] = key.match(arrayKeyRegex)

      if (!returnObject[name]) {
        returnObject[name] = []
      }

      if (Array.isArray(value)) {
        returnObject[name] = value
      } else {
        if (index != null) {
          returnObject[name][index] = value
        } else {
          returnObject[name].push(value)
        }
      }
    } else {
      returnObject[key] = value
    }
  }

  return returnObject
}
