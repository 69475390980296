import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const Form = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    'NAM UNM'
    'EML PEM'
    'ACT ACT';
  grid-template-columns: 1fr 200px;
  gap: var(--spacing-3);

  > .form-select-container--name {
    grid-area: NAM;
  }

  > .form-select-container--username {
    grid-area: UNM;
  }

  > .form-select-container--email {
    grid-area: EML;
  }

  > .form-select-container--permissions {
    grid-area: PEM;
  }

  > .actions {
    grid-area: ACT;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-2);
    }
  }
`

export const UsersListContainer = styled(Container)`
  padding: var(--spacing-4);

  .card {
    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-2);

      > .type-to-search {
        width: 100%;
      }

      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        width: 130px;
        height: 36px;
        gap: var(--spacing-2);
      }
    }
  }
`
