import axios, { AxiosInstance } from 'axios'

import { storageService } from 'methone/services/storageService'
import { REST_API_PREFIX, REST_OPEN_PREFIX } from 'methone/shared/constants'
import { store } from 'methone/store'

export function getAPIClient(baseURL: string): AxiosInstance {
  const api = axios.create({ baseURL })

  const lang = storageService.getItem('language')
  if (lang != null) {
    api.defaults.headers.common['Methone-Language'] = lang
  }

  const accessToken = store.getState().authentication.accessToken
  if (accessToken != null) {
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

  return api
}

export const api = getAPIClient(`/${REST_API_PREFIX}`)
export const openApi = getAPIClient(`/${REST_OPEN_PREFIX}`)
