export const ptBR: I18NProps = {
  name: 'Português (Brasil)',
  code: 'pt-BR',
  values: {
    Methone: 'Methone',
    // eslint-disable-next-line prettier/prettier
    'Methone is a simple, fast and secure way to control your company.': 'Methone é uma forma simples, rápida e segura de controlar seu negócio.',
    'Copyright © 2021 Methone': 'Copyright © 2021 Methone',

    Home: 'Início',
    Clients: 'Clientes',
    Settings: 'Configurações',
    Name: 'Nome',
    Group: 'Grupo',
    Back: 'Voltar',
    Description: 'Descrição',
    Phone: 'Telefone',
    Street: 'Rua',
    Number: 'Número',
    Details: 'Detalhes',
    Neighborhood: 'Bairro',
    City: 'Cidade',
    State: 'Estado',
    Country: 'País',
    Username: 'Nome de Usuário',
    Password: 'Senha',
    Submit: 'Enviar',
    Loading: 'Carregando',
    Version: 'Versão',
    Confirm: 'Confirmar',
    Cancel: 'Cancelar',
    or: 'ou',
    Vehicles: 'Veículos',
    Types: 'Tipos',
    Insurances: 'Seguros',
    Status: 'Status',
    Enabled: 'Ativo',
    Disabled: 'Inativo',
    Email: 'E-mail',
    Permissions: 'Permissões',
    Save: 'Salvar',
    Users: 'Usuários',
    Create: 'Criar',
    Plugins: 'Plugins',
    Plugin: 'Plugin',
    messages: 'mensagens',
    Type: 'Tipo',
    Model: 'Modelo',
    cancel: 'cancelar',
    'Required fields': 'Campos obrigatórios',
    '* Required fields': '* Campos obrigatórios',
    Files: 'Arquivos',
    Client: 'Cliente',
    Error: 'Erro',
    Enable: 'Ativar',
    Disable: 'Desativar',
    Test: 'Teste',
    Valid: 'Válido',
    Testing: 'Testando',
    Extensions: 'Extensões',
    Administration: 'Administração',
    Logout: 'Sair',
    Groups: 'Grupos',
    Edit: 'Editar',
    Language: 'Idioma',
    Profile: 'Perfil',

    'Select...': 'Selecione...',
    'No options available': 'Nenhuma opção disponível',
    'Are you sure you want to remove this address?': 'Você tem certeza que deseja remover este endereço?',
    'New address': 'Novo endereço',
    'Invalid tax id': 'CPF/CNPJ inválido',
    'Tax id': 'CPF/CNPJ',
    'Forgot password?': 'Esqueceu a senha?',
    'This page can not be founded.': 'Esta pagina não pode ser encontrada.',
    'Back to home': 'Voltar à página inicial',
    'System status': 'Status do sistema',
    'Last update': 'Ultima atualização',
    'Action needed': 'Ação necessária',
    'This action can not be undone.': 'Esta ação não pode ser desfeita.',
    'Created at': 'Criado em',
    'Updated at': 'Atualizado em',
    'Type to search...': 'Digite para pesquisar...',
    'New user': 'Novo usuário',
    'Are you sure you want to delete the user {name}?': 'Você tem certeza que deseja deletar o usuário {name}?',
    'An email has been sent to you with instructions to reset your password.': 'Um e-mail foi enviado para você com instruções para redefinir sua senha.', // eslint-disable-line prettier/prettier
    'Forgot password': 'Esqueceu a senha',
    'Reset password': 'Redefinir senha',
    'Back to login': 'Voltar ao login',
    'Password again': 'Senha novamente',
    'New client': 'Novo cliente',
    'Are you sure you want to delete the client {name}?': 'Você tem certeza que deseja deletar o cliente {name}?',
    'No data': 'Sem dados',
    'Something went wrong.': 'Algo deu errado.',
    'Missing reset password token': 'Token de redefinição de senha ausente',
    'No clients founded': 'Nenhum cliente encontrado',
    'Yes, I am sure': 'Sim, tenho certeza',
    'Are you sure you want to disable this user?': 'Você tem certeza que deseja desativar este usuário?',
    'Are you sure you want to enable this user?': 'Você tem certeza que deseja ativar este usuário?',
    'Apply filters': 'Aplicar filtros',
    'item selected': 'item selecionado',
    'items selected': 'itens selecionados',
    "Create new '{name}' extension": "Criar nova extensão '{name}'",
    'Email name': 'Nome no e-mail',
    'Email address': 'Endereço do e-mail',
    Host: 'Host',
    Port: 'Porta',
    Secure: 'Seguro',
    'Are you sure you want to logout?': 'Você tem certeza que deseja sair?',
    'Application General Configs': 'Configurações gerais da aplicação',
    'Custom application favicon': 'Favicon da aplicação customizado',
    'Custom application logo': 'Logo da aplicação customizado',
    'Application URL': 'URL da aplicação',
    'Default email provider': 'Provedor de e-mail padrão',
    'Default storage provider': 'Provedor de armazenamento padrão',
    'General information': 'Informações gerais',
    'Attached storages': 'Armazenamentos anexados',
    Memory: 'Memória',

    /* <=============================================[ METHONE ERRORS ]=============================================> */

    'An error occurred while processing your request': 'Ocorreu um erro ao processar sua requisição',

    'Client already exists': 'O cliente já existe',
    'Permission already exists': 'A permissão já existe',
    'Token already exists': 'O token já existe',
    'User already exists': 'O usuário já existe',

    'Invalid auth token': 'Token de autenticação inválido',
    'Refresh token not found': 'Refresh token não encontrado',
    'Invalid token type': 'Tipo de token inválido',
    'Missing auth token': 'Token de autenticação ausente',
    'Malformed auth token': 'Token de autenticação malformado',
    'Invalid reset password token': 'Token de redefinição de senha inválido',

    'Validation error': 'Erro de validação',
    "You can't delete a required extension": 'Você não pode excluir uma extensão obrigatória',

    /* <============================================================================================================> */

    Unauthorized: 'Não autorizado',

    'Unauthorized route': 'Rota não autorizada',
    'Only one super admin is allowed': 'Somente um super administrador é permitido',
    "You can't modify others permissions": 'Você não pode modificar as permissões de outros',
    "You can't modify your own permissions": 'Você não pode modificar suas próprias permissões',
    "You can't disable yourself": 'Você não pode desabilitar a si mesmo',
    "You can't create other admins": 'Você não pode criar outros administradores',
    "You can't modify this user": 'Você não pode modificar este usuário',
    "You can't delete yourself": 'Você não pode excluir a si mesmo',
    "You can't delete this user": 'Você não pode excluir este usuário',

    'Inactive account': 'Conta inativa',
    'Invalid credentials': 'Credenciais inválidas',
    'Revoked refresh token': 'Refresh token revogado',
    'Revoked access token': 'Token de acesso revogado',
    'User disabled': 'Usuário desativado',
    'Invalid refresh token': 'Refresh token inválido',

    /* <============================================================================================================> */

    'Not found': 'Não encontrado',

    'Client address not found': 'Endereço do cliente não encontrado',
    'Client not found': 'Cliente não encontrado',
    'User not found': 'Usuário não encontrado',
    'Permission not found': 'Permissão não encontrada',
    'Token not found': 'Token não encontrado',
    'Config not found': 'Configuração não encontrada',

    'File not found': 'Arquivo não encontrado',

    'Route not found': 'Rota não encontrada',

    /* <============================================================================================================> */

    'Method not allowed': 'Método não permitido',

    /* <============================================================================================================> */

    'Internal server error': 'Erro interno do servidor',

    'Persistance error': 'Erro de persistência',
    'Missing repository model': 'Modelo de repositório ausente',
    'Invalid where value type': 'Tipo de valor de where inválido',
    'Unsupported where value type': 'Tipo de valor de where não suportado',
    'Unsupported where operator': 'Operador where não suportado',
    'App url not set': 'URL do aplicativo não definida',

    'Unsupported file type': 'Tipo de arquivo não suportado',
    'File too large': 'Arquivo muito grande',
    'File too many': 'Muitos arquivos',
    'File upload error': 'Erro ao fazer upload do arquivo',

    'Email provider not found': 'Provedor de e-mail não encontrado',
    'Storage provider not found': 'Provedor de armazenamento não encontrado',
    'Extension must be started': 'A extensão deve ser iniciada',
    'Extension config is invalid': 'A configuração da extensão é inválida',
    'Extension already exists': 'A extensão já existe',
    'Extension does not exists': 'A extensão não existe',
    'Extension is unique': 'A extensão é única',

    /* <===========================================[ END METHONE ERRORS ]===========================================> */

    'An unexpected error has occurred.': 'Ocorreu um erro inesperado',
    'Check your internet connection': 'Verifique sua conexão com a internet',

    'errors.forms.unknown': 'Este campo é inválido',
    'errors.forms.required': 'Este campo é obrigatório',
    'errors.forms.maxLength': 'Este campo deve ter no máximo {max} caracteres',
    'errors.forms.minLength': 'Este campo deve ter no mínimo {min} caracteres'
  }
}
