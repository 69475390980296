import { Modal } from 'react-bootstrap'

import styled from 'styled-components'

import { SIDEBAR } from 'methone/utils/constants'

const CONTENT_DEDUCTIONS = SIDEBAR.HEADER_HEIGHT + SIDEBAR.FOOTER_HEIGHT

export const SidebarContainer = styled.div`
  position: relative;
  background: var(--gray-700);
  z-index: 999;
  box-shadow: var(--shadow);
  height: 100%;
  width: ${SIDEBAR.EXPANDED_WIDTH}px;
  min-width: ${SIDEBAR.EXPANDED_WIDTH}px;
  max-width: ${SIDEBAR.EXPANDED_WIDTH}px;
  transition:
    width 150ms linear,
    min-width 150ms linear,
    max-width 150ms linear;

  > .sidebar-header {
    padding: var(--spacing-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-2);
    height: ${SIDEBAR.HEADER_HEIGHT}px;
    overflow: hidden;

    > button {
      flex-shrink: 0;
      background: var(--gray-700);
      border-radius: var(--spacing-1);
      border: none;
      margin: 0;
      padding: var(--spacing-2);
      height: ${SIDEBAR.HEADER_BUTTON_SIZE}px;
      width: ${SIDEBAR.HEADER_BUTTON_SIZE}px;

      &:hover {
        background: var(--gray-600);
      }
    }

    > .header-logo-wrapper {
      height: ${SIDEBAR.HEADER_LOGO_HEIGHT}px;
      width: ${SIDEBAR.HEADER_LOGO_WIDTH}px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }

  > .sidebar-content {
    height: calc(100% - ${CONTENT_DEDUCTIONS}px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 var(--spacing-2);

    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  > .sidebar-footer {
    position: relative;
    padding: var(--spacing-2);
    height: ${SIDEBAR.FOOTER_HEIGHT}px;

    > div.profile-wrapper {
      padding: var(--spacing-1);
      height: calc(${SIDEBAR.FOOTER_HEIGHT}px - var(--spacing-2) * 2);
      border-radius: var(--spacing-1);
      display: grid;
      grid-template-areas: 'AV US';
      grid-template-columns: ${SIDEBAR.FOOTER_USER_ICON}px 1fr;
      gap: var(--spacing-2);
      overflow: hidden;
      cursor: pointer;

      &:hover {
        background: var(--gray-600);
      }

      > div.avatar-wrapper {
        grid-area: AV;
        background: var(--gray-1000);
        border-radius: var(--spacing-1);

        > img {
          border-radius: var(--spacing-1);
        }
      }

      > div.user-information {
        grid-area: US;
        display: flex;
        align-items: center;

        > div.user-information-name {
          font-family: var(--font-semibold);
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          white-space: nowrap;
          max-width: ${SIDEBAR.EXPANDED_WIDTH - SIDEBAR.FOOTER_USER_ICON - 8 * 3}px;
        }
      }
    }
  }

  &.sidebar-collapsed {
    width: ${SIDEBAR.COLLAPSED_WIDTH}px;
    min-width: ${SIDEBAR.COLLAPSED_WIDTH}px;
    max-width: ${SIDEBAR.COLLAPSED_WIDTH}px;

    > .sidebar-footer {
      > div.profile-wrapper {
        > div.user-information {
          display: none;
        }
      }
    }
  }
`

export const UserMenuModalBody = styled(Modal.Body)`
  display: grid;
  grid-template-areas: 'UI UI' 'LS LS';
  grid-template-columns: 1fr 175px;
  gap: var(--spacing-2);

  > :nth-child(1) {
    grid-area: UI;
    position: relative;
    padding: var(--spacing-1);
    height: calc(${SIDEBAR.FOOTER_HEIGHT}px - var(--spacing-2) * 2);
    border-radius: var(--spacing-1);
    display: grid;
    grid-template-areas: 'AV US';
    grid-template-columns: ${SIDEBAR.FOOTER_USER_ICON}px 1fr;
    gap: var(--spacing-2);
    overflow: hidden;

    &:hover {
      background: var(--gray-800);
    }

    > div.avatar-wrapper {
      grid-area: AV;
      background: var(--gray-1000);
      border-radius: var(--spacing-1);

      > img {
        border-radius: var(--spacing-1);
      }
    }

    > div.user-information {
      grid-area: US;
      display: flex;
      align-items: center;

      > div.user-information-name {
        font-family: var(--font-semibold);
        text-overflow: ellipsis;
        overflow: hidden;
        line-clamp: 1;
        white-space: nowrap;
        max-width: calc(${SIDEBAR.EXPANDED_WIDTH - SIDEBAR.FOOTER_USER_ICON * 2}px - var(--spacing-2) * 3);
      }
    }

    > div.action-buttons {
      position: absolute;
      right: var(--spacing-1);
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      gap: var(--spacing-2);

      > button {
        height: ${SIDEBAR.FOOTER_USER_ICON}px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing-2);
        padding: 0 var(--spacing-2);
      }
    }
  }

  > :nth-child(2) {
    grid-area: LS;
  }
`
