import { Container } from 'react-bootstrap'

import styled from 'styled-components'

export const ExtensionStyledContainer = styled(Container)`
  padding: var(--spacing-4);
`

export const ExtensionHeaderBlock = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: var(--spacing-2);

  .extension-type {
    font-family: var(--font-semibold);
  }
`

export const ExtensionBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    padding-bottom: var(--spacing-2);
    border-bottom: 1px solid var(--gray-300);
  }

  &:not(:first-child) {
    margin-top: var(--spacing-2);
  }

  .extension-describer {
    font-family: var(--font-semibold);
  }

  .add-extension {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-1);
    }
  }
`
