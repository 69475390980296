import { combineReducers, configureStore, Dispatch } from '@reduxjs/toolkit'

import { authenticationReducer, uiReducer } from './reducers'

const reducers = { authentication: authenticationReducer, ui: uiReducer }

type Reducers = typeof reducers
type ReduxAction<K extends keyof Reducers = keyof Reducers> = Parameters<Reducers[K]>[1]
export type ReduxStore = { [K in keyof Reducers]: ReturnType<Reducers[K]> }
export type ReduxDispatch = Dispatch<ReduxAction>

export const store = configureStore({ reducer: combineReducers(reducers) })
