import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { Control, FormState, UseFormGetValues, UseFormHandleSubmit, UseFormReset } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { i18n } from 'methone/services/i18n'
import { createExtension, getExtension, updateExtension } from 'methone/services/rest/extensions'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { SaveButton } from '../SaveButton'
import { HookFormCheckbox, HookFormInput } from '../ui/Forms'
import { Form, FormRowFooter, FormRowHeader, FormRowHeaderContent } from './styled'

interface Props {
  children?: React.ReactNode
  control: Control<ExtensionResponseDTO, any>
  getValues: UseFormGetValues<ExtensionResponseDTO>
  handleSubmit: UseFormHandleSubmit<ExtensionResponseDTO>
  reset: UseFormReset<ExtensionResponseDTO>
  testButton?: boolean
  formState: FormState<ExtensionResponseDTO>
}

export const ExtensionFormBase: React.FC<Props> = ({ children, testButton = true, ...hookForm }) => {
  const { id, type } = useParams()

  const [savingState, setSavingState] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')

  const { run, runAsync, data, error } = useRequest(getExtension, { manual: true })
  const navigate = useNavigate()

  async function handleSave(data: ExtensionResponseDTO): Promise<void> {
    try {
      setSavingState('saving')
      let redirectId = id

      if (redirectId === 'new') {
        const extension = await createExtension({ ...data, type })
        redirectId = extension.id
        navigate(`/admin/extensions/${type}/${redirectId}`)
      } else {
        await updateExtension(id, { ...data, type })
        await runAsync(id)
      }

      setSavingState('saved')
    } catch (e) {
      handleHTTPRequestError(e)
      setSavingState('error')
    }
  }

  async function handleTest(): Promise<void> {
    // const data = hookForm.getValues()
    alert('WIP')
  }

  React.useEffect(() => {
    if (id != null && id !== 'new') {
      run(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (data) {
      hookForm.reset(data)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={hookForm.handleSubmit(handleSave)}>
      <Card>
        <Card.Body>
          <FormRowHeader>
            <span>{type}</span>
            <Badge
              bg={
                hookForm.getValues().status === EXTENSION_STATUS.RUNNING
                  ? 'success'
                  : hookForm.getValues().status === EXTENSION_STATUS.ERROR
                    ? 'danger'
                    : 'secondary'
              }
            >
              {hookForm.getValues().status.toUpperCase()}
            </Badge>
          </FormRowHeader>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <FormRowHeaderContent>
            <HookFormInput control={hookForm.control} rules={{ required: true }} name="name" label={i18n('Name')} />
            <HookFormCheckbox control={hookForm.control} type="switch" name="enabled" label={i18n('Status')} />
          </FormRowHeaderContent>
        </Card.Body>
      </Card>
      {children}
      <Card>
        <Card.Body>
          <FormRowFooter>
            <SaveButton
              type="submit"
              disabled={!hookForm.formState.isValid}
              status={savingState}
              customIdleContent={id === 'new' ? i18n('Create') : i18n('Save')}
            />
            {testButton && id !== 'new' && (
              <>
                <span>{i18n('or')}</span>
                <SaveButton
                  disabled
                  onClick={handleTest}
                  customErrorVariant="outline-danger"
                  customIdleContent={i18n('Test')}
                  customIdleVariant="outline-secondary"
                  customSavedContent={i18n('Valid')}
                  customSavedVariant="outline-success"
                  customSavingContent={i18n('Testing')}
                  customSavingVariant="secondary"
                />
              </>
            )}
            <span>{i18n('or')}</span>
            <Button variant="link" onClick={() => navigate('/admin/extensions')}>
              {i18n('cancel')}
            </Button>
          </FormRowFooter>
        </Card.Body>
      </Card>
    </Form>
  )
}
