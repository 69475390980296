import { Badge, Container } from 'react-bootstrap'

import styled from 'styled-components'

export const StatusContainer = styled(Container)`
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  > .card {
    > .card-header {
      > .card-title {
        height: 22px;

        > .badge {
          padding: var(--spacing-1) var(--spacing-2);
        }
      }
    }

    > .card-body {
      > .status-item {
        &:not(:first-child) {
          margin-top: var(--spacing-3);
        }

        > .status-label {
          font-family: var(--font-semibold);
        }
      }
    }
  }

  > .metrics-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);

    > .metrics-group {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: var(--spacing-2);
      border-radius: var(--spacing-1);

      > .metrics-card {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-template-rows: 40px;
        gap: var(--spacing-2);
        padding: var(--spacing-2);
        background: var(--gray-700);
        border-radius: var(--spacing-1);
        border: 1px solid var(--gray-700);

        &.metrics-card--storage-provider {
          cursor: pointer;

          > .metrics-card-content {
            > .metrics-card-header {
              > .metrics-card--usage-label {
                i {
                  margin-left: var(--spacing-2);
                }
              }
            }
          }
        }

        > .metrics-card-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > .metrics-card-content {
          > div {
            height: 20px;
          }

          > .metrics-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .metrics-card--title {
              flex: 1;
              font-family: var(--font-semibold);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-clamp: 1;
            }
          }

          > .metrics-card--usage-bar {
            padding-top: 10px;

            > .progress {
              height: 10px;
            }
          }

          > .metrics-card-info-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .metrics-card--info-title {
              flex: 1;
              font-family: var(--font-semibold);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-clamp: 1;
            }
          }
        }
      }
    }
  }
`

export const StatusBadge = styled(Badge)`
  text-transform: capitalize;
`
