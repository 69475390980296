export class ClientAddressCreateDTO {
  clientId: string
  description: string
  phone?: string
  street?: string
  number?: number
  details?: string
  neighborhood?: string
  city?: string
  state?: string
  country?: string
}
