import { UserResponseDTO } from 'methone/shared/dto/UserResponseDTO'
import { AUTH_ACTIONS } from 'methone/utils/constants'

interface SetAuthenticatedAction {
  type: AUTH_ACTIONS.SET_AUTHENTICATED
  payload: {
    newUser: UserResponseDTO
    newAccessToken: string
  }
}

interface SetUserAction {
  type: AUTH_ACTIONS.SET_USER
  payload: {
    newUser: UserResponseDTO
  }
}

interface SetUnauthenticatedAction {
  type: AUTH_ACTIONS.SET_UNAUTHENTICATED
}

interface ResetAction {
  type: AUTH_ACTIONS.RESET
}

type AuthenticationAction = SetAuthenticatedAction | SetUnauthenticatedAction | SetUserAction | ResetAction

interface AuthenticationState {
  isAuthenticated: boolean
  user: UserResponseDTO
  accessToken: string
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  user: null,
  accessToken: null
}

export function authenticationReducer(state = initialState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AUTH_ACTIONS.SET_AUTHENTICATED:
      return { isAuthenticated: true, user: action.payload.newUser, accessToken: action.payload.newAccessToken }
    case AUTH_ACTIONS.SET_USER:
      return { ...state, user: action.payload.newUser }
    case AUTH_ACTIONS.SET_UNAUTHENTICATED:
    case AUTH_ACTIONS.RESET:
      return initialState
    default:
      return state
  }
}
