import React from 'react'

import { i18n } from 'methone/services/i18n'

import { ErrorBoundaryErrorContainer } from './styled'

interface Props {
  children?: React.ReactNode
}

interface State {
  error: Error
  errorInfo: React.ErrorInfo
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo })
  }

  render(): React.ReactNode {
    if (this.state.errorInfo && this.state.error) {
      return (
        <ErrorBoundaryErrorContainer>
          <div className="content-wrapper">
            <div className="content-header">
              <i className="fas fa-circle-exclamation fa-4x" />
              <h3>{i18n('Something went wrong.')}</h3>
              <p>
                {this.state.error.name}: {this.state.error.message}
              </p>
            </div>
            <pre className="content-body">{this.state.errorInfo.componentStack}</pre>
          </div>
        </ErrorBoundaryErrorContainer>
      )
    }

    return this.props.children
  }
}
