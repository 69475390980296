export class ClientAddressResponseDTO {
  id: string
  clientId: string
  description: string
  phone: string
  street: string
  number: number
  details: string
  neighborhood: string
  city: string
  state: string
  country: string
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string

  constructor(data: ClientAddressResponseDTO) {
    this.id = data.id
    this.clientId = data.clientId
    this.description = data.description
    this.phone = data.phone
    this.street = data.street
    this.number = data.number
    this.details = data.details
    this.neighborhood = data.neighborhood
    this.city = data.city
    this.state = data.state
    this.country = data.country
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.updatedAt = data.updatedAt
    this.updatedBy = data.updatedBy
  }
}
