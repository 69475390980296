import { CLIENTS_PATHS, REST_ENDPOINTS } from 'methone/shared/constants'
import { ClientAddressResponseDTO } from 'methone/shared/dto/ClientAddressResponseDTO'
import { ClientCreateDTO } from 'methone/shared/dto/ClientCreateDTO'
import { ClientResponseDTO } from 'methone/shared/dto/ClientResponseDTO'
import { ClientResumeResponseDTO } from 'methone/shared/dto/ClientResumeResponseDTO'
import { ClientUpdateDTO } from 'methone/shared/dto/ClientUpdateDTO'
import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

const ENDPOINT = REST_ENDPOINTS.CLIENTS

export async function exportClientsList(fileType: 'csv' | 'excel' | 'pdf', params: ListClientsFilters): Promise<File> {
  const { data: response } = await api.get(`/${ENDPOINT}/${CLIENTS_PATHS.EXPORT}/${fileType}`, {
    params,
    responseType: 'blob'
  })

  const exts = {
    csv: 'csv',
    pdf: 'pdf',
    excel: 'xlsx'
  }

  return new File([response], `clients.${exts[fileType]}`, { type: response.type })
}

export async function listClientGroups(): Promise<Options<string>> {
  const { data: response } = await api.get(`/${ENDPOINT}/${CLIENTS_PATHS.GROUPS}`)

  return response
}

export async function findClient(id: string): Promise<ClientResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function findClientResume(id: string): Promise<ClientResumeResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}/${CLIENTS_PATHS.RESUME}`)

  return response
}

export async function findClientAddresses(id: string): Promise<ClientAddressResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}/${CLIENTS_PATHS.ADDRESSES}`)

  return response
}

export async function listClients(params?: ListClientsFilters): Promise<PaginationDTO<ClientResumeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createClient(data: Partial<ClientCreateDTO>): Promise<ClientResumeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateClient(id: string, data: Partial<ClientUpdateDTO>): Promise<ClientResumeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteClient(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
