import { FormLabel as BSLabel } from 'react-bootstrap'

import styled from 'styled-components'

export const Label = styled(BSLabel)`
  display: inline-block;
  font-family: var(--font-semibold);
  line-height: 1;
  margin: 0 0 var(--spacing-1) var(--spacing-1);

  &.required {
    &::after {
      content: '*';
      color: var(--red-500);
      margin-left: var(--spacing-1);
    }
  }
`
