import { clearObject } from 'methone/shared/clearObject'

export function parseURLSearchParams<T>(_params: Record<string, any>, defaultParams: T): T {
  function primitiveParse(value: string | string[]): (string | number | boolean) | (string | number | boolean)[] {
    if (Array.isArray(value)) {
      return value.map(primitiveParse) as (string | number | boolean)[]
    } else if (
      value === 'null' ||
      value === 'undefined' ||
      value === 'NaN' ||
      value.includes('Infinity') ||
      value.trim() === ''
    ) {
      return null
    } else if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
      return value.toLowerCase() === 'true'
    } else if (!Number.isNaN(Number(value)) && value[0] !== '0') {
      return Number(value)
    } else {
      return value
    }
  }

  const newParams = Object.entries(_params).reduce((acc, [k, v]) => {
    return clearObject({ ...acc, [k]: primitiveParse(v) })
  }, {})

  return Object.keys(newParams).length > 0 ? (newParams as T) : defaultParams
}
