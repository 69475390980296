import { Card, Container } from 'react-bootstrap'

import styled from 'styled-components'

export const ProfileContainer = styled(Container)`
  padding: var(--spacing-4);

  > form {
    display: grid;
    grid-template-areas: 'UC FM' 'AC AC';
    grid-template-columns: 150px 1fr;
    gap: var(--spacing-2);

    > .form-footer {
      grid-area: AC;

      > .card-body {
        display: flex;
        justify-content: space-between;

        > .required-warn {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--font-semibold);
          color: var(--red-500);
        }
      }
    }
  }
`

export const FormCard = styled(Card)`
  grid-area: FM;
  height: min-content;

  > .card-body {
    height: min-content;

    > fieldset {
      display: grid;
      grid-template-areas: 'NM UN' 'EM EM';
      grid-template-columns: 1fr 200px;
      gap: var(--spacing-2);

      > .form-input-container--name {
        grid-area: NM;
      }

      > .form-input-container--username {
        grid-area: UN;
      }

      > .form-input-container--email {
        grid-area: EM;
      }
    }
  }
`

export const ProfileCard = styled(Card)`
  grid-area: UC;

  > .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .avatar-wrapper {
      margin: var(--spacing-3);
      width: 100px;
      height: 100px;
      border-radius: var(--spacing-1);
      overflow: hidden;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > .user-name {
      width: calc(148px - calc(var(--spacing-3) * 2));
      font-family: var(--font-semibold);
      font-size: 14px;
      text-align: center;
      line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .user-permissions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: var(--spacing-3);
      gap: var(--spacing-1);
    }
  }
`
