import React from 'react'
import { useNavigate } from 'react-router-dom'

import { i18n } from 'methone/services/i18n'

import { NotFoundContainer } from './styled'

export function NotFoundErrorPage(): JSX.Element {
  const navigate = useNavigate()

  return (
    <NotFoundContainer>
      <div className="not-found-container">
        <div className="error-message">
          <h1 className="not-found">404</h1>
          <h2 className="page-not-found">{i18n('This page can not be founded.')}</h2>
        </div>
        <div className="back-home">
          <button type="button" onClick={() => navigate('/')}>
            {i18n('Back to home')}
          </button>
        </div>
      </div>
    </NotFoundContainer>
  )
}
