import React from 'react'
import { Navigate } from 'react-router-dom'

import { ErrorBoundary } from 'methone/components/ErrorBoundary'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { Dimensions } from 'methone/hooks'
import { NotFoundErrorPage } from 'methone/pages/NotFound'
import { setPageTitle } from 'methone/services/setPageTitle'
import { Permissions } from 'methone/shared/permissions'
import { store } from 'methone/store'

import { validatePermsByStore } from './validatePermsByStore'

interface PageProps {
  title: string
  dimensions: Dimensions
}

interface SecPageProps extends PageProps {
  permissions?: Permissions[]
}

const PageLoader: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
  React.useEffect(() => {
    setPageTitle(title)
  }, [title])

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export function page(Component: React.FC<MethonePageProps>, { dimensions, title }: PageProps): JSX.Element {
  return (
    <PageLoader title={title}>
      <Component dimensions={dimensions} />
    </PageLoader>
  )
}

export function securePage(Component: React.FC<MethonePageProps>, { permissions, ...rest }: SecPageProps): JSX.Element {
  const { isAuthenticated, user } = store.getState().authentication

  if (isAuthenticated == null || user == null) {
    return <FixedLoading />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  if (!validatePermsByStore(permissions) && (permissions ?? []).length > 0) {
    return <NotFoundErrorPage />
  }

  return page(Component, rest)
}
