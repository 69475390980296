import { enUS, ptBR } from 'methone/i18n'

import { storageService } from './storageService'

interface I18NFunction {
  (key: string, auxiliar?: Record<string, string | number | boolean>): string
  addItems(lang: MethoneLanguage, items: Record<string, string>): void
  data: Record<string, Record<string, string>>
  getLang(): MethoneLanguage
}

const i18n: I18NFunction = function (key, auxiliar) {
  const lang = storageService.getItem('language') ?? 'pt-BR'
  let text: string = i18n.data[lang][key] ?? key

  if (auxiliar && typeof auxiliar === 'object') {
    for (const [key, value] of Object.entries(auxiliar)) {
      text = text.replace(new RegExp(`{${key}}`, 'g'), String(value))
    }
  }

  return text
}

i18n.data = { 'pt-BR': ptBR.values, 'en-US': enUS.values }

i18n.addItems = function (lang, items) {
  i18n.data[lang] = { ...i18n.data[lang], ...items }
}

i18n.getLang = function () {
  return storageService.getItem('language') ?? 'pt-BR'
}

export { i18n }
