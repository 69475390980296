import styled from 'styled-components'

export const ForgotPasswordContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  > .card {
    > form {
      > .card-body {
        > .form-group:not(:first-child) {
          margin-top: var(--spacing-2);
        }
      }
    }
  }
`
