import React from 'react'
import { Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { ExtensionFormBase } from 'methone/components/ExtensionFormBase'
import { HookFormCheckbox, HookFormInput, HookFormPassword } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { ExtensionResponseDTO } from 'methone/shared/dto/ExtensionResponseDTO'

import { SMTPEmailProviderStyledContainer } from './styled'

const defaultValues: ExtensionResponseDTO<SMTPConfig> = {
  id: '',
  name: '',
  type: '',
  enabled: true,
  status: EXTENSION_STATUS.STOPPED,
  jsonConfig: {
    emailAddress: '',
    emailName: '',
    host: '',
    password: '',
    port: undefined,
    secure: false,
    user: ''
  },
  createdAt: undefined,
  createdBy: '',
  updatedAt: undefined,
  updatedBy: '',
  deletedAt: undefined,
  deletedBy: ''
}

export const SMTPEmailProviderForm: React.FC = () => {
  const { control, ...rest } = useForm<ExtensionResponseDTO<SMTPConfig>>({ defaultValues, mode: 'all' })

  return (
    <ExtensionFormBase control={control} {...rest}>
      <Card>
        <Card.Body>
          <SMTPEmailProviderStyledContainer>
            <div className="g1">
              <HookFormInput control={control} name="jsonConfig.emailName" label={i18n('Email name')} />
              <HookFormInput control={control} name="jsonConfig.emailAddress" label={i18n('Email address')} />
            </div>
            <div className="g2">
              <HookFormInput control={control} name="jsonConfig.host" label={i18n('Host')} />
              <HookFormInput control={control} name="jsonConfig.port" label={i18n('Port')} />
              <HookFormCheckbox control={control} type="switch" name="jsonConfig.secure" label={i18n('Secure')} />
            </div>
            <div className="g3">
              <HookFormPassword control={control} name="jsonConfig.password" label={i18n('Password')} />
              <HookFormInput control={control} name="jsonConfig.user" label={i18n('Username')} />
            </div>
          </SMTPEmailProviderStyledContainer>
        </Card.Body>
      </Card>
    </ExtensionFormBase>
  )
}
