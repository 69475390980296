import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import cx from 'classnames'

import { useDispatch, useSelector } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { menuService } from 'methone/services/menuService'
import { storageService } from 'methone/services/storageService'
import { AUTH_ACTIONS, SIDEBAR, UI_ACTIONS } from 'methone/utils/constants'
import { menuItemFilter } from 'methone/utils/menuItemFilter'
import { sortByOrder } from 'methone/utils/sortByOrder'

import { Select } from '../ui/Forms'
import { NavbarItem } from './NavbarItem'
import { SidebarContainer, UserMenuModalBody } from './styled'

const languageMap = new Map([
  ['pt-BR', { value: 'pt-BR', label: 'Português (Brasil)' }],
  ['en-US', { value: 'en-US', label: 'English (United States)' }]
])

export const Navbar: React.FC = () => {
  const navigate = useNavigate()

  const [focusedMenuItem, setFocusedMenuItem] = React.useState<string>(null)
  const [userMenuModal, setUserMenuModal] = React.useState<boolean>(false)

  const user = useSelector((store) => store.authentication.user)
  const sidebarExpanded = useSelector((store) => store.ui.sidebarExpanded)
  const dispatch = useDispatch()

  function handleExpandChange(): void {
    setFocusedMenuItem(null)
    dispatch({ type: UI_ACTIONS.SET_SIDEBAR_EXPANDED, payload: !sidebarExpanded })
  }

  async function handleLogout(): Promise<void> {
    if (await globalConfirmation.danger(i18n('Are you sure you want to logout?'))) {
      storageService.removeItem('refreshToken')
      dispatch({ type: AUTH_ACTIONS.SET_UNAUTHENTICATED })
      setUserMenuModal(false)
      navigate('/login')
    }
  }

  function navigateToProfile(): void {
    navigate('/profile')
    setUserMenuModal(false)
  }

  return (
    <>
      <SidebarContainer className={cx('methone-sidebar', { 'sidebar-collapsed': !sidebarExpanded })}>
        <div className="sidebar-header">
          <button onClick={handleExpandChange}>
            <i className={`fas fa-chevron-${sidebarExpanded ? 'left' : 'right'}`} />
          </button>
          <div className="header-logo-wrapper">
            <img src="/images/application-logo.png" height={SIDEBAR.HEADER_BUTTON_SIZE} />
          </div>
        </div>
        <div className="sidebar-content">
          {menuService
            .getMenuItems()
            .sort(sortByOrder)
            .filter(menuItemFilter)
            .map((menuItem, idx) => (
              <NavbarItem
                key={idx}
                menuItem={menuItem}
                focusedMenuItem={focusedMenuItem}
                setFocusedMenuItem={setFocusedMenuItem}
              />
            ))}
        </div>
        <div className="sidebar-footer">
          <div className="profile-wrapper" onClick={() => setUserMenuModal(true)}>
            <div className="avatar-wrapper">
              <img
                src={`https://ui-avatars.com/api/?name=${user.name}`}
                width={SIDEBAR.FOOTER_USER_ICON}
                height={SIDEBAR.FOOTER_USER_ICON}
              />
            </div>
            <div className="user-information">
              <div className="user-information-name">{user.name.split(' ', 2).join(' ')}</div>
            </div>
          </div>
        </div>
      </SidebarContainer>

      <Modal show={userMenuModal} onHide={() => setUserMenuModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{i18n('User menu')}</Modal.Title>
        </Modal.Header>
        <UserMenuModalBody>
          <div className="profile-wrapper">
            <div className="avatar-wrapper">
              <img
                src={`https://ui-avatars.com/api/?name=${user.name}`}
                width={SIDEBAR.FOOTER_USER_ICON}
                height={SIDEBAR.FOOTER_USER_ICON}
              />
            </div>
            <div className="user-information">
              <div className="user-information-name">{user.name.split(' ', 2).join(' ')}</div>
            </div>
            <div className="action-buttons">
              <Button variant="primary" onClick={navigateToProfile} className="profile-button">
                <i className="fas fa-user" />
                {i18n('Profile')}
              </Button>
              <Button variant="danger" onClick={handleLogout}>
                <i className="fas fa-sign-out" />
              </Button>
            </div>
          </div>
          <Select
            value={languageMap.get(storageService.getItem('language'))}
            options={Array.from(languageMap.values())}
            label={i18n('Language')}
            isClearable={false}
            onChange={(option) => {
              storageService.setItem('language', (option as Option<string>).value as MethoneLanguage)
              window.location.reload()
            }}
          />
        </UserMenuModalBody>
      </Modal>
    </>
  )
}
