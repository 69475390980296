export class PaginationDTO<T> {
  totalEntries: number
  totalPages: number
  perPage: number
  currentPage: number
  entries: Array<T>

  constructor(totalEntries: number, totalPages: number, perPage: number, currentPage: number, entries: Array<T>) {
    this.totalEntries = totalEntries
    this.totalPages = totalPages
    this.perPage = perPage
    this.currentPage = currentPage
    this.entries = entries
  }
}
