export class ExtensionBuilderResponseDTO {
  name: string
  unique: boolean

  constructor(data: Partial<ExtensionBuilderResponseDTO>) {
    this.name = data.name
    this.unique = data.unique
  }

  public static fromIterable(iterable: IterableIterator<ExtensionBuilderResponseDTO>): ExtensionBuilderResponseDTO[] {
    return Array.from(iterable).map((item) => new ExtensionBuilderResponseDTO(item))
  }
}
