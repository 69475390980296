export class ClientResumeResponseDTO {
  id: string
  name: string
  taxId: string
  group: string
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string

  constructor(data: ClientResumeResponseDTO) {
    this.id = data.id
    this.name = data.name
    this.taxId = data.taxId.substring(0, 3).padEnd(data.taxId.length, '*')
    this.group = data.group
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.updatedAt = data.updatedAt
    this.updatedBy = data.updatedBy
  }
}
