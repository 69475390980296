import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Joi from 'joi'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput } from 'methone/components/ui/Forms'
import { useSelector } from 'methone/hooks'
import { globalNotification } from 'methone/services/globalNotification'
import { i18n } from 'methone/services/i18n'
import { userForgotPassword } from 'methone/services/rest/users'
import { storageService } from 'methone/services/storageService'
import { UserForgotPasswordDTO } from 'methone/shared/dto/UserForgotPasswordDTO'
import { JOIErrorMessages } from 'methone/shared/JOIErrorMessages'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { ForgotPasswordContainer } from './styled'

const defaultValues: UserForgotPasswordDTO = {
  email: ''
}

export const ForgotPasswordPage: React.FC = () => {
  const isMounted = React.useRef(false)
  const navigate = useNavigate()

  const isAuthenticated = useSelector((store) => store.authentication.isAuthenticated)
  const [loading, setLoading] = React.useState(false)

  const { control, handleSubmit } = useForm<UserForgotPasswordDTO>({ mode: 'all', defaultValues })

  const handleLogin: SubmitHandler<UserForgotPasswordDTO> = async (data) => {
    try {
      setLoading(true)

      const { error } = Joi.object<UserForgotPasswordDTO>({
        email: Joi.string()
          .email({ allowUnicode: false, tlds: { allow: false } })
          .required()
      })
        .messages(JOIErrorMessages)
        .validate(data)

      if (error) {
        throw error
      }

      await userForgotPassword({ email: data.email })

      globalNotification.success(i18n('An email has been sent to you with instructions to reset your password.'))

      navigate('/login')
    } catch (err: any) {
      handleHTTPRequestError(err)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (isMounted.current) {
      if (storageService.hasItem('refreshToken') || isAuthenticated) {
        navigate('/')
      }
    } else {
      isMounted.current = true
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ForgotPasswordContainer>
      <Card style={{ minWidth: 310.283 }}>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Card.Header>
            <Card.Img src="/images/application-logo.png" alt="methone-logo" height="70px" />
          </Card.Header>
          <Card.Body>
            <HookFormInput
              control={control}
              name="email"
              rules={{ required: true }}
              label={i18n('Email')}
              inputProps={{ autoCorrect: 'false', autoCapitalize: 'false' }}
            />
          </Card.Body>
          <Card.Footer style={{ display: 'flex', gap: 'var(--spacing-2)', flexDirection: 'column' }}>
            <Button type="submit" variant="primary">
              {i18n('Submit')}
            </Button>
            <Button type="button" variant="outline-secondary" onClick={() => navigate('/login')}>
              {i18n('Back to login')}
            </Button>
          </Card.Footer>
        </form>
      </Card>
      <FixedLoading enabled={loading} />
    </ForgotPasswordContainer>
  )
}
