import React from 'react'
import { FormLabel, FormText } from 'react-bootstrap'
import Dropzone, { DropEvent, DropzoneProps, DropzoneRef, DropzoneRootProps, DropzoneInputProps } from 'react-dropzone'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

import cx from 'classnames'
import { filesize as fileSize } from 'filesize'

import { i18n } from 'methone/services/i18n'

import { getHookFormErrorMessage } from '../errorMessages'
import { DropMessage, FileItemContainer, FileItemWrapper } from './styled'

export interface FileData {
  name: string
  size: number | string
  preview?: string | FAIconType
  file?: File
}

export interface FileItemProps extends Omit<DropzoneProps, 'onDropAccepted' | 'ref'> {
  value: FileData[]
  onChange: (files: FileData[], event: DropEvent) => void
  showDelete?: boolean
  onDelete?: (data: FileData[]) => void
  acceptMessage?: string
  rejectMessage?: string
  idleMessage?: string
  rootProps?: DropzoneRootProps
  inputProps?: DropzoneInputProps
  name?: string
  id?: string
  label?: string
  error?: string
  required?: boolean
}

function faIconTypeGuard(arg: any): arg is FAIconType {
  const allowedKeys = ['fas', 'far', 'fal', 'fat', 'fad', 'fa-solid', 'fa-regular', 'fa-light', 'fa-thin', 'fa-duotone']

  return typeof arg === 'string' && allowedKeys.some((key) => arg.startsWith(key))
}

export const FileItem = React.forwardRef<DropzoneRef, FileItemProps>(function FileItemComponent(props, ref) {
  const { onChange, value, acceptMessage, error, idleMessage, inputProps, label, onDelete, rejectMessage, required, rootProps, showDelete = true, ...dropzone } = props  // eslint-disable-line prettier/prettier
  const id = dropzone?.name ?? Math.random().toString(36).substring(7)
  const fullId = dropzone?.id ?? `file-field--${id}`
  const cnBase = 'form-file'
  const inputClassName = `form-control ${cnBase}-field`

  function handleMessage(isDragAccept: boolean, isDragActive: boolean, isDragReject: boolean): string {
    if (isDragActive && isDragAccept) {
      return acceptMessage ?? i18n('Drop the file here')
    }

    if (isDragActive && isDragReject) {
      return rejectMessage ?? i18n('Unsupported file type')
    }

    return idleMessage ?? i18n('Click here or drop a file to upload')
  }

  async function handleDropAccepted(files: File[], event: DropEvent): Promise<void> {
    const newFiles = await Promise.all(
      files.map(async (file) => {
        let previewData = null

        if (file.type.startsWith('image')) {
          const fileReader = new FileReader()
          fileReader.readAsDataURL(file)
          previewData = await new Promise((resolve, reject) => {
            fileReader.onload = () => resolve(fileReader.result)
            fileReader.onerror = (error) => reject(error)
          })
        }

        return { name: file.name, size: file.size, preview: previewData ?? 'far fa-file fa-2xl', file }
      })
    )

    onChange(newFiles, event)
  }

  return (
    <FileItemWrapper className={cx(`form-group ${cnBase}-container ${cnBase}-container--${id}`, { hasError: error })}>
      {label && (
        <FormLabel className={cx(`${cnBase}-label`, { required })} htmlFor={fullId}>
          {label}
        </FormLabel>
      )}

      <Dropzone {...dropzone} ref={ref} onDropAccepted={handleDropAccepted}>
        {({ getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject }) => (
          <FileItemContainer
            {...getRootProps(rootProps)}
            className={cx('file-item', { missing: !value || value.length === 0, disabled: dropzone.disabled })}
            isDragAccept={isDragAccept && isDragActive}
            isDragReject={isDragReject && isDragActive}
          >
            {(value ?? []).length > 0 &&
              (value.length > 1 ? (
                <>
                  <div className="file-item__icon">
                    <i className="fa-regular fa-files fa-2xl" />
                  </div>
                  <div className="file-item__details">
                    <div className="file-item__name">{i18n('Multiple files allocated')}</div>
                    <div className="file-item__extra">{i18n('{length} files', { length: value.length })}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className="file-item__icon">
                    {faIconTypeGuard(value[0].preview) ? (
                      <i className={value[0].preview} />
                    ) : typeof value[0].preview === 'string' ? (
                      <img src={value[0].preview} alt={value[0].name} />
                    ) : (
                      <i className="fa-regular fa-file fa-2xl" />
                    )}
                  </div>
                  <div className="file-item__details">
                    <div className="file-item__name">{value[0].name}</div>
                    <div className="file-item__extra">
                      {typeof value[0].size !== 'number' ? value[0].size : fileSize(value[0].size)}
                    </div>
                  </div>
                </>
              ))}

            {((value ?? []).length === 0 || isDragAccept || isDragReject) && !dropzone.disabled && (
              <DropMessage isDragAccept={isDragAccept} isDragReject={isDragReject}>
                <span>{handleMessage(isDragAccept, isDragActive, isDragReject)}</span>
              </DropMessage>
            )}
            <input type="file" {...getInputProps({ ...(inputProps ?? {}), className: inputClassName, id: fullId })} />
          </FileItemContainer>
        )}
      </Dropzone>
      {(value ?? []).length > 0 && (onDelete || showDelete) && dropzone.disabled !== true && (
        <div className="file-item__delete">
          <button type="button" onClick={() => (onDelete ? onDelete(value) : onChange([], null))}>
            <i className="far fa-trash-can" />
          </button>
        </div>
      )}

      {error && (
        <FormText className={`error ${cnBase}-error`}>
          <i className="fas fa-times-circle" />
          {error}
        </FormText>
      )}
    </FileItemWrapper>
  )
})

export interface HFFileItemProps<TFV extends FieldValues, TN extends Path<TFV>>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  label?: string
  fileItemProps?: Omit<FileItemProps, 'label' | 'ref' | 'name' | 'value' | 'onChange' | 'error' | 'required'>
}

export function HookFormFileItem<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  fileItemProps
}: HFFileItemProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FileItem
          {...fileItemProps}
          label={label}
          ref={field.ref}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
