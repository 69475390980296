import React from 'react'
import { Button } from 'react-bootstrap'

import { globalNotification } from 'methone/services/globalNotification'
import { i18n } from 'methone/services/i18n'

interface Props extends React.ComponentProps<typeof Button> {
  status?: 'idle' | 'saving' | 'saved' | 'error'
  customErrorContent?: React.ReactNode
  customErrorVariant?: string
  customIdleContent?: React.ReactNode
  customIdleVariant?: string
  customSavedContent?: React.ReactNode
  customSavedVariant?: string
  customSavingContent?: React.ReactNode
  customSavingVariant?: string
}

export const SaveButton: React.FC<Props> = ({
  onClick,
  type,
  status: externalStatus,
  customErrorContent,
  customErrorVariant,
  customIdleContent,
  customIdleVariant,
  customSavedContent,
  customSavedVariant,
  customSavingContent,
  customSavingVariant,
  ...rest
}) => {
  const [status, setStatus] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')

  async function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
    if (onClick) {
      try {
        setStatus('saving')
        await onClick(e)
        setStatus('saved')
      } catch (err: any) {
        setStatus('error')
        globalNotification.error(err.message)
      }
    }
  }

  function handleVariant(): string {
    switch (externalStatus ?? status) {
      case 'saved':
        return customSavedVariant ?? 'success'
      case 'error':
        return customErrorVariant ?? 'danger'
      case 'saving':
        return customSavingVariant ?? 'primary'
      default:
        return customIdleVariant ?? 'primary'
    }
  }

  return (
    <Button {...rest} type={type ?? 'button'} variant={handleVariant()} onClick={handleClick}>
      {(externalStatus ?? status) === 'saved'
        ? customSavedContent ?? i18n('Saved')
        : (externalStatus ?? status) === 'error'
          ? customErrorContent ?? i18n('Error')
          : (externalStatus ?? status) === 'saving'
            ? customSavingContent ?? i18n('Saving...')
            : customIdleContent ?? i18n('Save')}
    </Button>
  )
}
