import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect } from 'methone/components/ui/Forms'
import { useSelector } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { listPermissions } from 'methone/services/rest/permissions'
import { createUser, findUser, updateUser } from 'methone/services/rest/users'
import { UserResponseDTO } from 'methone/shared/dto/UserResponseDTO'
import { Permissions } from 'methone/shared/permissions'
import { validUUID } from 'methone/shared/validators'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { UserEditorContainer } from './styled'

interface FormProps extends Omit<UserResponseDTO, 'id' | 'createdAt' | 'updatedAt' | 'permissions'> {
  permissions: Options<string>
}

const defaultValues: FormProps = {
  name: '',
  username: '',
  email: '',
  enabled: true,
  permissions: []
}

export const UserEditorPage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [status, setStatus] = React.useState<'idle' | 'saving' | 'saved' | 'error'>('idle')
  const [permissions, setPermissions] = React.useState<Options<string>>([])

  const { id } = useParams()
  const { control, handleSubmit, reset, watch } = useForm<FormProps>({ defaultValues, mode: 'all' })
  const enabled = watch('enabled')
  const user = useSelector((state) => state.authentication.user)

  const navigate = useNavigate()

  async function handleGetUserData(): Promise<void> {
    try {
      setIsLoading(true)

      const permissions = (await listPermissions())
        .map((permission) => ({
          value: permission.name,
          label: permission.langs[i18n.getLang()] ?? permission.name
        }))
        .filter((p) => p.value !== Permissions.SUPER_ADMIN)

      setPermissions(permissions)

      if (id === 'new') {
        reset({
          enabled: true,
          permissions: permissions.filter((p) => p.value === Permissions.COMMON)
        })
      } else {
        const response = await findUser(id)

        if (id === user.id) {
          navigate('/profile')
        }

        reset({
          name: response.name,
          username: response.username,
          email: response.email,
          enabled: response.enabled,
          permissions: response.permissions.map((permission) => ({
            value: permission,
            label: permissions.find((p) => p.value === permission)?.label ?? permission
          }))
        })
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleToggleUserEnabledState(): Promise<void> {
    try {
      if (id === 'new') {
        return
      }

      if (
        await globalConfirmation.warn(i18n(`Are you sure you want to ${enabled ? 'disable' : 'enable'} this user?`), {
          confirmText: i18n('Yes, I am sure')
        })
      ) {
        setIsLoading(true)

        const response = await findUser(id)
        const body = { ...response }
        body.enabled = !body.enabled
        await updateUser(id, body)

        await handleGetUserData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitForm: SubmitHandler<FormProps> = async (data) => {
    try {
      setIsLoading(true)
      setStatus('saving')

      if (id === 'new') {
        delete data.enabled

        const createdUser = await createUser({
          ...data,
          permissions: data.permissions.map(({ value }) => value)
        })

        navigate(`/admin/users/${createdUser.id}`)
      } else {
        if (!validUUID(id)) {
          delete data.enabled
          delete data.permissions
        }

        await updateUser(validUUID(id) ? id : user.id, {
          ...data,
          permissions: data.permissions?.map(({ value }) => value)
        })

        setStatus('saved')
        await handleGetUserData()
      }
    } catch (e) {
      setStatus('error')
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (id && (validUUID(id) || id === 'new')) {
      if (id === user.id) {
        navigate('/profile')
      } else {
        handleGetUserData()
      }
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserEditorContainer>
      <FixedLoading enabled={isLoading} />
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Card>
          <Card.Body>
            <fieldset>
              <HookFormInput label={i18n('Name')} name="name" control={control} rules={{ required: true }} />
              <HookFormInput label={i18n('Username')} name="username" control={control} rules={{ required: true }} />
              <HookFormInput label={i18n('Email')} name="email" control={control} rules={{ required: true }} />
              <HookFormSelect
                label={i18n('Permissions')}
                name="permissions"
                control={control}
                isMulti={true}
                rules={{ required: true }}
                options={permissions}
              />
            </fieldset>
          </Card.Body>
        </Card>
        <Card className="form-footer">
          <Card.Body style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="actions-area">
              <SaveButton type="submit" status={status}>
                {i18n(id !== 'new' ? 'Save' : 'Create')}
              </SaveButton>
              {id !== 'new' && (
                <>
                  <span style={{ marginRight: 10 }}>{i18n('or')}</span>
                  <Button
                    type="button"
                    variant={enabled ? 'outline-danger' : 'outline-success'}
                    onClick={handleToggleUserEnabledState}
                  >
                    {i18n(enabled ? 'Disable' : 'Enable')}
                  </Button>
                </>
              )}
              <span>{i18n('or')}</span>
              <Button type="button" variant="link" onClick={() => navigate(`/admin/users`)}>
                {i18n('cancel')}
              </Button>
            </div>

            <div className="required-warn">* {i18n('Required fields')}</div>
          </Card.Body>
        </Card>
      </form>
    </UserEditorContainer>
  )
}
