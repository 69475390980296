import { storageService } from './storageService'

class LoggerFactory {
  private logLevel: string

  constructor() {
    this.logLevel = 'warn'
  }

  public setLogLevel(level: string): void {
    storageService.setItem('logLevel', level)
    this.logLevel = level
  }

  public getLogLevel(): string {
    return this.logLevel
  }

  public error(...args: any[]): void {
    this.doLog('error', ...args)
  }

  public warn(...args: any[]): void {
    this.doLog('warn', ...args)
  }

  public info(...args: any[]): void {
    this.doLog('info', ...args)
  }

  public debug(...args: any[]): void {
    this.doLog('debug', ...args)
  }

  private levelDecode(level: string): number {
    return (
      {
        debug: 0,
        info: 1,
        warn: 2,
        error: 3
      }[level] ?? 2
    )
  }

  private doLog(level: string, ...args: any[]): void {
    if (this.levelDecode(level) >= this.levelDecode(this.logLevel)) {
      console[this.handleLogLevel(level)](...args)
    }
  }

  private handleLogLevel(level: string): 'debug' | 'warn' | 'error' | 'info' | 'log' {
    switch (level) {
      case 'debug':
        return 'debug'
      case 'warn':
        return 'warn'
      case 'error':
        return 'error'
      case 'info':
        return 'info'
      default:
        return 'log'
    }
  }
}

export const logger = new LoggerFactory()
