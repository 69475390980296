import { REST_ENDPOINTS, USERS_PATHS } from 'methone/shared/constants'
import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { UserCreateDTO } from 'methone/shared/dto/UserCreateDTO'
import { UserForgotPasswordDTO } from 'methone/shared/dto/UserForgotPasswordDTO'
import { UserLoginDTO } from 'methone/shared/dto/UserLoginDTO'
import { UserResetPasswordDTO } from 'methone/shared/dto/UserResetPasswordDTO'
import { UserResponseDTO } from 'methone/shared/dto/UserResponseDTO'
import { UserResumeResponseDTO } from 'methone/shared/dto/UserResumeResponseDTO'
import { UserUpdateDTO } from 'methone/shared/dto/UserUpdateDTO'
import { UserWithTokensDTO } from 'methone/shared/dto/UserWithTokensDTO'
import { api } from 'methone/utils/api'

const ENDPOINT = REST_ENDPOINTS.USERS

export async function userForgotPassword(data: UserForgotPasswordDTO): Promise<void> {
  await api.post<UserWithTokensDTO>(`/${ENDPOINT}/${USERS_PATHS.FORGOT_PASSWORD}`, data)
}

export async function userResetPassword(data: UserResetPasswordDTO): Promise<void> {
  await api.post<UserWithTokensDTO>(`/${ENDPOINT}/${USERS_PATHS.RESET_PASSWORD}`, data)
}

export async function userLogin(data: UserLoginDTO): Promise<UserWithTokensDTO> {
  const { data: response } = await api.post<UserWithTokensDTO>(`/${ENDPOINT}/${USERS_PATHS.LOGIN}`, data)

  return response
}

export async function getUserAccessToken(data: { token: string }): Promise<UserWithTokensDTO> {
  const { data: response } = await api.post<UserWithTokensDTO>(`/${ENDPOINT}/${USERS_PATHS.REFRESH_TOKEN}`, data)

  return response
}

export async function getUserInformation(): Promise<UserResponseDTO> {
  const { data: response } = await api.get<UserResponseDTO>(`/${ENDPOINT}/${USERS_PATHS.ME}`)

  return response
}

export async function listUsers(params?: ListUsersFilters): Promise<PaginationDTO<UserResumeResponseDTO>> {
  const { data: response } = await api.get<PaginationDTO<UserResumeResponseDTO>>(`/${ENDPOINT}`, { params })

  return response
}

export async function findUser(id: string): Promise<UserResponseDTO> {
  const { data: response } = await api.get<UserResponseDTO>(`/${ENDPOINT}/${id}`)

  return response
}

export async function createUser(data: UserCreateDTO): Promise<UserResponseDTO> {
  const { data: response } = await api.post<UserResponseDTO>(`/${ENDPOINT}`, data)

  return response
}

export async function updateUser(id: string, data: Partial<UserUpdateDTO>): Promise<UserResponseDTO> {
  const { data: response } = await api.put<UserResponseDTO>(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteUser(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
