import BSModal from 'react-bootstrap/Modal'

import styled from 'styled-components'

interface Props {
  level: string
  customHeaderBackground?: string
  customHeaderColor?: string
}

function handleLevelText(level: string, customColor: string): string {
  if (customColor != null) {
    return customColor
  }

  switch (level) {
    case 'info':
    case 'warning':
      return 'var(--gray-1000)'
    default:
      return 'var(--gray-0)'
  }
}
function handleLevel(level: string, customColor: string): string {
  if (customColor != null) {
    return customColor
  }

  switch (level) {
    case 'primary':
      return 'var(--bs-primary)'
    case 'success':
      return 'var(--bs-success)'
    case 'info':
      return 'var(--bs-info)'
    case 'warning':
      return 'var(--bs-warning)'
    case 'danger':
      return 'var(--bs-danger)'
    default:
      return `var(--gray-900)`
  }
}

export const Modal = styled(BSModal)<Props>`
  > .modal-dialog {
    > .modal-content {
      > .modal-header {
        gap: var(--spacing-2);
        justify-content: flex-start;
        align-items: center;
        font-size: var(--font-size-4);
        font-family: var(--font-semibold);
        background: ${({ level, customHeaderBackground }) => handleLevel(level, customHeaderBackground)} !important;
        color: ${({ level, customHeaderColor }) => handleLevelText(level, customHeaderColor)} !important;

        > .modal-title {
          color: ${({ level, customHeaderColor }) => handleLevelText(level, customHeaderColor)} !important;
        }

        svg > path,
        i {
          color: ${({ level, customHeaderColor }) => handleLevelText(level, customHeaderColor)} !important;
        }
      }

      > .modal-body {
        padding: var(--spacing-4);
      }

      > .modal-footer {
        justify-content: center;

        > span {
          margin-left: var(--spacing-3);
        }

        > .btn.btn-link {
          padding: 0;
          background: none;
          color: var(--gray-0);
          border: none;
          text-decoration: none;
          font-family: var(--font-semibold);
          padding: var(--spacing-1) var(--spacing-2);

          :hover {
            background: var(--gray-800);
          }
        }
      }
    }
  }
`
