import { EXTENSION_STATUS } from 'methone/shared/constants'

export class ExtensionResponseDTO<Config = any> {
  id: string
  name: string
  type: string
  enabled: boolean
  status: EXTENSION_STATUS
  jsonConfig: Config
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string
  deletedAt: Date
  deletedBy: string

  constructor(data: Partial<ExtensionResponseDTO>) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.enabled = data.enabled
    this.status = data.status
    this.jsonConfig = data.jsonConfig
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.updatedAt = data.updatedAt
    this.updatedBy = data.updatedBy
    this.deletedAt = data.deletedAt
    this.deletedBy = data.deletedBy
  }

  public static fromIterable(iterable: IterableIterator<ExtensionResponseDTO>): ExtensionResponseDTO[] {
    return Array.from(iterable).map((item) => new ExtensionResponseDTO(item))
  }
}
