import styled from 'styled-components'

export const SortedTableWrapper = styled.div`
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-border-radius);
  background: var(--gray-700);
  overflow: hidden;
`

export const SortedTableContainer = styled.table`
  margin: 0;
  width: 100%;

  th,
  td {
    padding: var(--spacing-3);
  }

  thead {
    tr {
      background: var(--gray-900);

      th {
        &.table-column-head-sortable {
          cursor: pointer;
        }

        &.table-column-actions {
          width: 45px;
          max-width: 45px;
          min-width: 45px;
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: rgba(var(--gray-500-rgb), 0.25);
      }

      td {
        > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: var(--spacing-1);
        }

        &.table-column-actions {
          opacity: 0;

          > div {
            white-space: nowrap;

            > button {
              background: none;
              border: none;
              height: 21px;
              width: 21px;

              &:not(:last-child) {
                margin-right: var(--spacing-2);
              }

              i.fa-trash-alt {
                color: var(--red-500);
                fill: var(--red-500);
              }
            }
          }
        }

        &.table-column-no-data {
          > div {
            justify-content: center;
            font-family: var(--font-semibold);
          }
        }
      }

      &:hover {
        td {
          &.table-column-actions {
            opacity: 1;
          }
        }
      }
    }
  }
`
