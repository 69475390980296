import React from 'react'
import { FormText } from 'react-bootstrap'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'
import ReactSelect, { GroupBase, Props as RSProps } from 'react-select'
import CreatableReactSelect, { CreatableProps as RSCProps } from 'react-select/creatable'

import cx from 'classnames'

import { i18n } from 'methone/services/i18n'

import { getHookFormErrorMessage } from '../errorMessages'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'

export type SelectRef = typeof CreatableReactSelect | typeof ReactSelect
// eslint-disable-next-line prettier/prettier
type PureSelectProps<IsMulti extends boolean> = (RSProps<Option, IsMulti, GroupBase<Option>> & RSCProps<Option, IsMulti, GroupBase<Option>>)
export interface SelectProps<IsMulti extends boolean = boolean> extends Omit<PureSelectProps<IsMulti>, 'isDisabled'> {
  label?: string
  error?: string
  infoText?: string
  required?: boolean
  disabled?: boolean
}

const creatableUniqueProps = [
  'allowCreateWhileLoading',
  'createOptionPosition',
  'formatCreateLabel',
  'isValidNewOption',
  'getNewOptionData',
  'onCreateOption'
]

export const Select = React.forwardRef<SelectRef, SelectProps>(function SelectComponent(props, ref) {
  const { label, error, required, disabled, infoText, ...selectProps } = props
  const id = selectProps?.name ?? Math.random().toString(36).substring(7)
  const fullId = selectProps?.id ?? `select-field--${id}`
  const cnBase = 'form-select'

  const SelectElement = creatableUniqueProps.some((prop) => prop in selectProps) ? CreatableReactSelect : ReactSelect

  return (
    <FormGroup className={cx(`${cnBase}-container ${cnBase}-container--${id}`, { hasError: !!error })}>
      {label && (
        <Label className={cx(`${cnBase}-label`, { required })} htmlFor={fullId}>
          {label}
        </Label>
      )}

      <SelectElement
        {...selectProps}
        ref={ref as any}
        id={fullId}
        hideSelectedOptions={selectProps.hideSelectedOptions ?? false}
        isDisabled={disabled}
        isMulti={selectProps.isMulti ?? false}
        isClearable={selectProps.isClearable ?? !required}
        placeholder={selectProps.placeholder ?? ''}
        noOptionsMessage={selectProps.noOptionsMessage ?? (() => i18n('No options available'))}
        classNamePrefix="react-select"
        className={cx(`${cnBase}-field react-select`, selectProps.className)}
      />

      {(error || infoText) && <FormText>{error || infoText}</FormText>}
    </FormGroup>
  )
})

type SelectOmitProps = 'label' | 'name' | 'value' | 'onBlur' | 'onChange' | 'error' | 'isMulti' | 'options'
export interface HFSelectProps<TFV extends FieldValues, TN extends Path<TFV>, IsMulti extends boolean = boolean>
  extends Omit<ControllerProps<TFV, TN>, 'render'> {
  selectProps?: Omit<SelectProps<IsMulti>, SelectOmitProps>
  label?: string
  isMulti?: IsMulti
  options: Option[] | GroupOfOptions[]
}

export function HookFormSelect<TFV extends FieldValues, TN extends Path<TFV>>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,

  label,
  options,
  isMulti,
  selectProps
}: HFSelectProps<TFV, TN>): JSX.Element {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Select
          {...selectProps}
          label={label}
          ref={field.ref}
          name={field.name}
          value={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          isMulti={isMulti}
          options={options}
          required={!!rules?.required}
          error={getHookFormErrorMessage(fieldState.error)}
        />
      )}
    />
  )
}
