import { ClientAddressResponseDTO } from './ClientAddressResponseDTO'

export class ClientResponseDTO {
  id: string
  name: string
  taxId: string
  email: string
  group: string
  addresses: ClientAddressResponseDTO[]
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string

  constructor(data: ClientResponseDTO) {
    this.id = data.id
    this.name = data.name
    this.taxId = data.taxId
    this.email = data.email
    this.group = data.group
    this.addresses = (data.addresses ?? []).map((address) => new ClientAddressResponseDTO(address))
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.updatedAt = data.updatedAt
    this.updatedBy = data.updatedBy
  }
}
