export function clearObject<T extends Record<string, any>>(data: T): T {
  if (data && typeof data === 'object' && Object.keys(data).length > 0) {
    const keys = Object.keys(data)

    keys.forEach((key: keyof T) => {
      if (typeof data[key] === 'object') {
        data[key] = clearObject(data[key]) as unknown as any
      }

      if (data[key] == null || (typeof data[key] === 'object' && Object.keys(data[key]).length === 0)) {
        delete data[key]
      }
    })

    return data as unknown as T
  }

  return undefined as T
}
