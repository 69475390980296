import React from 'react'
import { useParams } from 'react-router-dom'

import { ErrorBoundary } from 'methone/components/ErrorBoundary'
import { extensionRendererService } from 'methone/services/extensionRendererService'

import { ExtensionEditorStyledContainer } from './styled'

export const ExtensionEditorPage: React.FC = () => {
  const { type } = useParams()

  const ExtensionEditor = React.useCallback(() => {
    const ExtensionEditor = extensionRendererService.getRenderer(type).component

    if (ExtensionEditor != null) {
      return <ExtensionEditor />
    }
  }, [type])

  return (
    <ExtensionEditorStyledContainer>
      <ErrorBoundary>
        <ExtensionEditor />
      </ErrorBoundary>
    </ExtensionEditorStyledContainer>
  )
}
