import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Joi from 'joi'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormPassword } from 'methone/components/ui/Forms'
import { useQueryParams, useSelector } from 'methone/hooks'
import { globalNotification } from 'methone/services/globalNotification'
import { i18n } from 'methone/services/i18n'
import { userResetPassword } from 'methone/services/rest/users'
import { storageService } from 'methone/services/storageService'
import { JOIErrorMessages } from 'methone/shared/JOIErrorMessages'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { ResetPasswordContainer } from './styled'

interface FormData {
  password: string
  passwordTwo: string
}

const defaultValues: FormData = {
  password: '',
  passwordTwo: ''
}

export const ResetPasswordPage: React.FC = () => {
  const isMounted = React.useRef(false)
  const navigate = useNavigate()
  const [params] = useQueryParams<{ token: string }>()

  const isAuthenticated = useSelector((store) => store.authentication.isAuthenticated)
  const [loading, setLoading] = React.useState(false)

  const { control, handleSubmit } = useForm<FormData>({ mode: 'all', defaultValues })

  async function handleResetPassword(data: FormData): Promise<void> {
    try {
      setLoading(true)

      const { error } = Joi.object<FormData>({
        password: Joi.string().required(),
        passwordTwo: Joi.string().required()
      })
        .messages(JOIErrorMessages)
        .validate(data)

      if (error) {
        throw error
      }

      await userResetPassword({ token: params.token, password: data.password })

      navigate('/login')
    } catch (err: any) {
      handleHTTPRequestError(err)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (isMounted.current) {
      if (storageService.hasItem('refreshToken') || isAuthenticated) {
        navigate('/')
      } else if (params != null && params.token == null) {
        globalNotification.error(i18n('Missing reset password token'))
        navigate('/login')
      }
    } else {
      isMounted.current = true
    }
  }, [isAuthenticated, params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResetPasswordContainer>
      <Card style={{ minWidth: 310.283 }}>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <Card.Header>
            <Card.Img src="/images/application-logo.png" alt="methone-logo" height="70px" />
          </Card.Header>
          <Card.Body>
            <HookFormPassword
              control={control}
              name="password"
              rules={{ required: true }}
              label={i18n('Password')}
              inputProps={{ autoCorrect: 'false', autoCapitalize: 'false' }}
            />
            <HookFormPassword
              control={control}
              name="passwordTwo"
              rules={{ required: true }}
              label={i18n('Password again')}
              inputProps={{ autoCorrect: 'false', autoCapitalize: 'false' }}
            />
          </Card.Body>
          <Card.Footer style={{ display: 'flex', gap: 'var(--spacing-2)', flexDirection: 'column' }}>
            <Button type="submit" variant="primary">
              {i18n('Submit')}
            </Button>
          </Card.Footer>
        </form>
      </Card>
      <FixedLoading enabled={loading} />
    </ResetPasswordContainer>
  )
}
