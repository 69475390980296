export function getTimeInMilliseconds(time: number | string): number {
  if (typeof time === 'number') {
    if (Number.isNaN(time) || time < 0) {
      throw new Error('Invalid time')
    }

    return time
  }

  const number = Number(time.replace(/[a-zA-Z]/g, ''))
  const unit = (/([a-zA-Z]+)/.exec(time)?.[0] ?? '').toLowerCase()

  if (Number.isNaN(number) || number < 0 || !['ms', 's', 'm', 'h', 'd'].includes(unit)) {
    throw new Error('Invalid time')
  }

  if (unit === 'ms' || unit === 's') {
    return number * 1000 ** (unit === 'ms' ? 0 : 1)
  }

  if (unit === 'm' || unit === 'h') {
    return number * 1000 * 60 ** (unit === 'h' ? 2 : 1)
  }

  return number * 1000 * 60 ** 2 * 24
}
