export enum JOIErrorMessages {
  // 'alternatives.all'=`alternatives.all`,
  // 'alternatives.any'=`alternatives.any`,
  // 'alternatives.match'=`alternatives.match`,
  // 'alternatives.one'=`alternatives.one`,
  // 'alternatives.types'=`alternatives.types`,
  // 'any.custom'=`any.custom`,
  // 'any.default'=`any.default`,
  // 'any.failover'=`any.failover`,
  // 'any.invalid'=`any.invalid`,
  'any.only' = `Parameter {{#label}} must be one of {{#valids}}.`,
  // 'any.ref'=`any.ref`,
  'any.required' = `Parameter {{#label}} is required.`,
  // 'any.unknown'=`any.unknown`,
  // 'array.base'=`Parameter {{#label}} is not an array.`,
  // 'array.excludes'=`array.excludes`,
  // 'array.includesRequiredBoth'=`array.includesRequiredBoth`,
  // 'array.includesRequiredKnowns'=`array.includesRequiredKnowns`,
  // 'array.includesRequiredUnknowns'=`array.includesRequiredUnknowns`,
  // 'array.includes'=`array.includes`,
  // 'array.length'=`array.length`,
  // 'array.max'=`Parameter {{#label}} maximum length is {{#limit}}.`,
  'array.min' = `Parameter {{#label}} must contain at least {{#limit}} items.`,
  // 'array.orderedLength'=`array.orderedLength`,
  // 'array.sort'=`array.sort`,
  // 'array.sort.mismatching'=`array.sort.mismatching`,
  // 'array.sort.unsupported'=`array.sort.unsupported`,
  // 'array.sparse'=`array.sparse`,
  'array.unique' = `Parameter {{#label}} contains a duplicate value.`,
  // 'array.hasKnown'=`array.hasKnown`,
  // 'array.hasUnknown'=`array.hasUnknown`,
  // 'binary.base'=`binary.base`,
  // 'binary.length'=`binary.length`,
  // 'binary.max'=`binary.max`,
  // 'binary.min'=`binary.min`,
  'boolean.base' = `Parameter {{#label}} must be a boolean.`,
  'date.base' = `Parameter {{#label}} must be a valid date.`,
  // 'date.format'=`Parameter {{#label}} is in invalid format.`,
  // 'date.greater'=`Parameter {{#label}} must be greater than {{#limit}}.`,
  // 'date.less'=`Parameter {{#label}} must be less than {{#limit}}.`,
  // 'date.max'=`Parameter {{#label}} must be less than or equal to {{#limit}}.`,
  // 'date.min'=`Parameter {{#label}} must be greater than or equal to {{#limit}}.`,
  // 'date.strict'=`date.strict`,
  // 'function.arity'=`function.arity`,
  // 'function.class'=`function.class`,
  // 'function.maxArity'=`function.maxArity`,
  // 'function.minArity'=`function.minArity`,
  // 'number.base'=`Parameter {{#label}} is not a valid number.`,
  // 'number.greater'=`Parameter {{#label}} must be greater than {{#limit}}.`,
  // 'number.infinity'=`Parameter {{#label}} is not a valid number.`,
  // 'number.integer'=`Parameter {{#label}} is not an integer.`,
  // 'number.less'=`Parameter {{#label}} must be less than {{#limit}}.`,
  // 'number.max'=`Parameter {{#label}} needs to be less than or equal to {{#limit}}.`,
  'number.min' = `Parameter {{#label}} must be greater than or equal to {{#limit}}.`,
  // 'number.multiple'=`Parameter {{#label}} is not a multiple of {{#multiple}}.`,
  // 'number.negative'=`Parameter {{#label}} is not a negative number.`,
  // 'number.port'=`Parameter {{#label}} is not a valid port.`,
  // 'number.positive'=`Parameter {{#label}} is not a positive number.`,
  // 'number.precision'=`Parameter {{#label}} must have no more than {{#limit}} decimal places.`,
  // 'number.unsafe'=`Parameter {{#label}} is not a safe number.`,
  'object.unknown' = `Parameter {{#label}} is not allowed.`,
  // 'object.and'=`object.and`,
  // 'object.assert'=`object.assert`,
  // 'object.base'=`object.base`,
  // 'object.length'=`object.length`,
  // 'object.max'=`object.max`,
  // 'object.min'=`object.min`,
  // 'object.missing'=`Parameter {{#label}} must be one of the following values {{#values}}.`,
  // 'object.nand'=`object.nand`,
  // 'object.pattern.match'=`object.pattern.match`,
  // 'object.refType'=`object.refType`,
  // 'object.regex'=`object.regex`,
  // 'object.rename.multiple'=`object.rename.multiple`,
  // 'object.rename.override'=`object.rename.override`,
  // 'object.schema'=`object.schema`,
  // 'object.instance'=`object.instance`,
  // 'object.with'=`Parameter {{#label}} must be contained in {{#schema}}.`,
  // 'object.without'=`Parameter {{#label}} must not contain {{#key}}.`,
  // 'object.xor'=`Parameter {{#label}} must be either {{#oneOf}}.`,
  'object.oxor' = `Parameter {{#label}} contains a conflict between optional exclusive peers {{#peers}}.`,
  // 'string.alphanum'=`string.alphanum`,
  // 'string.base64'=`Parameter {{#label}} is not a valid base64 string.`,
  // 'string.base'=`Parameter {{#label}} is not a valid string.`,
  // 'string.creditCard'=`Parameter {{#label}} is valid credit card.`,
  // 'string.dataUri'=`Parameter {{#label}} is not a valid data URI.`,
  // 'string.domain'=`Parameter {{#label}} is not a valid domain.`,
  'string.email' = `Parameter {{#label}} must be a valid email.`,
  'string.empty' = `Parameter {{#label}} is not allowed to be empty.`,
  'string.guid' = `Parameter {{#label}} must be a valid UUID.`,
  // 'string.hexAlign'=`string.hexAlign`,
  // 'string.hex'=`Parameter {{#label}} is not a valid hexadecimal value.`,
  // 'string.hostname'=`Parameter {{#label}} is not a valid hostname.`,
  // 'string.ipVersion'=`string.ipVersion`,
  // 'string.ip'=`Parameter {{#label}} is not a valid IP.`,
  // 'string.isoDate'=`Parameter {{#label}} is not a valid ISO 8601 date.`,
  // 'string.isoDuration'=`Parameter {{#label}} is not a valid ISO 8601 duration.`,
  // 'string.length'=`Parameter {{#label}} must be {{#limit}} characters long.`,
  // 'string.lowercase'=`Parameter {{#label}} must be lowercase.`,
  'string.max' = `Parameter {{#label}} must be less than or equal to {{#limit}} characters long.`,
  // 'string.min'=`Parameter {{#label}} must be at least {{#limit}} characters long.`,
  // 'string.normalize'=`Parameter {{#label}} is not a valid string.`,
  // 'string.pattern.base'=`Parameter {{#label}} uses an invalid pattern.`,
  // 'string.pattern.name'=`string.pattern.name`,
  // 'string.pattern.invert.base'=`string.pattern.invert.base`,
  // 'string.pattern.invert.name'=`string.pattern.invert.name`,
  // 'string.token'=`Parameter {{#label}} is not a valid token.`,
  // 'string.trim'=`string.trim`,
  // 'string.uppercase'=`Parameter {{#label}} must be uppercase.`,
  // 'string.uri'=`Parameter {{#label}} is not a valid URI.`,
  // 'string.uriCustomScheme'=`string.uriCustomScheme`,
  // 'string.uriRelativeOnly'=`string.uriRelativeOnly`,
  // 'symbol.base'=`symbol.base`,
  // 'symbol.map'=`symbol.map`
  'methone.orderByKeys' = `Parameter {{#label}} with value "{{#value}}" doesn't satisfy the keys {{#values}}.`,
  'methone.orderByDirection' = `Parameter {{#label}} with value "{{#value}}" doesn't satisfy the directions {{#values}}.`,
  'methone.whereValidator' = `Parameter {{#label}} with value "{{#value}}" doesn't satisfy the validation function.`,
  'methone.whereValueType' = `Parameter {{#label}} with value "{{#value}}" doesn't satisfy the expected value type {{#valueType}}.`,
  'methone.whereOperator' = `Parameter {{#label}} as SQLOperators instance missing a valid operator, operator {{#operator}} is not allowed.`
}
