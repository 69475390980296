import * as dateFns from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'

import { selectByLanguage } from 'methone/utils/selectByLanguage'

const banKeys = [
  'format',
  'formatDistance',
  'formatDistanceStrict',
  'formatDistanceToNow',
  'formatDistanceToNowStrict',
  'formatDuration',
  'formatISO',
  'formatISO9075',
  'formatISODuration',
  'formatRFC3339',
  'formatRFC7231',
  'formatRelative',
  'fromUnixTime',
  'parse',
  'parseISO',
  'parseJSON',
  'set',
  'sub',
  'toDate'
]

export type DateServiceKnownLocales = keyof typeof dateFnsLocales

class DateService {
  private locale: DateServiceKnownLocales = 'enUS'
  public unverifiedMethods = Array.from(Object.entries(dateFns))
    .filter(([key]) => !banKeys.includes(key))
    .reduce((prev, [k, v]) => ({ ...prev, [k]: v }), {} as typeof dateFns)

  public getLocale(): DateServiceKnownLocales {
    return this.locale
  }

  public setLocale(locale: string | DateServiceKnownLocales): void {
    this.locale = locale as DateServiceKnownLocales
  }

  public toDate(date: Date | string | number): Date {
    if (!date) return null

    return dateFns.toDate(date)
  }

  public formatISO(date: Date | number | string): string {
    if (!date) return null

    return dateFns.format(this.toDate(date), "yyyy-MM-dd'T'HH:mm:ssXXX", {
      locale: dateFnsLocales[this.locale]
    })
  }

  public format(date: Date | number | string, formatString: string): string {
    if (!date) return null

    return dateFns.format(this.toDate(date), formatString, {
      locale: dateFnsLocales[this.locale]
    })
  }

  public formatDate(date: Date | string | number): string {
    return this.format(date, selectByLanguage('dd/MM/yyyy', 'MM/dd/yyyy'))
  }

  public formatDateTime(date: Date | string | number): string {
    return this.format(date, selectByLanguage('dd/MM/yyyy HH:mm:ss', 'MM/dd/yyyy hh:mm:ss bb'))
  }

  public formatTime(date: Date | string | number): string {
    return this.format(date, selectByLanguage('HH:mm:ss', 'hh:mm:ss bb'))
  }
}

export const dateService = new DateService()
