import React from 'react'

import cx from 'classnames'

import { PaginationContainer } from './styled'

interface Props {
  totalPages: number
  currentPage: number
  onChangePage: (page: number) => void
  maxPagesToShow?: number
}

export const Pagination: React.FC<Props> = ({ currentPage, onChangePage, totalPages, maxPagesToShow = 5 }) => {
  function getItems(): number[] {
    const items = []

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(i)
      }
    } else {
      const half = Math.floor(maxPagesToShow / 2)

      if (currentPage <= half) {
        for (let i = 1; i <= maxPagesToShow; i++) {
          items.push(i)
        }
      } else if (currentPage >= totalPages - half) {
        for (let i = totalPages - maxPagesToShow + 1; i <= totalPages; i++) {
          items.push(i)
        }
      } else {
        for (let i = currentPage - half; i <= currentPage + half; i++) {
          items.push(i)
        }
      }
    }

    return items
  }

  return (
    <PaginationContainer>
      <button type="button" className="pagination-item" disabled={currentPage === 1} onClick={() => onChangePage(1)}>
        <i className="fas fa-chevron-left" />
        <i className="fas fa-chevron-left" />
      </button>
      <button
        type="button"
        className="pagination-item"
        disabled={currentPage === 1}
        onClick={() => onChangePage(currentPage - 1)}
      >
        <i className="fas fa-chevron-left" />
      </button>
      {getItems().map((index) => (
        <button
          key={index}
          type="button"
          className={cx('pagination-item', { selected: index === currentPage })}
          disabled={index === currentPage}
          onClick={() => onChangePage(index)}
        >
          {index}
        </button>
      ))}
      <button
        type="button"
        className="pagination-item"
        disabled={currentPage === totalPages}
        onClick={() => onChangePage(currentPage + 1)}
      >
        <i className="fas fa-chevron-right" />
      </button>
      <button
        type="button"
        className="pagination-item"
        disabled={currentPage === totalPages}
        onClick={() => onChangePage(totalPages)}
      >
        <i className="fas fa-chevron-right" />
        <i className="fas fa-chevron-right" />
      </button>
    </PaginationContainer>
  )
}
