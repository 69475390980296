import React from 'react'
import Button from 'react-bootstrap/Button'

import { i18n } from 'methone/services/i18n'

import { Modal } from './styled'

export interface Props {
  children: React.ReactNode
  onConfirm: () => void
  onCancel: () => void
  level?: string
  cancelText?: string
  customHeaderBackground?: string
  customHeaderColor?: string
  confirmText?: string
  headerText?: string
}

export const GlobalConfirmationModal: React.FC<Props> = (props) => {
  const {
    children,
    onConfirm,
    onCancel,

    level = 'danger',
    cancelText,
    customHeaderBackground,
    customHeaderColor,
    confirmText,
    headerText
  } = props

  function handleEscapeKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      onCancel()
    }
  }

  React.useEffect(() => {
    addEventListener('keydown', handleEscapeKeyDown)

    return () => removeEventListener('keydown', handleEscapeKeyDown)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      show={true}
      onHide={onCancel}
      level={level}
      customHeaderBackground={customHeaderBackground}
      customHeaderColor={customHeaderColor}
    >
      <Modal.Header>
        <i className="fas fa-exclamation-triangle fa-lg" />
        {headerText ?? i18n('Action needed')}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={onConfirm} variant={level}>
          {confirmText ?? i18n('Confirm')}
        </Button>
        <span>{i18n('or')}</span>
        <Button type="button" onClick={onCancel} variant="link">
          {cancelText ?? i18n('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
