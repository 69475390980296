import { UI_ACTIONS } from 'methone/utils/constants'

interface SetBooleanPropsAction {
  type: UI_ACTIONS.SET_SIDEBAR_EXPANDED
  payload: boolean
}

interface ResetAction {
  type: UI_ACTIONS.RESET
}

type UiAction = SetBooleanPropsAction | ResetAction

interface UiState {
  sidebarExpanded: boolean
}

const initialState: UiState = {
  sidebarExpanded: true
}

export function uiReducer(state = initialState, action: UiAction): UiState {
  switch (action.type) {
    case UI_ACTIONS.SET_SIDEBAR_EXPANDED:
      return { ...state, sidebarExpanded: action.payload }
    case UI_ACTIONS.RESET:
      return initialState
    default:
      return state
  }
}
