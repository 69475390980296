import { logger } from 'methone/services/logger'

class StorageService {
  private prefix = '@methone'

  public hasItem(key: keyof Storage): boolean {
    return this.getItem(key) != null
  }

  public getItem<K extends keyof Storage>(key: K): Storage[K] {
    try {
      const value = localStorage.getItem(`${this.prefix}/${key}`)

      if (value) {
        return JSON.parse(value)
      }
    } catch (e) {
      logger.error(e)

      return null
    }
  }

  public setItem<K extends keyof Storage>(key: K, value: Storage[K]): void {
    try {
      if (value != null) {
        localStorage.setItem(`${this.prefix}/${key}`, JSON.stringify(value))
      } else {
        localStorage.removeItem(`${this.prefix}/${key}`)
      }
    } catch (e) {
      logger.error(e)
    }
  }

  public removeItem(key: keyof Storage): void {
    try {
      this.setItem(key, null)
    } catch (e) {
      logger.error(e)
    }
  }
}

export const storageService = new StorageService()
