import React from 'react'
import { FormControl } from 'react-bootstrap'

import { InputMask, MaskProps } from '@react-input/mask'

export interface MaskInputProps extends Omit<MaskProps, 'component'>, Omit<HTMLInputProps, 'type' | 'ref'> {
  type?: 'text'
  /** Input mask, `replacement` is used to replace characters. */
  mask: string
  /** Sets the characters replaced in the mask, where "key" is the replaced character, "value" is the regular expression to which the input character must match (see «[Replacement](https://github.com/GoncharukBro/react-input/tree/main/packages/mask#replacement)»). It is possible to pass the replacement character as a string, then `replacement="_"` will default to `replacement={{ _: /./ }}`. Keys are ignored as you type. */
  replacement: import('@react-input/mask').Replacement
}

// eslint-disable-next-line react/display-name
export const MaskInput = React.forwardRef<HTMLInputElement, MaskInputProps>(function (props, ref) {
  return <InputMask {...props} component={FormControl} ref={ref} type="text" />
})
