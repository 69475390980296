export class UserResumeResponseDTO {
  id: string
  name: string
  email: string
  username: string
  enabled: boolean
  createdAt: Date
  updatedAt: Date

  constructor(user: UserResumeResponseDTO) {
    this.id = user.id
    this.name = user.name
    this.email = user.email
    this.username = user.username
    this.enabled = user.enabled
    this.createdAt = user.createdAt
    this.updatedAt = user.updatedAt
  }
}
