import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button.pagination-item {
    position: relative;
    background: var(--gray-800);
    padding: var(--spacing-2);
    font-family: var(--font-semibold);
    min-width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &.selected {
      background: var(--blue-500);
      font-family: var(--font-bold);
    }

    &:hover {
      &.selected {
        background: var(--blue-500);
      }

      &:not(.selected):not(:disabled) {
        background: var(--gray-700);
      }
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:disabled:not(.selected) {
      background: var(--gray-700);
    }

    &:first-child {
      border-top-left-radius: var(--spacing-1);
      border-bottom-left-radius: var(--spacing-1);

      > i {
        position: absolute;

        &:first-child {
          top: 50%;
          left: calc(50% + 3px);
          transform: translate(-50%, -50%);
        }

        &:last-child {
          top: 50%;
          left: calc(50% - 3px);
          transform: translate(-50%, -50%);
        }
      }
    }

    &:last-child {
      border-top-right-radius: var(--spacing-1);
      border-bottom-right-radius: var(--spacing-1);

      > i {
        position: absolute;

        &:first-child {
          top: 50%;
          left: calc(50% + 3px);
          transform: translate(-50%, -50%);
        }

        &:last-child {
          top: 50%;
          left: calc(50% - 3px);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`
