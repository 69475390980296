import React from 'react'
import { FieldValues as FV, Path } from 'react-hook-form'
import { GroupBase, IndicatorsContainerProps, InputActionMeta, components } from 'react-select'

import { SelectProps, HFSelectProps, Select, SelectRef, HookFormSelect } from 'methone/components/ui/Forms'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

export interface ACProps extends Omit<SelectProps, 'options'> {
  searchFunction: (keyword: string) => Promise<Options>
  minSearchInputLength?: number
}

export interface HFACProps<TFV extends FV, TN extends Path<TFV>> extends Omit<HFSelectProps<TFV, TN>, 'options'> {
  searchFunction: (keyword: string) => Promise<Options>
  minSearchInputLength?: number
}

type ICProps = IndicatorsContainerProps<Option, boolean, GroupBase<Option>>
const IndicatorsContainer: React.FC<ICProps> = ({ children, ...props }) => {
  if (Array.isArray(children)) {
    children = children[0]
  }

  return <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>
}

export const AutoComplete = React.forwardRef<SelectRef, ACProps>(function AutoComplete(props, ref) {
  const { searchFunction, minSearchInputLength = 3, components, onMenuClose, ...rest } = props
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const [store, setStore] = React.useState<Options>(null)

  function handleInputChange(newValue: string, _actionMeta: InputActionMeta): void {
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(async () => {
        if (newValue.length > minSearchInputLength) {
          const newStoreData = await searchFunction(newValue)

          setStore(newStoreData)
        }
      }, 500)
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  return (
    <Select
      {...rest}
      ref={ref}
      options={store ?? []}
      onInputChange={handleInputChange}
      menuIsOpen={store != null ? undefined : false}
      components={{ IndicatorsContainer, ...(components ?? {}) }}
      onMenuClose={() => {
        setStore(null)
        onMenuClose?.()
      }}
    />
  )
})

export function AutoCompleteHookForm<TFV extends FV, TN extends Path<TFV>>(props: HFACProps<TFV, TN>): JSX.Element {
  const { selectProps, searchFunction, minSearchInputLength = 2, ...rest } = props
  const { components, onMenuClose, ...restSelectProps } = selectProps ?? {}
  const timeoutRef = React.useRef<NodeJS.Timeout>()
  const [store, setStore] = React.useState<Options>(null)

  function handleInputChange(newValue: string, _actionMeta: InputActionMeta): void {
    try {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(async () => {
        if (newValue.length > minSearchInputLength) {
          const newStoreData = await searchFunction(newValue)

          setStore(newStoreData)
        }
      }, 500)
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  return (
    <HookFormSelect
      {...rest}
      options={store ?? []}
      selectProps={{
        ...restSelectProps,
        components: { IndicatorsContainer, ...(components ?? {}) },
        onInputChange: handleInputChange,
        menuIsOpen: store != null ? undefined : false,
        onMenuClose: () => {
          setStore(null)
          onMenuClose?.()
        }
      }}
    />
  )
}
