import styled, { keyframes } from 'styled-components'

const loopRoll = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const FixedLoadingContainer = styled.div<{ position: string }>`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;

  > div {
    border: var(--spacing-1) solid var(--gray-900); /* Light grey */
    border-top: var(--spacing-1) solid var(--blue-500); /* Blue */
    border-radius: 50%;
    width: calc(var(--spacing-5) * 2);
    height: calc(var(--spacing-5) * 2);
    animation: ${loopRoll} 1s linear infinite;
  }
`
