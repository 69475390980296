import styled from 'styled-components'

export const LoginContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4);

  > .card {
    > form {
      > .card-body {
        > .form-group:not(:first-child) {
          margin-top: var(--spacing-2);
        }
      }
    }
  }

  .auth-provider-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-2);
    width: 500px;

    > .auth-providers-title {
      width: 100%;
      text-align: center;
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-2);
    }
  }
`
