import React from 'react'
import { Accordion, Badge, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { extensionRendererService } from 'methone/services/extensionRendererService'
import { i18n } from 'methone/services/i18n'
import { getExtensionBuilders, getExtensions } from 'methone/services/rest/extensions'
import { EXTENSION_STATUS } from 'methone/shared/constants'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { ExtensionBlock, ExtensionHeaderBlock, ExtensionStyledContainer } from './styled'

export const ExtensionsPage: React.FC = () => {
  const { loading: buildersLoading, data: builders, error: buildersError } = useRequest(getExtensionBuilders)
  const { loading: extensionLoading, data: extensions, error: extensionsError } = useRequest(getExtensions)
  const navigate = useNavigate()

  function isUpperCase(char: string): boolean {
    return (char ?? '') === (char ?? '').toUpperCase()
  }

  function formatName(name: string): string {
    let resultado = name[0]

    for (let i = 1; i < name.length; i++) {
      if (isUpperCase(name[i]) && (!isUpperCase(name[i - 1]) || !isUpperCase(name[i + 1]))) {
        resultado += ` ${name[i]}`
      } else {
        resultado += name[i]
      }
    }

    return resultado.trim()
  }

  React.useEffect(() => {
    if (buildersError) {
      handleHTTPRequestError(buildersError)
    }
  }, [buildersError]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (extensionsError) {
      handleHTTPRequestError(extensionsError)
    }
  }, [extensionsError]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExtensionStyledContainer>
      <FixedLoading enabled={buildersLoading || extensionLoading} />
      <Accordion>
        {(builders ?? [])
          .filter(({ name }) => extensionRendererService.hasRenderer(name))
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, unique }) => {
            const builderExtensions = extensions?.filter((e) => e.type === name) ?? []

            return (
              <Accordion.Item key={name} eventKey={name}>
                <Accordion.Button type="button" onClick={(e) => e.preventDefault()}>
                  <ExtensionHeaderBlock>
                    <span className="extension-type">{formatName(name)}</span>
                    <Badge bg="secondary">{builderExtensions.length}</Badge>
                  </ExtensionHeaderBlock>
                </Accordion.Button>
                <Accordion.Body>
                  {builderExtensions.length > 0 &&
                    builderExtensions.map((extension) => (
                      <ExtensionBlock
                        key={extension.id}
                        onClick={() => navigate(`/admin/extensions/${name}/${extension.id}`)}
                      >
                        <div className="extension-describer">
                          {extension.type}/{extension.name}
                        </div>
                        <div className="status-badge">
                          <Badge
                            bg={
                              extension.status === EXTENSION_STATUS.RUNNING
                                ? 'success'
                                : extension.status === EXTENSION_STATUS.ERROR
                                  ? 'danger'
                                  : 'secondary'
                            }
                          >
                            {extension.status.toUpperCase()}
                          </Badge>
                        </div>
                      </ExtensionBlock>
                    ))}

                  {(builderExtensions.length === 0 || unique === false) && (
                    <ExtensionBlock>
                      <div className="add-extension">
                        <Button variant="outline-primary" onClick={() => navigate(`/admin/extensions/${name}/new`)}>
                          <i className="fas fa-plus" />
                          {i18n("Create new '{name}' extension", { name })}
                        </Button>
                      </div>
                    </ExtensionBlock>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
      </Accordion>
    </ExtensionStyledContainer>
  )
}
