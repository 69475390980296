export class UserResponseDTO {
  id: string
  name: string
  username: string
  email: string
  enabled: boolean
  createdAt: Date
  updatedAt: Date
  permissions: string[]

  constructor(user: UserResponseDTO) {
    this.id = user.id
    this.name = user.name
    this.username = user.username
    this.email = user.email
    this.enabled = user.enabled
    this.createdAt = user.createdAt
    this.updatedAt = user.updatedAt
    this.permissions = user.permissions
  }
}
