import { EXTENSION_STATUS } from 'methone/shared/constants'

export class ExtensionResumeResponseDTO {
  id: string
  name: string
  type: string
  status: EXTENSION_STATUS
  createdAt: Date
  createdBy: string
  updatedAt: Date
  updatedBy: string
  deletedAt: Date
  deletedBy: string

  constructor(data: Partial<ExtensionResumeResponseDTO>) {
    this.id = data.id
    this.name = data.name
    this.type = data.type
    this.status = data.status
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
    this.updatedAt = data.updatedAt
    this.updatedBy = data.updatedBy
    this.deletedAt = data.deletedAt
    this.deletedBy = data.deletedBy
  }

  public static fromIterable(iterable: IterableIterator<ExtensionResumeResponseDTO>): ExtensionResumeResponseDTO[] {
    return Array.from(iterable).map((item) => new ExtensionResumeResponseDTO(item))
  }
}
