import React from 'react'
import { createPortal } from 'react-dom'

import { DEFAULT_PORTAL_ID } from 'methone/utils/constants'
import { getOrCreatePortalRoot } from 'methone/utils/getOrCreatePortalRoot'

export const Portal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [portalRoot, setPortalRoot] = React.useState<HTMLElement>()

  React.useEffect(() => {
    setPortalRoot(getOrCreatePortalRoot(DEFAULT_PORTAL_ID))
  }, [])

  if (portalRoot) {
    return createPortal(<>{children}</>, portalRoot)
  }

  return <></>
}
